import React from "react";
import { Vega } from "react-vega";
import { Decimal } from "decimal.js";
import MyVega from "./MyVega";

const sideLength = 40;

const symbolShape = "circle";

export default function MyRandomScatterPlot(props) {
  let deviceType = props.deviceType != undefined? props.deviceType: "desktop";
  let chartData = props.data;
  let chartMode = props.mode == "" ? "random" : props.mode;
  let chartTitle = chartData.chartTitle;
  let legendTitle = chartData.legendTitle;
  let legendOffset = props.legendOffset;

  let chartWidth = props.width != undefined? props.width: 400;
  let chartHeight = props.height != undefined? props.height: 400;

  let sideLength = props.sideLength != undefined? props.sideLength: 40;

  let chartDataFlatten = {
    table: [],
  };

  let proportionIndex = 0;

  for (let j = 0; j < chartData.table.length; j++) {
    proportionIndex = j;

    let amountToDraw = new Decimal(chartData.table[proportionIndex].amount);
    let amountDrew = new Decimal(0);
    //amountToDraw = amountToDraw.times(Decimal(10)).floor();

    while (amountToDraw.greaterThan(Decimal(0))) {
      let zIndex =
        proportionIndex == chartData.table.length - 1 ? 0 : proportionIndex + 1;

      let xPos = 0;
      let yPos = 0;

      let xLowerBound = 0;
      let xUpperBound = chartWidth;
      let yLowerBound = 9;
      let yUpperBound = chartHeight;
      let columnMax = Math.floor(chartWidth/sideLength);
      let rowMax = Math.floor(chartHeight/sideLength);

      switch (chartMode) {
        case "two-sides-column":

          if (proportionIndex < chartData.table.length - 1) {
            switch (proportionIndex) {
              case 0:
                xPos =
                  sideLength / 2 +
                  sideLength * amountDrew.dividedToIntegerBy(Decimal(columnMax));
                yPos =
                  sideLength / 2 + 
                  sideLength * amountDrew.mod(Decimal(rowMax));
                break;
              case 1:
                xPos =
                  chartWidth -
                  sideLength * amountDrew.dividedToIntegerBy(Decimal(columnMax));
                yPos =
                  sideLength / 2 + sideLength * amountDrew.mod(Decimal(rowMax));
                break;
            }
          } else {
            xPos =
              sideLength / 2 + Math.random() * (chartWidth - sideLength / 2);
            yPos = sideLength / 2 + Math.random() * (chartHeight - sideLength);
          }
          break;
        case "two-sides":
          if (proportionIndex < chartData.table.length - 1) {
            switch (proportionIndex) {
              case 0:
                xLowerBound = sideLength / 2;
                xUpperBound = chartWidth / 2 - sideLength;
                yLowerBound = sideLength / 2;
                yUpperBound = chartHeight - sideLength;

                xPos =
                  sideLength / 2 +
                  Math.random() * (chartWidth / 2 - sideLength);
                yPos =
                  sideLength / 2 + Math.random() * (chartHeight - sideLength);
                break;
              case 1:
                xLowerBound = chartWidth / 2 + sideLength / 2;
                xUpperBound = chartWidth - sideLength;
                yLowerBound = sideLength / 2;
                yUpperBound = chartHeight - sideLength;
                xPos =
                  chartWidth / 2 +
                  sideLength / 2 +
                  Math.random() * (chartWidth / 2 - sideLength / 2);
                yPos =
                  sideLength / 2 + Math.random() * (chartHeight - sideLength);
                break;
            }
          } else {
            xLowerBound = sideLength / 2;
            xUpperBound = chartWidth - sideLength/2;
            yLowerBound = sideLength / 2;
            yUpperBound = chartHeight - sideLength/2;
            xPos =
              sideLength / 2 + Math.random() * (chartWidth - sideLength / 2);
            yPos = sideLength / 2 + Math.random() * (chartHeight - sideLength);
          }

          break;
        default:
          // random
          xPos = 
            sideLength / 2 + 
            Math.random() * (chartWidth - sideLength/2);
            //Math.random() * (chartWidth);
          yPos = 
            //sideLength / 2 + 
            Math.random() * (chartHeight - sideLength);
          break;
      }

      // now, check if  the position is too close to one of the preious one.

      if (proportionIndex < chartData.table.length - 1) {
        let noCollision = false;
        let iterationLimit = 10;
        let iterationCount = 0;

        while (!noCollision & iterationCount < iterationLimit) {
          let collisionCount = 0;
          for (let i = 0; i < chartDataFlatten.table.length; i++) {
            let record = chartDataFlatten.table[i];
            let distance = Math.sqrt(
              Math.pow(record.x - xPos, 2) + Math.pow(record.y - yPos, 2)
            );
            if (distance < sideLength / 2) {
              // too close
              collisionCount++;
              break;
            }
          }
          if( collisionCount > 0 ){
            noCollision = false;
            // now, do some random adjustment
            let randomDirection = Math.random() > 0.5? "x":"y";
            switch(randomDirection){
              case "x":
                xPos = xPos + sideLength / 2 < xUpperBound? xPos + sideLength / 2: xPos - sideLength / 2;
                break;
              default:
                yPos = yPos + sideLength / 2 < yUpperBound? yPos + sideLength / 2: yPos - sideLength / 2;
                break;
            }
          }
          else{
            noCollision = true;
          }

          iterationCount++;
          //console.log("iterationCount: " + iterationCount);
        }
      }

      chartDataFlatten.table.push({
        id: chartDataFlatten.table.length,
        category: chartData.table[proportionIndex].category,
        color: chartData.table[proportionIndex].color,
        amount: 1,
        height: sideLength, // unitAmountToDraw.times(Decimal(sideLength)),
        x: xPos, //xPos.times(Decimal(sideLength)),
        y: yPos, //yPos.times(Decimal(sideLength)),
        zIndex: zIndex,
        fillOpacity: chartData.table[proportionIndex].fillOpacity //proportionIndex == chartData.table.length - 1 ? 0.3 : 1.0, // 0.5 vs. 1.0
      });
      amountToDraw = amountToDraw.minus(Decimal(1));
      amountDrew = amountDrew.add(Decimal(1));
      //console.log("Amount to draw: " + amountToDraw);
    }

    /*
    if(amountToDraw > 0){
      let yPos = Math.floor(chartDataFlatten.table.length / 10);
      let xPos = chartDataFlatten.table.length % 10;
      //chartDataFlatten.table.push({ id: chartDataFlatten.table.length, category: chartData.table[proportionIndex].category, color:  colorList[proportionIndex], amount: 1, x: xPos * sideLength, y: yPos * sideLength});

      // paint anohter one that acutally has color, but less depth?
      chartDataFlatten.table.push({
        id: chartDataFlatten.table.length,
        category: chartData.table[proportionIndex].category,
        color: chartData.table[proportionIndex].color,
        amount: 1,
        height: sideLength * amountToDraw,
        x: xPos * sideLength,
        y: yPos * sideLength,
      });


    }
    */

    // method 1: only  integer
    /*
    for (let i = 0; i < chartData.table[proportionIndex].amount; i++) {
      let yPos = Math.floor(chartDataFlatten.table.length / 10);
      let xPos = chartDataFlatten.table.length % 10;
      //chartDataFlatten.table.push({ id: chartDataFlatten.table.length, category: chartData.table[proportionIndex].category, color:  colorList[proportionIndex], amount: 1, x: xPos * sideLength, y: yPos * sideLength});

      chartDataFlatten.table.push({
        id: chartDataFlatten.table.length,
        category: chartData.table[proportionIndex].category,
        color: chartData.table[proportionIndex].color,
        amount: 1,
        x: xPos * sideLength,
        y: yPos * sideLength,
      });
    }
    */
  }

  let specWaffle = {
    width: chartWidth,
    height: chartHeight,
    //"autosize": {"type": "pad", "resize": true},
    //"padding": 20,

    data: [{ name: "table" }],
    usermeta: {
      embedOptions: {
        actions: false
      }
    },
    
    title: {
      text: { value: chartTitle },
      fontSize: 26,
      anchor: "start",
      baseline: "top",
      offset: 25,
      //frame: "group",
    },
    
    scales: [
      {
        name: "color",
        type: "ordinal",
        //"range": {"scheme": "category10"},
        domain: { data: "table", field: "category" },
        range: { data: "table", field: "color" },
      },
    ],

    legends: [
      {
        title: legendTitle,
        titleFontSize: 18,
        titleLimit: chartWidth, //350, //300
        titleAnchor: "start",
        //titleBaseline: "top",

        //type: {value: "symbol"},

        symbolType: { value: symbolShape },

        symbolSize: 196,

        labelFontSize: 14,
        labelLimit: chartWidth, //deviceType == "tablet"? 250: 300, // 300
        //"symbolLimit": 3,
        stroke: "color",
        fill: "color",
        orient: "top",
        offset: {value: legendOffset},
        //padding: 15,

        /*
        properties: {
          symbols: {
            shape: {value: "square"},
            //shape: "square",
            fillOpacity: { value: 1.0 },
            stroke: { value: "transparent" },
          },
        },
        */
      },
    ],

    marks: [
      {
        type: "symbol",
        from: { data: "table" },
        encode: {
          enter: {
            shape: { value: symbolShape },
            size: { value: (sideLength * sideLength) }, //    /10
            width: { value: sideLength },
            height: [{ field: "height" }], //{value: sideLength},
            fill: [{ field: "color" }],
            fillOpacity: [{ field: "fillOpacity" }],
            stroke: { value: "white" },
            strokeWidth: { value: 0.5 },
            zindex: [{ field: "zIndex" }],
          },
          update: {
            x: { field: "x" },
            y: { field: "y" },
          },
        },
        transform: [
          {
            type: "force",
            iterations: 300,
            //"restart": {"signal": "restart"},
            static: { value: true },
            signal: "force",
            forces: [
              {
                force: "collide",
                iterations: { value: 30 },
                radius: { value: sideLength / 10 },
              },
            ],
          },
        ],
      },
    ],
  };

  //console.log(chartDataFlatten);
  // method 2:use MyVega to provide layout
  return <MyVega spec={specWaffle} data={chartDataFlatten} chartTitle={props.data.chartTitle} legendTitle={props.data.legendTitle}/>;



  // method 1: use Vega directly
  //return <Vega spec={specWaffle} data={chartDataFlatten} />;
}

// method 3: align left or right in columns
/*
      if (proportionIndex < chartData.table.length - 1) {
        switch (proportionIndex) {
          case 0:
            xPos =
              sideLength / 2 +
              sideLength * amountDrew.dividedToIntegerBy(Decimal(10));
            yPos = sideLength/2 + sideLength * amountDrew.mod(Decimal(10));
            break;
          case 1:
            xPos =
              chartWidth -
              sideLength * amountDrew.dividedToIntegerBy(Decimal(10));
            yPos = sideLength/2 + sideLength * amountDrew.mod(Decimal(10));
            break;
        }
      } else {
        xPos = sideLength / 2 + Math.random() * (chartWidth - sideLength / 2);
        yPos = sideLength/2 + Math.random() * (chartHeight - sideLength);
      }
      */
// method 2: align left or right
/*
      switch(proportionIndex){
          case 0:
            xPos = sideLength / 2 + Math.random() * (chartWidth/2 - sideLength);
            yPos = sideLength + Math.random() * (chartHeight - sideLength);
            break;
          case 1:
            xPos = chartWidth / 2 + sideLength / 2  + Math.random() * (chartWidth/2 - sideLength / 2);
            yPos = sideLength + Math.random() * (chartHeight - sideLength);
            break;
          default:
            xPos = sideLength / 2 + Math.random() * (chartWidth - sideLength / 2);
            yPos = sideLength + Math.random() * (chartHeight - sideLength);
            break;
      }
      */

// method 1: random
/*
      let xPos = sideLength / 2 + Math.random() * (chartWidth - sideLength / 2);
      let yPos = sideLength + Math.random() * (chartHeight - sideLength);
      */

/*
  "data": [
    {
      "name": "temperature",
      "url": "https://vega.github.io/vega-editor/app/data/seattle-temps.csv",
      "format": {"type": "csv", "parse": {"temp": "number", "date": "date"}},
      "transform": [
        {"type": "formula", "field": "hour", "expr": "hours(datum.date)"},
        { "type": "formula", "field": "date",
          "expr": "datetime(year(datum.date), month(datum.date), date(datum.date))"},
          { "type": "filter", "test": "month(datum.date) < 1 && date(datum.date) <= 5 && datum.hour <= 5" }
      ]
    }
  ],
  */
/*
  "scales": [
    {
      "name": "x",
      "type": "ordinal",
      "domain": {"data": "table", "field": "xPos"},
      "range": "width"
    }, {
      "name": "y",
      "type": "ordinal",
      "domain": {"data": "table", "field": "yPos"},
      "range": "height",
      "round": false
    }, {
      "name": "c",
      "type": "linear",
      "domain": [37.5, 41.3, 45.1, 48.9, 52.7, 56.5, 60.3, 64.1, 67.9, 71.7, 75.5],
      "range": ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43", "#d73027", "#a50026"],
      "zero": false
    }
  ],
  */

/*
  {test: "datum.category == 'P'", value: proportionColor},
  { value: nonProportionColor }
  */
