import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import MyAccordion from "../components/MyAccordion";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";

class BenefitHarmPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }


  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language} = this.state;

    console.log("language: " + language);

    var pageContent = "";//Content.all_content[language].benefitHarmPage;


    if (Content.all_content != undefined &&
      Content.all_content[language] != undefined && 
      Content.all_content[language]["benefitHarmPage"] != undefined) {
      pageContent = Content.all_content[language]["benefitHarmPage"];
    } else {
      pageContent = Content.all_content["English"]["benefitHarmPage"];
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            changeRoute = {this.onRouteChange}
            {...others}
          />
        </div>
        <Banner
          src={pageContent.banner_image_source}
          title={pageContent.banner_title}
          heightOption = {language == "English"? "small": "normal"}
          deviceType={deviceType}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">{pageContent.benefit_harm_content.benefit.title}</div>
            <p>{pageContent.benefit_harm_content.benefit.content}</p>
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">{pageContent.benefit_harm_content.harm.title}</div>
            <p>{pageContent.benefit_harm_content.harm.content}</p>
            <MyAccordion list={pageContent.sample_list} />
            <p>{pageContent.more_info}</p>
            <div>
              <div className="is-size-6">
                {pageContent.image_info.head}
                <a
                  href={pageContent.image_info.src}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pageContent.image_info.text}
                </a>
              </div>
            </div>
            <MyNavigator
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
            />
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default BenefitHarmPage;