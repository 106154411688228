import React, { Fragment } from "react";
import "../css/bulma-timeline.css";

export default function MyTimeline(props) {
  let deviceType = props.deviceType != undefined ? props.deviceType : "desktop";
  let topHeaderLabel =
    props.topHeaderLabel != undefined ? props.topHeaderLabel : "Now";
  let bottomHeaderLabel =
    props.bottomHeaderLabel != undefined ? props.bottomHeaderLabel : "Begin";
  let eventList = props.eventList != undefined ? props.eventList : [];

  return (
    <div className="timeline">
      <header className="timeline-header">
        <span className="tag is-medium is-primary">{topHeaderLabel}</span>
      </header>
      {eventList
        .sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        })
        .map((item, index) => {
          let markerColor =
            item.marker_color != undefined ? item.marker_color : "is-primary";

          let markerType =
            item.marker_type != undefined ? item.marker_type : "";

          let markerIconType =
            item.marker_icon_type != undefined ? item.marker_icon_type : "";
          let markerImageSize =
            item.marker_image_size != undefined ? item.marker_image_size : "";
          let markerImageSource =
            item.marker_image_source != undefined
              ? item.marker_image_source
              : "";


          let needYearLabel = false;
          let year = new Date(item.timestamp).getFullYear();
          /*
          if( index  == eventList.length - 1){
              // the last one, so show the timestamp anyway
              needYearLabel = true;
          }
          */

          if(index  < eventList.length - 1){
            let nextItem = eventList[index + 1];

            if( new Date(nextItem.timestamp).getFullYear() != new Date(item.timestamp).getFullYear()){
                needYearLabel = true;
            }
          }
          return (
            <Fragment>
              <div className={`timeline-item ${markerColor}`} key={index}>
                {
                  <div
                    className={`timeline-marker ${markerColor} ${markerType} ${markerImageSize}`}
                  >
                    {markerType == "is-icon" ? (
                      <i className={markerIconType}></i>
                    ) : null}
                    {markerType == "is-image" ? (
                      <img src={markerImageSource} />
                    ) : null}
                  </div>
                }
                <div className="timeline-content">
                  <p className="heading">{item.label}</p>
                  <p>{item.description}</p>
                </div>
              </div>
              {
                  needYearLabel? <header className="timeline-header">
                  <span className="tag is-primary">{year}</span>
                </header>
                : 
                null
              }
            </Fragment>
          );
        })}
      <header className="timeline-header">
        <span className="tag is-medium is-primary">{bottomHeaderLabel}</span>
      </header>
    </div>
  );
}
