import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import FieldGroup from "../components/FieldGroup";
import RadioGroup from "../components/RadioGroup";
import SelectGroup from "../components/SelectGroup";
import ServerService from "../ServerService";
import MyUtility from "../MyUtility";
import MyCalculation from "../utils/MyCalculation";
import MyPageHead from "../components/MyPageHead";
import MyNavigator from "../components/MyNavigator";

import Header from "../components/Header";
import { Redirect } from "react-router";

import Variable from "../Variable";
import ControlLabel from "react-bootstrap/lib/ControlLabel";


//import Button from "react-bootstrap/lib/Button";

const buttonStyle = {
  width: 150
};

class CalculatePage extends Component {
  constructor(props) {
    super(props);

    let linkLanguage = MyUtility.getLanguageFromURL(
      this.props.location.pathname
    );

    this.state = {
      formOld: "",
      formCurrentStatus: "",
      formAgeLastSmoke: "",
      formYearsSmoking: "",
      formCigarettesPerDay: "",
      formGender: "",
      formInsurance: "",
      formHighestGradeCompleted: "",
      formRaceEthnicity: "",
      formTallFeet: "",
      formTallInch: "",
      formWeight: "",
      formDoctorToldCancer: "",
      formFamilyHistory: "",
      formDoctorToldCOPD: "",
      errorMsg: "",
      redirectToResult: false,
      redirectToResultURL: "",
      language: linkLanguage, //localStorage.getItem("language"),
      errorExist: false,
      formOldError: false,
      formCurrentStatusError: false,
      formAgeLastSmokeError: false,
      formYearsSmokingError: false,
      formCigarettesPerDayError: false,
      formWeightError: false
    };

    this.calculateRisk = this.calculateRisk.bind(this);
    this.getRiskResultURL = this.getRiskResultURL.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceiveProps.location.pathname: " +
        nextProps.location.pathname
    );
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  /*
  shouldComponentUpdate(nextProps, nextState){
    console.log("shouldComponentUpdate: this.props.location: "+ JSON.stringify(this.props.location, null, 2));
    console.log("shouldComponentUpdate:  nextProps.location: "+ JSON.stringify(nextProps.location, null, 2));
    return this.props.location != nextProps.location;
  }
  */

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);

    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  setErrorMessage(msg) {
    this.setState({
      errorMsg: msg
    });
  }

  calculateRisk() {
    console.log("calculateRisk");

    const {
      language,
      formOld,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      errorMsg
    } = this.state;

    // mapping
    // mapping
    var currentage = formOld;
    var currentsmokingstatus = formCurrentStatus;
    var agequitsmoking = formAgeLastSmoke;
    var currentsmokingduration = formYearsSmoking;
    var cigsperday = formCigarettesPerDay;
    var sex = formGender;
    var education = formHighestGradeCompleted;
    var race = formRaceEthnicity;
    var heightft = formTallFeet;
    var heightin = formTallInch;
    var weight = formWeight;
    var personalhistory = formDoctorToldCancer;
    var familyhistory = formFamilyHistory;
    var copd = formDoctorToldCOPD;
    var insurance = formInsurance;

    // now the original code

    var errorHelpText = {
      errorExist: false,
      formOldError: false,
      formCurrentStatusError: false,
      formAgeLastSmokeError: false,
      formYearsSmokingError: false,
      formCigarettesPerDayError: false,
      formWeightError: false
    };
    var errorExist = false;

    //var currentage=document.getElementById('txt_currentage').value;
    currentage = Number(currentage);
    // only verify and do any of this if someone is not a never smoker

    if (currentsmokingstatus != 2) {
      if (
        !MyUtility.isNumber(currentage) ||
        currentage > 100 ||
        Number(currentage) < 18
      ) {
        /*
        this.setErrorMessage(
          "Question 1 is a required field, and the valid age range for this calculator is from 18 to 100 years old."
        );
        */

        errorHelpText.formOldError = true;
        errorExist = true;

        //return false;
      }

      if (currentsmokingstatus == -1) {
        /*
        this.setErrorMessage("Question 2 is a required field.");
        */
        errorHelpText.formCurrentStatusError = true;
        errorExist = true;
        //return false;
      }

      //var agequitsmoking = document.getElementById('txt_agequitsmoking').value;
      agequitsmoking = Number(agequitsmoking);
      if (
        agequitsmoking > currentage ||
        (currentsmokingstatus == 0 && agequitsmoking == "")
      ) {
        /*
        this.setErrorMessage(
          "You have indicated that you are former smoker. Please ensure that you have filled in a number that is less than your current age in Question 1.1."
        );
        */
        errorHelpText.formAgeLastSmokeError = true;
        errorExist = true;
        //return false;
      }

      if (agequitsmoking == "" || currentsmokingstatus == 1) {
        agequitsmoking = currentage;
      }
      var currentyearsquit = currentage - agequitsmoking;

      //var currentsmokingduration = document.getElementById('txt_currentsmokingduration').value;

      currentsmokingduration = Number(currentsmokingduration);
      if (
        !MyUtility.isNumber(currentsmokingduration) ||
        Number(currentsmokingduration) > Number(currentage) ||
        Number(currentyearsquit) + Number(currentsmokingduration) >
          Number(currentage)
      ) {
        /*
        this.setErrorMessage(
          "Question 3 is a required field. Please ensure that this value is less than your current age, and that it is a number."
        );
        */
        errorHelpText.formYearsSmokingError = true;
        errorExist = true;
        //alert("Age: " + Number(currentage) + "\n" + "Years Quit: " + Number(currentyearsquit) + "\n" + "Duration: " + Number(currentsmokingduration) + "\n" );
        //return false;
      }

      //var averageCPD= document.getElementById('txt_cigsperday').value;
      var averageCPD = cigsperday;

      averageCPD = Number(averageCPD);
      if (!MyUtility.isNumber(averageCPD) || averageCPD > 200) {
        /*
        this.setErrorMessage(
          "Question 4 is a required field. Please ensure that you have filled in a number between 0 and 200."
        );
        */
        errorHelpText.formCigarettesPerDayError = true;
        errorExist = true;
        //return false;
      }
      //var sex = document.getElementById('txt_sex').value;
      if (sex == "") {
        sex = "Male";
      }
      //var education=document.getElementById('txt_education').value;
      if (!MyUtility.isNumber(education)) {
        education = 3;
      }
      //var heightft = document.getElementById('txt_heightft').value * 12;
      //var heightin = document.getElementById('txt_heightin').value;
      //heightin = +heightin + heightft;
      heightin = +heightin + heightft * 12;

      //var weight = document.getElementById('txt_weight').value;
      weight = Number(weight);
      if (weight > 0 && (weight > 1000 || weight < 45)) {
        /*
        this.setErrorMessage(
          "Please ensure that you have entered a number between 45 and 1000 in Question 9. This is not a required field. Leaving it empty will assign a default value to our calculation." +
            weight
        );
        */
        errorHelpText.formWeightError = true;
        errorExist = true;
        //return false;
      }

      // ok, now, check
      if (errorExist) {
        errorHelpText.errorExist = true;
      }

      this.setState(errorHelpText);

      if (errorExist) {
        window.scrollTo(0, 0);
        return {calculatedRisk: false, expout: ""};
      }

      var bmi = weight / (heightin * heightin) * 703;

      if (!MyUtility.isNumber(bmi)) {
        bmi = 27;
      }

      // original code
      /*
  var copd = 0; 
	if ( document.getElementById('txt_copd').checked ) 
	{ copd= 1; } 
  */
      if (!MyUtility.isNumber(copd)) {
        copd = 0;
      }

      // original code
      /*
  var personalhistory= 0;
	if ( document.getElementById('txt_personalhistory').checked ) 
	{personalhistory= 1} 
  */

      if (!MyUtility.isNumber(personalhistory)) {
        personalhistory = 0;
      }

      // original code
      /*
  var familyhistory= 0;
  if ( document.getElementById('txt_familyhistory').checked ) 
	{ familyhistory= 1}
  */

      if (!MyUtility.isNumber(familyhistory)) {
        familyhistory = 0;
      }

      // original code
      //var race=document.getElementById('txt_race').value;
      if (!MyUtility.isNumber(race)) {
        race = 0;
      }

      var Coeffs = [
        0.0778868,
        -0.0812744,
        -0.0274194,
        0.3553063,
        0.4589971,
        0.587185,
        0.2597431,
        -1.822606,
        0.0317321,
        -0.0308572
      ];
      // var Racecoeffs=[0,0.3944778,-0.734744,-0.466585,0,1.027152];
      var Racecoeffs = [0, 0.3944778, -0.734744, -0.466585, 1.027152, 0];
      var agecentervalue = 62;
      var educationcentervalue = 3;
      var bmicentervalue = 27;
      var CPDcentervalue = 0.4021541613;
      var Smokingdurationcentervalue = 27;
      var Smokingcessationcentervalue = 10;
      var Modelconstant = -4.532506;
      var Agecontribution = (currentage - agecentervalue) * Coeffs[0];
      var Educationcontribution =
        (education - educationcentervalue) * Coeffs[1];
      var Bmicontribution = (bmi - bmicentervalue) * Coeffs[2];
      var Copdcontribution = copd * Coeffs[3];
      var Personalhistorycontribution = personalhistory * Coeffs[4];
      var Familyhistorycontribution = familyhistory * Coeffs[5];
      var Smokingstatuscontribution = currentsmokingstatus * Coeffs[6];
      var CPDcontribution =
        (Math.pow(averageCPD / 10, -1) - CPDcentervalue) * Coeffs[7];
      var Smokingdurationcontribution =
        (currentsmokingduration - Smokingdurationcentervalue) * Coeffs[8];
      var Smokingcessationcontribution =
        (currentyearsquit - Smokingcessationcentervalue) * Coeffs[9];
      var Racecontribution = Racecoeffs[race];
      var Sumvalues =
        Modelconstant +
        Agecontribution +
        Educationcontribution +
        Bmicontribution +
        Copdcontribution +
        Personalhistorycontribution +
        Familyhistorycontribution +
        Smokingstatuscontribution +
        CPDcontribution +
        Smokingdurationcontribution +
        Smokingcessationcontribution +
        Racecontribution;
      var packyears = currentsmokingduration * averageCPD / 20;
      var expout = "no";
      if (
        50 <= currentage &&
        currentage <= 80 &&
        packyears >= 20 &&
        currentyearsquit <= 15
      ) {
        expout = "yes";
      }
      var Sixyearprobability = Math.exp(Sumvalues) / (1 + Math.exp(Sumvalues));
      Sixyearprobability = (Sixyearprobability * 100).toFixed(1);
      if (Sixyearprobability > 30) {
        Sixyearprobability = Math.round(Sixyearprobability);
      }


      return { calculatedRisk: Sixyearprobability, 
              expout: expout
            };
      
    } else {
      // Pei-Yao: using zero to represnt non-smoker. Need to verify with Lisa and Rafael
      return { calculatedRisk: 0, 
        expout: ""
      };
    }
  }

  getRiskResultURL(risk, expout) {
    console.log("getRiskResultURL");

    const {
      language,
      formOld,
      formCurrentStatus,
      formGender,
      formInsurance,
      formYearsSmoking,
      formCigarettesPerDay
    } = this.state;

    
    var currentsmokingstatus = formCurrentStatus;
    var currentage = formOld;
    var sex = formGender;
    var insurance = formInsurance;
    
    currentage = Number(currentage);
    if (sex == "") {
      sex = "male";
    }

    var newLoc = "";

    var imglocation = "";

    if (currentsmokingstatus != 2) {

      let Sixyearprobability = risk;

      let packYears = MyCalculation.calculatePackYears(formYearsSmoking, formCigarettesPerDay);

      // On 2020.08.19
      imglocation = `${sex}_${Sixyearprobability}_${expout}_${insurance}_${formOld}_${packYears}`;

      // before 2020.08.19
      //imglocation = sex + "_" + Sixyearprobability + "_" + expout + "_" + insurance; // + ".png";
      
      imglocation = imglocation.toLowerCase();
      
      switch (language) {
        case "English":
          newLoc = "/" + language + "/result/" + imglocation;
          break;
        case "Español":
          newLoc = "/" + language + "/resultado/" + imglocation;
          break;
        default:
          break;
      }

    } else {

      switch (language) {
        case "English":
          newLoc = "/" + language + "/result/" + "never_smoked";
          break;
        case "Español":
          newLoc = "/" + language + "/resultado/" + "never_smoked";
          break;
        default:
          break;
      }
    }

    return newLoc;
  }

  handleChange(event) {
    console.log("handleChange: " + event.target);

    const target = event.target;

    var identifier = "";
    var value = "";

    var type = target.type;

    var name = target.name;

    switch (target.type) {
      case "checkbox":
        identifier = target.name;
        value = target.checked;
        break;
      case "radio":
        identifier = target.name;
        value = target.value;
        break;
      default:
        identifier = target.id;
        value = target.value;
        break;
    }

    this.setState({
      [identifier]: value
    });

    console.log("handleChange - " + this.state.identifier + ": " + value);
  }

  handleSubmit(event) {
    console.log("handleSubmit: " + event.target.value);
    event.preventDefault();

    const {
      formOld,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      errorMsg,
      language
    } = this.state;

    const{ calculatedRisk, expout} = this.calculateRisk();

    console.log("calculatedRisk: " + calculatedRisk + ", expout: " + expout);

    if (calculatedRisk === false) {
      // there is some error in the input
      // we should not be submitting
      return;
    }

    //alert("About to submit: " + calculatedRisk);
    var newLoc = this.getRiskResultURL(calculatedRisk, expout);

    let eligibility = expout;



    var cached_pack_id = Variable.getValue("pack_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_feedback_id = Variable.getValue("feedback_id");

    ServerService.submitRiskCalculation(
      formOld,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      calculatedRisk,
      eligibility,
      cached_feedback_id,
      cached_pack_id,
      cached_reflection_id,
      language
    )
      .then(response => {
        console.log(response);
        console.log("Submit Risk: " + JSON.stringify(response));
        Variable.setValue("risk_id", response.data.insert_id);

        // option 1: will trigger reload
        //this.props.history.push(newLoc);

        // option 2: might not reload?
        this.setState({
          redirectToResult: true,
          redirectToResultURL: newLoc
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const {
      formOld,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      errorMsg,
      redirectToResult,
      redirectToResultURL,
      language,
      errorExist = false,
      formOldError,
      formCurrentStatusError,
      formAgeLastSmokeError,
      formYearsSmokingError,
      formCigarettesPerDayError,
      formWeightError
    } = this.state;

    console.log("formCurrentStatus: " + formCurrentStatus);

    if (redirectToResult) {
      return <Redirect push to={redirectToResultURL} />;
    }

    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    console.log("language: " + language);

    var pageContent = "";

    if (
      Content.all_content != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["calculatePage"] != undefined
    ) {
      pageContent = Content.all_content[language]["calculatePage"];
    } else {
      pageContent = Content.all_content["English"]["calculatePage"];
    }

    /*
    let webTitle = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */
    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="content has-text-justified">
        <div className="top-sticky">
            <Header
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
              deviceType={deviceType}
              savedContent={savedContent}
              changeLanguage={this.onLanguageChange}
              {...others}
            />
          </div>
          <Banner
            src={pageContent.banner_image_source}
            title={pageContent.banner_title}
            heightOption={language == "English" ? "small" : "normal"}
            deviceType={deviceType}
          />
          <div className="columns is-mobile is-centered">
            <div className="column is-three-quarters">
              <div className="has-text-weight-bold is-size-3 is-size-4-mobile">
                {pageContent.title}
              </div>
              <p>{pageContent.instruction.normal}</p>

              {errorExist && (
                <p style={{ color: "red" }}>
                  {pageContent.error_input_guidance}
                </p>
              )}

              {pageContent.instruction.strong_text_list.map((item, index) => {
                return (
                  <p key={index}>
                    <strong>{item}</strong>
                  </p>
                );
              })}
              <form onSubmit={this.handleSubmit}>
                <FieldGroup
                  id="formOld"
                  type="text"
                  label={pageContent.risk_questions.formOld.label}
                  onChange={this.handleChange}
                  placeholder={pageContent.risk_questions.formOld.placeholder}
                  displayHelp={formOldError}
                  help={pageContent.risk_questions.formOld.help_text}
                />
                <RadioGroup
                  id="formCurrentStatus"
                  label={pageContent.risk_questions.formCurrentStatus.label}
                  options={pageContent.risk_questions.formCurrentStatus.options}
                  onChange={this.handleChange}
                  placeholder={
                    pageContent.risk_questions.formCurrentStatus.placeholder
                  }
                  displayHelp={formCurrentStatusError}
                  help={pageContent.risk_questions.formCurrentStatus.help_text}
                />
                {formCurrentStatus == 0 /* "Former Smoker" */ && (
                  <FieldGroup
                    id="formAgeLastSmoke"
                    type="text"
                    label={pageContent.risk_questions.formAgeLastSmoke.label}
                    onChange={this.handleChange}
                    placeholder={
                      pageContent.risk_questions.formAgeLastSmoke.placeholder
                    }
                    displayHelp={formAgeLastSmokeError}
                    help={pageContent.risk_questions.formAgeLastSmoke.help_text}
                  />
                )}
                {formCurrentStatus != 2 /* "Never Smoker" */ && (
                  <div>
                    <FieldGroup
                      id="formYearsSmoking"
                      type="text"
                      label={pageContent.risk_questions.formYearsSmoking.label}
                      onChange={this.handleChange}
                      placeholder={
                        pageContent.risk_questions.formYearsSmoking.placeholder
                      }
                      displayHelp={formYearsSmokingError}
                      help={
                        pageContent.risk_questions.formYearsSmoking.help_text
                      }
                    />
                    <FieldGroup
                      id="formCigarettesPerDay"
                      type="text"
                      label={
                        pageContent.risk_questions.formCigarettesPerDay.label
                      }
                      onChange={this.handleChange}
                      placeholder={
                        pageContent.risk_questions.formCigarettesPerDay
                          .placeholder
                      }
                      displayHelp={formCigarettesPerDayError}
                      help={
                        pageContent.risk_questions.formCigarettesPerDay
                          .help_text
                      }
                    />
                    <SelectGroup
                      id="formGender"
                      type="text"
                      label={pageContent.risk_questions.formGender.label}
                      selectLabel={pageContent.default_select_label}
                      options={pageContent.risk_questions.formGender.options}
                      onChange={this.handleChange}
                      value={formGender}
                      placeholder={
                        pageContent.risk_questions.formGender.placeholder
                      }
                    />
                    <SelectGroup
                      id="formInsurance"
                      type="text"
                      label={pageContent.risk_questions.formInsurance.label}
                      selectLabel={pageContent.default_select_label}
                      options={pageContent.risk_questions.formInsurance.options}
                      onChange={this.handleChange}
                      value={formInsurance}
                      placeholder={
                        pageContent.risk_questions.formInsurance.placeholder
                      }
                    />
                    <SelectGroup
                      id="formHighestGradeCompleted"
                      type="text"
                      label={
                        pageContent.risk_questions.formHighestGradeCompleted
                          .label
                      }
                      selectLabel={pageContent.default_select_label}
                      options={
                        pageContent.risk_questions.formHighestGradeCompleted
                          .options
                      }
                      onChange={this.handleChange}
                      placeholder={
                        pageContent.risk_questions.formHighestGradeCompleted
                          .placeholder
                      }
                    />

                    <SelectGroup
                      id="formRaceEthnicity"
                      type="text"
                      label={pageContent.risk_questions.formRaceEthnicity.label}
                      selectLabel={pageContent.default_select_label}
                      options={
                        pageContent.risk_questions.formRaceEthnicity.options
                      }
                      onChange={this.handleChange}
                      placeholder={
                        pageContent.risk_questions.formRaceEthnicity.placeholder
                      }
                    />

                    <div>
                      <ControlLabel>{pageContent.tall_question}</ControlLabel>
                      <div className="columns">
                        <div className="column">
                          <SelectGroup
                            id="formTallFeet"
                            type="text"
                            label={
                              pageContent.risk_questions.formTallFeet.label
                            }
                            selectLabel={pageContent.default_select_label}
                            rightSideLabel={
                              pageContent.risk_questions.formTallFeet
                                .right_side_label
                            }
                            options={
                              pageContent.risk_questions.formTallFeet.options
                            }
                            onChange={this.handleChange}
                            placeholder={
                              pageContent.risk_questions.formTallFeet
                                .placeholder
                            }
                          />
                        </div>
                        <div className="column">
                          <SelectGroup
                            id="formTallInch"
                            type="text"
                            label={
                              pageContent.risk_questions.formTallInch.label
                            }
                            selectLabel={pageContent.default_select_label}
                            rightSideLabel={
                              pageContent.risk_questions.formTallInch
                                .right_side_label
                            }
                            options={
                              pageContent.risk_questions.formTallInch.options
                            }
                            onChange={this.handleChange}
                            placeholder={
                              pageContent.risk_questions.formTallInch
                                .placeholder
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <FieldGroup
                      id="formWeight"
                      type="number"
                      label={pageContent.risk_questions.formWeight.label}
                      onChange={this.handleChange}
                      placeholder={
                        pageContent.risk_questions.formWeight.placeholder
                      }
                      displayHelp={formWeightError}
                      help={pageContent.risk_questions.formWeight.help_text}
                    />
                    <RadioGroup
                      id="formDoctorToldCancer"
                      label={
                        pageContent.risk_questions.formDoctorToldCancer.label
                      }
                      options={
                        pageContent.risk_questions.formDoctorToldCancer.options
                      }
                      onChange={this.handleChange}
                      inline={true}
                      placeholder={
                        pageContent.risk_questions.formDoctorToldCancer
                          .placeholder
                      }
                    />
                    <RadioGroup
                      id="formFamilyHistory"
                      label={pageContent.risk_questions.formFamilyHistory.label}
                      options={
                        pageContent.risk_questions.formFamilyHistory.options
                      }
                      onChange={this.handleChange}
                      inline={true}
                      placeholder={
                        pageContent.risk_questions.formFamilyHistory.placeholder
                      }
                    />
                    <RadioGroup
                      id="formDoctorToldCOPD"
                      label={
                        pageContent.risk_questions.formDoctorToldCOPD.label
                      }
                      options={
                        pageContent.risk_questions.formDoctorToldCOPD.options
                      }
                      onChange={this.handleChange}
                      inline={true}
                      placeholder={
                        pageContent.risk_questions.formDoctorToldCOPD
                          .placeholder
                      }
                    />
                  </div>
                )}
                {errorMsg.length > 0 && <div>{errorMsg}</div>}
                
                <input className="button" type="submit" value={pageContent.submit_button_label}></input>
              </form>
              <MyNavigator
                currentRoute={this.props.location.pathname}
                language={language}
                Content={Content}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CalculatePage;

// <Button type="submit">{pageContent.submit_button_label}</Button>