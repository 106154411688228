import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";

const imageStyle = {
  width: "90%",
  objectFit: "cover",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto"
};

const dividerStyle = {
  height: 50,
  opacity: 0
};

class NoPage extends Component {
  constructor(props) {
    super(props);
    
    let pathName = this.props.location.pathname;
    let linkLanguage = pathName.substring(1, pathName.indexOf("/", 1));
    this.state = {
      language: linkLanguage.length > 2? linkLanguage: "English" //localStorage.getItem("language")
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);

    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (
      Content.all_content[language] != undefined &&
      Content.all_content[language]["noPage"] != undefined
    ) {
      pageContent = Content.all_content[language]["noPage"];
    } else {
      pageContent = Content.all_content["English"]["noPage"];
    }
    //alert("language: " + language);
    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf("") >= 0;
    })[0].label;
    */


    return (
      <Fragment>
        <MyPageHead headInfo={{title:pageContent.title}}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
            style={{ width: "100%" }}
          />
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="is-mobile is-centered">
              {pageContent.not_found_message}<br/>
              <div className="is-mobile is-centered has-text-centered">
              <Link
                to={pageContent.take_me_home_link}
                style={{ width: "100%", textDecoration: "none" }}
              >
                {pageContent.take_me_home_label}
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default NoPage;
