import React, { Component, Fragment } from "react";

//import DropdownButton from "react-bootstrap/lib/DropdownButton";
//import MenuItem from "react-bootstrap/lib/MenuItem";
//import Glyphicon from "react-bootstrap/lib/Glyphicon";

import { Link } from "react-router-dom";
import MyUtility from "../MyUtility";

import Content from "../Content";

import {
  isIE,
} from "react-device-detect";


import MyDropdown from "../components/MyDropdown";

//import "../css/bootstrap.min.css";

import "../../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";


const headerStyle = {
  backgroundColor: "#339479",
  //width: "100%",
  paddingTop: 15
};

const noMarginRightStyle = {
  //marginRight: 0
};

const linkStyle = {
  color: "#000000",
  verticalAlign: "inherit"
};

const textStyle = {
  color: "#FFFFFF"
};

const toolbarGroupStyle = {
  //float: "none",
  //width: "auto", //"200px"
  //marginLeft: "auto",
  //marginRight: "auto"
};

class Header extends Component {
  constructor(props) {
    super(props);
    const { language, changeLanguage } = this.props;

    this.state = {
      value: 0,
      language: MyUtility.getLanguageFromURL(this.props.location.pathname), //language,
    };

    this.handleClick = this.handleClick.bind(this);

    this.routeToValue = this.routeToValue.bind(this);
    this.onLanguageClick = this.onLanguageClick.bind(this);
    this.getNewLanguageRouteFromURL = this.getNewLanguageRouteFromURL.bind(this);
  }

  componentWillMount() {
    console.log("Header.componentWillMount");
  }

  componentDidMount() {
    /*
    const { addNotification } = this.props;
    //gAnalytics();

    let route = this.props.location.pathname;

    console.log(`header: route: ${route}`);

    let language = MyUtility.getLanguageFromURL(route);

    console.log(`header: language: ${language}`);
    */


    

    
  }

  componentWillUpdate() {
    console.log("Header.componentWillUpdate: props.location: " + JSON.stringify(this.props.location, null, 2));
  }

  componentDidUpdate() {
    console.log("Header.componentDidUpdate");
  }
  /*
  shouldComponentUpdate(nextProps, nextState){
    console.log("Header.shouldComponentUpdate: this.props.location: "+ JSON.stringify(this.props.location, null, 2));
    console.log("Header.shouldComponentUpdate:  nextProps.location: "+ JSON.stringify(nextProps.location, null, 2));

    return this.props.location != nextProps.location;
  }
  */

  handleClick(index, route) {
    //console.log("handleClick.index: " + index);
  }

  componentWillReceiveProps(nextProps) {
    console.log("Header.componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    console.log("Header.componentWillReceiveProps.language: " + nextProps.language);

    this.setState({
      language: nextProps.language
    });

    const { changeLanguage } = this.props;

    if (this.props.language != nextProps.language) {
      //alert("Header - switch language to " +  nextProps.language);
      changeLanguage(nextProps.language);
    }
  }

  getNewLanguageRouteFromURL(newLanguage) {
    console.log("getNewLanguageRouteFromURL: " + newLanguage);
    const { Content } = this.props;

    let curURL = this.props.location.pathname;
    var newRoute = "";

    //let language = this.getLanguageFromURL();

    var pageContent;
    var routeOrderList;

    // now find the original link (current link)

    var resultIndex = -1;

    let originalRoute = this.props.location.pathname;
    console.log("originalRoute: " + originalRoute);
    resultIndex = this.routeToValue(originalRoute);

    // now figure out hte new route
    if (
      newLanguage != undefined &&
      Content.all_content[newLanguage]["headerComponent"] != undefined
    ) {
      pageContent = Content.all_content[newLanguage]["headerComponent"];
    } else {
      pageContent = Content.all_content["English"]["headerComponent"];
    }
    //console.log("pageContent:" + JSON.stringify(pageContent, null, 2));

    var newRouteOrderList = pageContent.route_order_list;

    var newRoute;
    //alert("Result Index: " + resultIndex);
    // for result page
    if (resultIndex == pageContent.route_in_menu_limit) {
      // it is the result page
      if (originalRoute.indexOf("/English/result") >= 0) {
        newRoute = originalRoute.replace(
          "/English/result",
          "/Español/resultado"
        );
      } else {
        // /Español/resultado
        newRoute = originalRoute.replace(
          "/Español/resultado",
          "/English/result"
        );
      }
    } else if (resultIndex >= 0) {
      //console.log("resultIndex: " + resultIndex);
      //console.log("newRouteOrderList[resultIndex]: " + JSON.stringify(newRouteOrderList[resultIndex], null, 2));

      newRoute = newRouteOrderList[resultIndex].route;
    } else {
      newRoute = "/notfound";
      //alert("NewRoute: " + newRoute.route);
    }

    return newRoute;
  }

  onLanguageClick(item) {
    console.log("onLanguageClick: " + item);


    this.setState({
      language: item
    });

    const { changeLanguage } = this.props;

    changeLanguage(item);

    return;
  }


  routeToValue(route) {
    var resultIndex = -1;

    const { language } = this.state;

    const { Content } = this.props;

    var pageContent;

    //console.log("Header.routeToValue.language: " + language);


    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["headerComponent"] != undefined
    ) {
      pageContent = Content.all_content[language]["headerComponent"];
      //console.log("Find content for language: " +language);
    } else {
      pageContent = Content.all_content["English"]["headerComponent"];
      //console.log("Can't find the languae. Settle for English");
    }

    var routeOrderList = pageContent.route_order_list;
    //console.log("routeOrderList.length" + routeOrderList.length);

    for (var i = 0; i < routeOrderList.length; i++) {

      var curRoute = routeOrderList[i];
      // console.log("Route: " + route + ", curRoute: " +curRoute.route );
      //if (curRoute.route == route) {
      if (route.indexOf(curRoute.route) >= 0) {
        //alert("Match: curRoute: " + curRoute.route + "\n" + "route: " + route);
        resultIndex = i;
        break;
      }
    }

    return resultIndex;
  }

  render() {
    const { value, language } = this.state;

    const { Content, deviceType,savedContent, currentRoute, changeLanguage } = this.props;

    //console.log("Header.render.language: " + language);
    //console.log("Header.render.currentRoute: " + currentRoute);

    var routeIndex = this.routeToValue(currentRoute);
    //console.log("Header.render.routeIndex: " + routeIndex);

    if (routeIndex < 0) {
      routeIndex = 0;
    }

    var pageContent = "";
    

    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["headerComponent"] != undefined
    ) {
      pageContent = Content.all_content[language]["headerComponent"];
    } else {
      pageContent = Content.all_content["English"]["headerComponent"];
    }

    var savedComponentContent = "";
    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["savedComponent"] != undefined
    ) {
      savedComponentContent = Content.all_content[language]["savedComponent"];
    } else {
      savedComponentContent = Content.all_content["English"]["savedComponent"];
    }

    var routeOrderList = pageContent.route_order_list;

    //var routeOrderList = Variable.getValue("route_order_list");

    var languageList = ["English", "Español"];

    // <div className="content has-text-justified flex" style={headerStyle}>

    var menuItemLength = 200;
    if (language == "English") {
      menuItemLength = 220;
    }
    else {
      menuItemLength = 350;
    }
    //  style={{display: "none"}}

    return (
      <div className="content has-text-justified" style={headerStyle} id="headerComponent">
        <div className="columns is-centered is-vcentered is-mobile is-desktop is-variable is-0-mobile is-3-desktop is-8-widescreen is-2-fullhd" style={noMarginRightStyle}>
          <div className="column has-text-centered is-paddingless"
          
          style={{
            //marginLeft: 0,
            width: "100%",
            //maxWidth: "20vw"
          }}

          >
            {languageList.map((item, index) => {
              return item == language ? (
                <div key={index} />
              ) : (
                  <Link
                    key={index}
                    style={textStyle}
                    to={this.getNewLanguageRouteFromURL(item)}
                    onClick={() => { this.onLanguageClick(item) }}
                    data-on="click" data-event-category="Language" data-event-action={item}
                  >
                    <div className="has-text-centered">{item}</div>
                  </Link>
                );
            })}
          </div>
          <div className="column has-text-centered">

            <MyDropdown
              itemList={routeOrderList}
              itemIndex={routeIndex}
              itemLimit={pageContent.route_in_menu_limit}
              menuTitle={pageContent.menu_title}
            > 
            </MyDropdown>

          </div>
          <div
              className="column has-text-centered"
              style={{
                //marginLeft: 0,
                width: "100%",
                //maxWidth: "20vw",
                cursor: "pointer"
              }}
            >
              {
                deviceType == "desktop" && false?
                <Link
                  to={savedComponentContent.saved_url}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="SavedBadge"
                  data-event-action={savedComponentContent.saved_url}
                >
                  <button className="button is-small has-tooltip-bottom has-tooltip-multiline" data-tooltip="You can highlight and save the parts you want to keep for later to print out. Click to see the content you saved!">
                    {savedComponentContent.saved_label + ":" + savedContent.filter((item, index) => { return item.category == "saved" }).length}
                  </button>
                </Link>:
                null
              }
            </div>
        </div>
      </div>
    );
  }
}

export default Header;


/*
<DropdownButton
noCaret
title={
  <div style={{
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }}><Glyphicon glyph="glyphicon glyphicon-menu-hamburger" /> 
  { routeIndex == 0? pageContent.menu_title : routeOrderList[routeIndex].label}
  </div>
}
id="bg-nested-dropdown"

style={{
  width: "100%",
  maxWidth: "50vw",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
}}

drop="down"
>
{
routeOrderList.slice(0, pageContent.route_in_menu_limit).map((item, index) => {
    return (
      <MenuItem
        key={index}
        eventKey={index}
        style={{
          listStyleType: "none",
          margin: 0,
          //left: -20,
          width: menuItemLength,
          //lineHeight: 2,
        }}
        onClick={event => {
          event.preventDefault();
        }}
      >
        <Link
          to={item.route}
          style={{ width: "100%", textDecoration: "none" }}
          data-on="click"
          data-event-category="Header"
          data-event-action={item.route}
        >
          <div className="is-clipped">{item.label}</div>
        </Link>
      </MenuItem>
    );
  })}
</DropdownButton>

*/

/*
              <Link
                to={savedComponentContent.saved_url}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="SavedBadge"
                data-event-action={savedComponentContent.saved_url}
              >
                <div className="is-size-5">
                  <Badge pill variant="light">{savedComponentContent.saved_label + ":" + savedContent.filter((item, index) => { return item.category == "saved" }).length}</Badge>
                </div>
                </Link>

*/

/*
<a
key={index}
style={textStyle}
onClick={event => {
  event.preventDefault();
  this.onLanguageClick(item);
}}
>
{item}
</a>
*/
