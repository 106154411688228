import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";

//import "../css/grid.min.css";

class CoveragePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceiveProps.location.pathname: " +
        nextProps.location.pathname
    );
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = ""; //Content.all_content[language].benefitHarmPage;

    if (
      Content.all_content != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["coveragePage"] != undefined
    ) {
      pageContent = Content.all_content[language]["coveragePage"];
    } else {
      pageContent = Content.all_content["English"]["coveragePage"];
    }

    /*
    let webTitle = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */


    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="content has-text-justified">
        <div className="top-sticky">
            <Header
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
              deviceType={deviceType}
              savedContent={savedContent}
              changeLanguage={this.onLanguageChange}
              changeRoute={this.onRouteChange}
              {...others}
            />
          </div>
          <Banner
            src={pageContent.banner_image_source}
            title={pageContent.banner_title}
            heightOption={language == "English" ? "small" : "normal"}
            deviceType={deviceType}
          />
          <div className="columns is-mobile is-centered">
            <div className="column is-three-quarters">
                <h3 className = "h3">{pageContent.title}</h3>
                <h6 classname = "h6">{pageContent.sub_title}</h6>
              <br />
              <div className="columns is-multiline">
                {pageContent.condition_list.map((item, index) => {
                  return (
                    <div key={index} className="column is-4" >
                      <div className="has-text-centered" >
                        <a
                          href={"#condition" + index}
                          className="button is-large is-fullwidth"
                          style={{
                            whiteSpace: "normal",
                            minHeight: 150
                          }}
                        >
                          <div >{item.title}</div>
                        </a>
                      </div>

                      <div className="col has-text-centered">&nbsp;</div>
                    </div>
                  );
                })}
              </div>
              
              <br />
              {pageContent.condition_list.map((item, index) => {
                return (
                  <div className="box" key={index}>
                    <div id={"condition" + index} className="anchor" />
                    <div className="has-text-weight-semibold is-size-4 is-size-5-mobile">
                      {item.title}
                    </div>
                    <div>{item.introtext}</div>
                    <div>
                      <ul>
                        {item.criteria_list.map((itemC, indexC) => {
                          return <li key={indexC}>{itemC}</li>;
                        })}
                      </ul>
                    </div>
                    <br />
                    <div>{item.note}</div>
                    <br />
                    <div>
                      {item.question_answer_list.map((itemQA, indexQA) => {
                        return (
                          <div key={indexQA}>
                            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
                              {itemQA.question}
                            </div>
                            <p>{itemQA.answer}</p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div>
                {pageContent.citation_text}
              </div>
              <MyNavigator
                currentRoute={this.props.location.pathname}
                language={language}
                Content={Content}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CoveragePage;

/*
<div className="row">
                {pageContent.condition_list.map((item, index) => {
                  return (
                    <div key={index} className="col c4">
                      <div className="has-text-centered">
                        <a
                          href={"#condition" + index}
                          className="button is-large is-fullwidth"
                          style={{
                            whiteSpace: "normal",
                            height: "100px"
                            }}
                        >
                          <div >{item.title}</div>
                        </a>
                      </div>

                      <div className="col has-text-centered">&nbsp;</div>
                    </div>
                  );
                })}
              </div>
*/