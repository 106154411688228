import React, { Component } from "react";

import Glyphicon from "react-bootstrap/lib/Glyphicon";

import Collapsible from "react-collapsible";


import "../css/my-react-accessible-accordion.css";
import "../css/app.css";

const fullWidth = {
  width: "100%"
};

class MyAccordion extends Component {
  constructor(props) {
    super(props);

    //var _accordionBody = null;
    const { list } = this.props;

    var status_list = [];

    list.forEach((element, index) => {
      if (index == 0) {
        status_list.push(true);
      }
      else {
        status_list.push(false);
      }

    });

    this.state = {
      activeKey: "1",
      status_list: status_list
    };

    this.setCollapsibleState = this.setCollapsibleState.bind(this);

    /*
    this.handleSelect = this.handleSelect.bind(this);
    this.onMouseDownListener = this.onMouseDownListener.bind(this);
    */
  }

  /*
  handleSelect(key) {
    this.setState({ activeKey: key });
  }
  */
  /*
  componentDidMount() {
    console.log("MyAccordion.componentDidMount");
    //this._accordionBody = this.refs.accordionBody;

    //this._accordionBody.addEventListener("mousedown", this.onMouseDownListener);
  }
  */

  /*
  - panelCustomClass 
  - header and headerCustomClass
  - body and bodyCustomClass
  - footer and footerCustomClass
  */

  setCollapsibleState(event, index, value) {
    const { status_list } = this.state;
    let new_status_list = status_list;
    new_status_list[index] = value;
    this.setState({
      status_list: new_status_list
    });
  }

  render() {
    const { title = "", list } = this.props;
    const { activeKey, status_list } = this.state;
    //<Flexbox flexDirection="column" minHeight="80vh" width="100%">
    //console.log("Accodion content: " + JSON.stringify(list, null, 2));
    return (
      <div>
        <h2>{title}</h2>
        {list.map((item, index) => {
          // <AccordionItem key={index} expanded={index == 0? true: false}>
          // tyle={{width: "100%"}}
          return (
            <Collapsible
              key={index}
              open={status_list[index]}
              onClosing={(event) => {
                console.log("onClosing: " + index);
                this.setCollapsibleState(event, index, false);
              }}
              onOpening={(event) => {
                console.log("onOpening: " + index);
                this.setCollapsibleState(event, index, true);
              }}
              trigger={
                <div className="hero is-desktop is-mobile" style={{ margin: 10, padding: 5 }}>
                  <div className="columns is-11 is-mobile is-vcentered">
                    <div className="column">
                      <div className="has-text-left is-size-5 is-size-6-mobile" style={{ textOverflow: "ellipsis" }}>{item.title}</div>
                    </div>
                    <div className="column is-1 has-text-right">
                      {status_list[index] ?
                        (<Glyphicon glyph="glyphicon glyphicon-chevron-up" />) :
                        (<Glyphicon glyph="glyphicon glyphicon-chevron-down" />)
                      }
                    </div>
                  </div>
                </div>
              }
              //triggerClassName="accordion-title"
              //openedClassName="has-background-grey-lighter"
              className="accordion-title"
              openedClassName="accordion-title"
              //triggerClassName="accordion"
              triggerOpenedClassName="accordion-title"
              contentOuterClassName="accordion-content"
              contentInnerClassName="accordion-content"
            >
              <div className="content block">{item.content}</div>
            </Collapsible>
          );
        })}
      </div>
    );
  }
}

export default MyAccordion;

// each item
/*
<div className="hero is-desktop is-mobile" style={{margin: 10, padding: 5}}>
<div className="level">
  <div className="level-left">
    <div className="level-item is-size-5 is-size-6-mobile">
      <div className="has-text-left" style={{textOverflow: "ellipsis"}}>{item.title}</div>
    </div>
  </div>
  <div className="level-right">
    <div className="level-item">
      {status_list[index]?
      (<Glyphicon glyph="glyphicon glyphicon-chevron-up" />) :
      (<Glyphicon glyph="glyphicon glyphicon-chevron-down" />)
      }
    </div>
  </div>
</div>
</div>
*/

// version 1
/*
return (
  <AccordionItem key={index} expanded={index == 0 ? true : false}>
    <AccordionItemTitle>
      <div className="u-position-relative">
        {item.title}
        <div className="accordion__arrow" role="presentation" />
      </div>
    </AccordionItemTitle>
    <AccordionItemBody ref="accordionBody" >{item.content}</AccordionItemBody>
  </AccordionItem>
);
*/
