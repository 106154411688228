import React from "react";
import { Vega } from "react-vega";
import { Decimal } from "decimal.js";

import MyIconArray from "./MyIconArray";
import MyRandomScatterPlot from "./MyRandomScatterPlot";
import MyTwoColumns from "./MyTwoColumns";



const squareSize = 40;

const symbolShape = "circle";

export default function MyRiskComparison(props) {

    // accross comparison
    let numberOfExtraLineForLegendLabel2 = props.numberOfExtraLineForLegendLabel2 != undefined? props.numberOfExtraLineForLegendLabel2: 0;
    let deviceType = props.deviceType != undefined? props.deviceType: "desktop";
    let chartBase = props.chartBase != undefined? props.chartBase: "1000";
    let chartType = props.chartType != undefined? props.chartType: "icon-array";
    let chartMode = props.chartMode != undefined? props.chartMode: "random";
    let chartWidth = props.width != undefined? props.width: 500;
    let chartHeight = props.height != undefined? props.height: 500;
    let sideLength = props.sideLength != undefined? props.sideLength: 50;
    let columnCount = props.columnCount != undefined? props.columnCount: 25;

    let data1 = props.data1;
    let data2 = props.data2;

    let chartData1 = undefined;
    let chartData2 = undefined;


    /*
    let chartData = props.data;
    let chartTitle = chartData.chartTitle;
    let legendTitle = chartData.legendTitle;
    */

    //let legendOffset = props.legnedOffset;

    // default value
    let legendOffsetLeft =  15 ; //10 + 15;
    let legendOffsetRight = 15 ; // 0 + 15;

    // now, adjust the legend offset based on the number of legend items there will be.
    let validLeftCategoryCount = 0;
    let validRightCategoryCount = 0;

    data1.table.forEach((record) => {
        if(record.amount > 0){
            validLeftCategoryCount++;
        }
    })

    data2.table.forEach((record) => {
        if(record.amount > 0){
            validRightCategoryCount++;
        }
    })

    console.log("legend validLeftCategoryCount: " + validLeftCategoryCount);
    console.log("legend validRightCategoryCount: " + validRightCategoryCount);

    console.log("legend category count difference: " + (validRightCategoryCount - validLeftCategoryCount));

    if( validLeftCategoryCount < validRightCategoryCount){
        legendOffsetLeft = legendOffsetLeft + (validRightCategoryCount - validLeftCategoryCount) * 20;
    }

    console.log(`numberOfExtraLineForLegendLabel2: ${numberOfExtraLineForLegendLabel2}`);
    if( numberOfExtraLineForLegendLabel2 > 0 ){
        // numberOfExtraLineForLegendLabel2
        legendOffsetLeft = legendOffsetLeft + numberOfExtraLineForLegendLabel2 * 14;
    }

    console.log("legendOffsetLeft: " + legendOffsetLeft);
    console.log("legendOffsetRight: " + legendOffsetRight);



    let componentChosen = undefined;

    if( chartBase == "100"){
        chartData1 = {...data1, table: data1.table.map((record) => {
            return {...record, amount: Decimal(record.amount).div(Decimal(10))};
        })};

        chartData2 = {...data2, table: data2.table.map((record) => {
            return {...record, amount: Decimal(record.amount).div(Decimal(10))};
        })};

    }
    else{
        chartData1 = data1;
        chartData2 = data2;
    }

    switch(chartType){

        case "random-scatter":
            //sideLength = Math.floor(sideLength * 0.3);
            //legendOffsetLeft = 20 + 15;
            //legendOffsetRight = 0 + 15;

            componentChosen = <MyTwoColumns 
            deviceType={deviceType}
            component1={
              <MyRandomScatterPlot data={chartData1} mode={chartMode}  sideLength={sideLength} legendOffset={legendOffsetLeft} width={chartWidth} height={chartHeight} deviceType={deviceType}></MyRandomScatterPlot>
            } 
            component2={
              <MyRandomScatterPlot data={chartData2} mode={chartMode}  sideLength={sideLength} legendOffset={legendOffsetRight} width={chartWidth} height={chartHeight} deviceType={deviceType}></MyRandomScatterPlot>
            }></MyTwoColumns>;
            break;
        default:
            // icon-array
            //legendOffsetLeft = 20 + 35;
            //legendOffsetRight = 0 + 35;

            componentChosen = <MyTwoColumns 
            deviceType={deviceType}
            component1={
                <MyIconArray data={chartData1} columnCount={columnCount} sideLength={sideLength} legendOffset={legendOffsetLeft} width={chartWidth} height={chartHeight} deviceType={deviceType}></MyIconArray>
            } 
            component2={
                <MyIconArray data={chartData2} columnCount={columnCount} sideLength={sideLength} legendOffset={legendOffsetRight} width={chartWidth} height={chartHeight} deviceType={deviceType}></MyIconArray>
            }></MyTwoColumns>
            break;

    }

    return componentChosen;


}
