import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import MyAccordion from "../components/MyAccordion";
import Banner from "../components/Banner";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";



class HowPage extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname), //localStorage.getItem("language")
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    let pageContent = "";
    
    let displayLanguage = language;

    if (
      displayLanguage != undefined &&
      Content.all_content[displayLanguage] != undefined &&
      Content.all_content[displayLanguage]["howPage"] != undefined
    ) {
      pageContent = Content.all_content[displayLanguage]["howPage"];
    } else {
      pageContent = Content.all_content["English"]["howPage"];
    }

    /*
    let webTitle = Content.all_content[displayLanguage]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */


    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={displayLanguage}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <Banner
          src={pageContent.banner_image_source}
          title={pageContent.banner_title}
          heightOption={displayLanguage == "English" ? "small" : "normal"}
          deviceType={deviceType}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">
              {pageContent.title}
            </div>
            <MyAccordion title={""} list={pageContent.sample_list} />
            <MyNavigator
              currentRoute={this.props.location.pathname}
              language={displayLanguage}
              Content={Content}
            />
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default HowPage;