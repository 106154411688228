import React, { Component, Fragment } from "react";

import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";

class FieldGroup extends Component {
  render() {
    const {
      id,
      label,
      leftSideLabel = "",
      rightSideLabel = "",
      displayHelp = false,
      help = "",
      ...props
    } = this.props;

    return (
      <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <div className="columns">
          {leftSideLabel.length > 0 && (
            <div className="column">
              <ControlLabel>{leftSideLabel}</ControlLabel>
            </div>
          )}

          <div className="column">
            <FormControl {...props} />
          </div>
          {rightSideLabel.length > 0 && (
            <div className="column">
              <ControlLabel>{rightSideLabel}</ControlLabel>
            </div>
          )}
        </div>
        {displayHelp && <div style={{ color: "red" }}>{help}</div>}
      </FormGroup>
    );
  }
}

export default FieldGroup;

// working version with react-bootstrap
/*
<FormGroup controlId={id}>
<ControlLabel>{label}</ControlLabel>
<div className="columns">
  {leftSideLabel.length > 0 && <div className="column">
    <ControlLabel>{leftSideLabel}</ControlLabel>
  </div>}
  
  <div className="column">
    <FormControl {...props} />
  </div>
  {rightSideLabel.length > 0 && <div className="column">
    <ControlLabel>{rightSideLabel}</ControlLabel>
  </div>}
</div>
{displayHelp && <div style={{color: "red"}}>{help}</div>}
</FormGroup>
*/
