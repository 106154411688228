import React, { Component, Fragment } from "react";

//import "../css/app.css";

const fullWidth = {
  width: "100%"
};

export default class MyTabsGeneral extends Component {
  constructor(props) {
    super(props);

    //var _accordionBody = null;
    const { list, initialIndex=0 } = this.props;

    console.log("initialIndex: " + initialIndex);

    this.state = {
      activeIndex: initialIndex
    };

    this.onTabClick = this.onTabClick.bind(this);

  }

  onTabClick(index) {
    const { onTabClick } = this.props;
    this.setState({
        activeIndex: index
    });
    onTabClick(index);
  }

  render() {
    const { list, showTabs, onTabClick} = this.props;
    const { activeIndex } = this.state;

    return (
      <Fragment>
        <div className={`tabs is-centered is-toggle ${showTabs? "": "is-hidden"}`}>
            <ul>
                {
                    list.map((item, index)=> {
                    return <li key={index} className={"has-text-weight-bold is-size-4 is-size-5-mobile " + (index==activeIndex? "is-active": "")} 
                    onClick={(event) => {
                        this.onTabClick(index);
                        /*
                        onTabClick(
                            event,
                            "HomePage",
                            "Tab Click",
                            item.title,
                            index,
                            true
                          );
                        */
                    }} ><a style={{ textDecoration: "none" }}>{item.title}</a></li>
                    })
                }
            </ul>
        </div>
        <div>
            {
                // is-offset-1
                list.filter((item, index) =>{return index == activeIndex;}).map((item, index) => {
                    return <Fragment key={index}>
                        {
                            item.content
                        }
                    </Fragment>
                })
            }
        </div>
      </Fragment>
    );
  }
}
