import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";

import FieldGroup from "../components/FieldGroup";
import RadioGroup from "../components/RadioGroup";
import SelectGroup from "../components/SelectGroup";
import CheckboxGroup from "../components/CheckboxGroup";
import ServerService from "../ServerService";
import Header from "../components/Header";
import Variable from "../Variable";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";
const imageStyle = {
  width: "90%",
  objectFit: "cover"
};

class FeedbackPage extends Component {
  constructor(props) {
    super(props);

    const { resultImgURL = "" } = this.props;

    let linkLanguage = MyUtility.getLanguageFromURL(this.props.location.pathname)

    this.state = {
      feedbackSubmitted: false,
      formGender: "",
      formSmokeStatus: "",
      formAge: "",
      formCalculatorResult: "",
      formInformationReceiver: [],
      formFindAllInformation: "",
      formHelpConversationDoctor: "",
      formHelpDecision: "",
      formInterestReason: "",
      formComment: "",
      language: linkLanguage //localStorage.getItem("language")
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  handleChange(event) {
    const target = event.target;

    var identifier = "";
    var value = "";

    var type = target.type;

    var name = target.name;

    var valueToBeRemoved = [];
    var valueToBeAdded = [];

    switch (target.type) {
      case "checkbox":
        identifier = target.name;
        value = target.checked;
        break;
      case "checkboxgroup":
        identifier = target.name;
        console.log("identifier: " + identifier);
        value = target.value;
        console.log("value: " + value);
        break;
      case "radio":
        identifier = target.name;
        value = target.value;
        break;
      default:
        identifier = target.id;
        value = target.value;
        break;
    }

    this.setState({
      [identifier]: value
    });

    console.log("handleChange - " + identifier + ": " + value);
  }

  handleSubmit(event) {
    //alert("handleSubmit: " + event.target.value);
    event.preventDefault();

    const {
      formGender,
      formSmokeStatus,
      formAge,
      formCalculatorResult,
      formInformationReceiver,
      formFindAllInformation,
      formHelpConversationDoctor,
      formHelpDecision,
      formInterestReason,
      formComment,
      language
    } = this.state;

    var cached_pack_id = Variable.getValue("pack_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_feedback_id = Variable.getValue("feedback_id");

    ServerService.submitFeedback(
      formGender,
      formSmokeStatus,
      formAge,
      formCalculatorResult,
      formInformationReceiver.toString(),
      formFindAllInformation,
      formHelpConversationDoctor,
      formHelpDecision,
      formInterestReason,
      formComment,
      cached_pack_id,
      cached_risk_id,
      cached_reflection_id,
      language
    )
      .then(response => {
        console.log(response);
        console.log("Submit Feedback: " + JSON.stringify(response));
        Variable.setValue("feedback_id", response.data.insert_id);
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      feedbackSubmitted: true
    });
  }
  render() {
    const { alt = "This is an image." } = this.props;

    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    const {
      formGender,
      formSmokeStatus,
      formAge,
      formCalculatorResult,
      formInformationReceiver,
      formFindAllInformation,
      formHelpConversationDoctor,
      formHelpDecision,
      formInterestReason,
      formComment,
      feedbackSubmitted
    } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined &&
      Content.all_content[language]["feedbackPage"] && 
    Content.all_content[language]["feedbackPage"] != undefined) {
      pageContent = Content.all_content[language]["feedbackPage"];
    } else {
      pageContent = Content.all_content["English"]["feedbackPage"];
    }


    if (feedbackSubmitted) {
      window.scrollTo(0, 0);
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
    <Fragment>
      <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content  has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>

        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              {pageContent.investigator_title}
            </div>
            {feedbackSubmitted ? (
              <h3>{pageContent.submitted_message}</h3>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <SelectGroup
                  id="formGender"
                  type="text"
                  selectLabel={pageContent.default_select_label}
                  onChange={this.handleChange}
                  value={formGender}
                  {...pageContent.feedback_questions.formGender}
                />
                <SelectGroup
                  id="formSmokeStatus"
                  type="text"
                  selectLabel={pageContent.default_select_label}
                  onChange={this.handleChange}
                  value={formSmokeStatus}
                  {...pageContent.feedback_questions.formSmokeStatus}
                />
                <FieldGroup
                  id="formAge"
                  type="text"
                  onChange={this.handleChange}
                  value={formAge}
                  {...pageContent.feedback_questions.formAge}
                />
                <SelectGroup
                  id="formCalculatorResult"
                  type="text"
                  selectLabel={pageContent.default_select_label}
                  onChange={this.handleChange}
                  value={formCalculatorResult}
                  {...pageContent.feedback_questions.formCalculatorResult}
                />
                <CheckboxGroup
                  id="formInformationReceiver"
                  type="text"
                  onChange={this.handleChange}
                  value={formInformationReceiver}
                  {...pageContent.feedback_questions.formInformationReceiver}
                />
                <RadioGroup
                  id="formFindAllInformation"
                  onChange={this.handleChange}
                  inline={true}
                  {...pageContent.feedback_questions.formFindAllInformation}
                />

                <RadioGroup
                  id="formHelpConversationDoctor"
                  onChange={this.handleChange}
                  inline={true}
                  {...pageContent.feedback_questions.formHelpConversationDoctor}
                />

                <RadioGroup
                  id="formHelpDecision"
                  {...pageContent.feedback_questions.formHelpDecision}
                  onChange={this.handleChange}
                  inline={true}
                />

                <FieldGroup
                  id="formInterestReason"
                  type="text"
                  onChange={this.handleChange}
                  value={formInterestReason}
                  {...pageContent.feedback_questions.formInterestReason}
                />

                <FieldGroup
                  id="formComment"
                  type="text"
                  onChange={this.handleChange}
                  value={formComment}
                  {...pageContent.feedback_questions.formComment}
                />
                <input className="button" type="submit" value={pageContent.submit_button_label}></input>
              </form>
            )}
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default FeedbackPage;

// <Button type="submit">{pageContent.submit_button_label}</Button>
                