import React, { Component, Fragment } from "react";
import FieldGroup from "../components/FieldGroup";

//import Button from "react-bootstrap/lib/Button";


import MyNavigator from "../components/MyNavigator";
import ServerService from "../ServerService";
import Variable from "../Variable";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";
import MyCalculation from "../utils/MyCalculation";


const buttonStyle = {
  width: 150
};

export default class PackPage extends Component {
  constructor(props) {
    super(props);

    let linkLanguage = MyUtility.getLanguageFromURL(this.props.location.pathname)

    this.state = {
      formYears: "",
      formAmount: "",
      calculationResult: -1,
      language: linkLanguage
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  handleChange(event) {
    //console.log("handleChange: id: " + event.target.id);

    /*
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    */

    this.setState({
      [event.target.id]: event.target.value
    });

    console.log(
      "handleChange - " + event.target.id + ": " + event.target.value
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const { language } = this.state;
    //alert("Pack submit");
    var currentsmokingduration = this.state.formYears;
    var cigsperday = this.state.formAmount;

    // new
    var packyears = MyCalculation.calculatePackYears(currentsmokingduration, cigsperday);

    // old
    //var packyears = currentsmokingduration * cigsperday / 20;

    console.log("Before insert, pack_id = " + Variable.getValue("pack_id"));

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");

    ServerService.submitPackCalculation(
      currentsmokingduration,
      cigsperday,
      packyears,
      cached_feedback_id,
      cached_reflection_id,
      cached_risk_id,
      language
    )
      .then(response => {
        console.log(response);

        console.log("Submit Pack: " + JSON.stringify(response.data.insert_id));
        Variable.setValue("pack_id", response.data.insert_id);

        console.log("After insert, pack_id = " + Variable.getValue("pack_id"));
      })
      .catch(error => {
        console.log(error);
      });
    // calculation_result_template: "You have x_number pack years.", // x_number will be replaced with calculation result, don't change it.
    this.setState({
      calculationResult: packyears
    });
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if ( Content.all_content != undefined && 
      Content.all_content[language] != undefined &&
      Content.all_content[language]["packPage"] != undefined) {
      pageContent = Content.all_content[language]["packPage"];
    } else {
      pageContent = Content.all_content["English"]["packPage"];
    }

    const { formYears, formAmount, calculationResult } = this.state;

    //const { bannerImgSource = "./image/smoke-298243_1920.jpg" } = this.props;

    var msg_template = pageContent.calculation_result_template;

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */
   
    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>

        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <p>{pageContent.instruction.normal}</p>
            <form onSubmit={this.handleSubmit}>
              <FieldGroup
                id="formYears"
                type="text"
                onChange={this.handleChange}
                {...pageContent.pack_questions.formYears}
              />
              <FieldGroup
                id="formAmount"
                type="text"
                onChange={this.handleChange}
                {...pageContent.pack_questions.formAmount}
              />
              
              <input className="button" type="submit" value={pageContent.submit_button_label}></input>
            </form>
            {calculationResult >= 0 && <h2>
              {msg_template.replace("x_number", calculationResult)}
              </h2>}
            <MyNavigator
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
          />


          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

// <Button type="submit">{pageContent.submit_button_label}</Button>
