import React, { Component } from 'react';
import { ControlLabel, FormGroup, HelpBlock, Checkbox } from 'react-bootstrap';

const spaceStyle={
    marginRight: 15
};

class CheckboxGroup extends Component {
  constructor(props){
      super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
        checkedOptions: []
    };
  } 
  
  componentDidMount(){
    console.log("CheckboxGroup.componentDidMount");



  }

  onChange(event){
    

    const {checkedOptions} = this.state;

    // still the same
    console.log("event.target.name: " + event.target.name);

    // the 0/1 for that checkbox
    //console.log("event.target.checked: " + event.target.checked);

    // the value
    //console.log("event.target.value: " + event.target.value);
    var resultCheckOptions = [];

    if( event.target.checked ){
        // something to add
        resultCheckOptions = [...checkedOptions, event.target.value];

        this.setState({
            checkedOptions: resultCheckOptions
        });
    }
    else{
        // something to be removed
        var index = checkedOptions.indexOf(event.target.value);

        resultCheckOptions = checkedOptions.filter((item, index) => {
                return item != event.target.value;
        });

        this.setState({
            checkedOptions: resultCheckOptions
        });
    }

    // target.type = checkboxgroup
    
    const {onChange} = this.props;

    const {target, ...eventOthers} = event;

    const {value, type, name, ...targetOthers} = target;

    onChange({target:{value: resultCheckOptions, type: "checkboxgroup", name: event.target.name, ...targetOthers}, ...eventOthers});
  }


  render() {

    const { id, label, options, inline=false, help, onChange, ...props} = this.props;

    const {checkedOptions} = this.state;

    console.log("checkedOptions: " + JSON.stringify(checkedOptions));

    return (
        <div>
        <ControlLabel>{label}</ControlLabel>
        <FormGroup  controlId={id} {...props}>
            {
                options.map((item, index) => {
                    return <Checkbox 
                            name={id} 
                            key={index} 
                            inline={inline} 
                            value={item.value} 
                            onChange={this.onChange}
                            checked={checkedOptions.indexOf(item.value) >= 0}
                            style={spaceStyle}
                            >
                            {item.label}</Checkbox>
                })
            }
            {help && <HelpBlock>{help}</HelpBlock>}

        </FormGroup>
        </div>
    );
  }
}

export default CheckboxGroup;
