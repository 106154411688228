import React, { Component, Fragment } from "react";

//import "../css/app.css";

const fullWidth = {
  width: "100%"
};

export default class MyTabs extends Component {
  constructor(props) {
    super(props);

    //var _accordionBody = null;
    const { list } = this.props;

    this.state = {
      activeIndex: 0,
    };

    this.onTabClick = this.onTabClick.bind(this);

  }

  onTabClick(index) {
    this.setState({
        activeIndex: index
    });
  }

  render() {
    const { list, onTabClick} = this.props;
    const { activeIndex } = this.state;

    return (
      <Fragment>
        <div className="tabs is-centered is-toggle">
            <ul>
                {
                    list.map((item, index)=> {
                    return <li className={"has-text-weight-bold is-size-4 is-size-5-mobile " + (index==activeIndex? "is-active": "")} 
                    onClick={(event) => {
                        this.onTabClick(index);
                        
                        onTabClick(
                            event,
                            "HomePage",
                            "Tab Click",
                            item.title,
                            index,
                            true
                          );
                        
                    }} ><a style={{ textDecoration: "none" }}>{item.title}</a></li>
                    })
                }
            </ul>
        </div>
        <div>
            {
                // is-offset-1
                list.filter((item, index) =>{return index == activeIndex;}).map((item, index) => {
                    return <Fragment key={index}>
                        {
                            item.items.map((itemS, indexS) => {
                                return (
                                    <Fragment key={indexS}>
                                      <br />
                                      <div className="columns is-desktop is-mobile is-centered">
                                        <div className="column box ">
                                          <div className="has-text-weight-bold is-size-5 is-size-6-mobile">
                                            {itemS.title}
                                          </div>
                                          <div>{itemS.content}</div>
                                        </div>
                                        <br />
                                      </div>
                                    </Fragment>
                                  );
                            })
                        }
                    </Fragment>
                })
            }
        </div>
      </Fragment>
    );
  }
}


// each item
/*
<div className="hero is-desktop is-mobile" style={{margin: 10, padding: 5}}>
<div className="level">
  <div className="level-left">
    <div className="level-item is-size-5 is-size-6-mobile">
      <div className="has-text-left" style={{textOverflow: "ellipsis"}}>{item.title}</div>
    </div>
  </div>
  <div className="level-right">
    <div className="level-item">
      {status_list[index]?
      (<Glyphicon glyph="glyphicon glyphicon-chevron-up" />) :
      (<Glyphicon glyph="glyphicon glyphicon-chevron-down" />)
      }
    </div>
  </div>
</div>
</div>
*/

// version 1
/*
return (
  <AccordionItem key={index} expanded={index == 0 ? true : false}>
    <AccordionItemTitle>
      <div className="u-position-relative">
        {item.title}
        <div className="accordion__arrow" role="presentation" />
      </div>
    </AccordionItemTitle>
    <AccordionItemBody ref="accordionBody" >{item.content}</AccordionItemBody>
  </AccordionItem>
);
*/
