import React from "react";
import { Helmet } from "react-helmet";

export default function MyPageHead(props) {
  return (
    <Helmet>
      <meta charSet="utf-8"></meta>
      <title>{props.headInfo.title}</title>
      {
          // recommended length: 50 ~ 160 characters
          props.headInfo.description != undefined? <meta name="description" content={props.headInfo.description}></meta>: null
      }
      {
          // wait.. Google won't use it.
          props.headInfo.keywords != undefined? <meta name="keywords" content={props.headInfo.keywords.join(", ")}></meta>: null
      }
    </Helmet>
  );
}
