import React, { Component } from 'react';


const imageStyle = {
    width: "100%",
    objectFit: "cover",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  };


class EmbeddedImage extends Component {
  render() {
    const {
        src,
        alt="This is an image"
    } = this.props;
    return (
        <img src={src} style={imageStyle} alt={alt}/>
    );
  }
}

export default EmbeddedImage;
