import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";

class CausePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = ""; //Content.all_content[language]["causePage"];

    if (Content.all_content != undefined &&
      Content.all_content[language]["causePage"] && 
    Content.all_content[language]["causePage"] != undefined) {
      pageContent = Content.all_content[language]["causePage"];
    } else {
      pageContent = Content.all_content["English"]["causePage"];
    }

    //var chartTitle = "Estimated proportions of lung cancer causes*";
    var chartCaption =
      "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49.";
    var chartSeries = [
      {
        field: "percentage",
        name: "Percentage"
      }
    ];

    var xAccessor = d => {
      return d.label;
    };

    var xScale = "ordinal";
    var xLabel = "Category";
    var yLabel = "Percentage";
    var yTicks = [25, "%"];

    var chartData = [
      {
        percentage: 2,
        label: "Outside air pollution",
        on_chart_label: "2%",
        color: "#4196E6"
      },
      {
        percentage: 10,
        label: "Occupational exposure",
        on_chart_label: "10%",
        color: "#3EA23D"
      },
      {
        percentage: 15,
        label: "Radon",
        on_chart_label: "9-15%",
        color: "#D8B836"
      },
      {
        percentage: 90,
        label: "Smoking",
        on_chart_label: "90%",
        color: "#E57E29"
      }
    ];

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];


    // <BillBoardBarChart />
    // <EmbeddedImage src={pageContent.cause_chart.src} />
    // <DocumentTitle title={pageContent.web_title}>
    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>

        <Banner
          src={pageContent.banner_image_source}
          title={pageContent.banner_title}
          heightOption = {language == "English"? "small" : "normal"}
          deviceType={deviceType}
        />

        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.cause_info.title}</div>
            {pageContent.cause_info.causes.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              Estimated proportions of lung cancer causes*
            </div>
            <img src={pageContent.cause_chart.src}/>
            <div className="is-size-6">{pageContent.cause_chart.text}</div>
            <br />
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              {pageContent.risk_info.title}
            </div>
            
            <ul>
              {pageContent.risk_info.risks.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <MyNavigator
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
            />
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default CausePage;
