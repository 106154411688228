import React, { Component } from 'react';

//import "../css/print.min.css";

//import "../js/print.min.js";

export default class PrintButton extends Component {
  render() {
    const {
        documentElementID="",
        documentTitle="",
        buttonLabel=""
    } = this.props;

    let cssList = [
      //"/css/grid.min.css",
      "/css/fontawesome_all.min.css",
      "/css/bulma.min.css",
      //"/css/my-react-accessible-accordion.css",
      "/css/app.css"
    ];

    return (
        <button className="button is-mobile is-centered has-text-centered" onClick={(event) => { window.printJS({ printable: documentElementID, type: 'html', documentTitle: documentTitle, css: cssList }); }}>{buttonLabel}</button>
    );
  }
}