import React, { Component, Fragment } from "react";
import { Stage, Layer, Rect, Line, Text } from "react-konva";
import {
  isIE
} from "react-device-detect";
import FieldGroup from "../components/FieldGroup";
import SelectGroup from "../components/SelectGroup";
import RadioGroup from "../components/RadioGroup";
import ServerService from "../ServerService";
import Variable from "../Variable";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyTabsGeneral from "../components/MyTabsGeneral";
import MyRiskComparison from "../charts/MyRiskComparison";
import { Decimal } from "decimal.js";
import { Redirect } from "react-router";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";
import MyDropdown from "../components/MyDropdown";
// import Button from "react-bootstrap/lib/Button";
import PrintButton from "../components/PrintButton";

import "../css/app.css";
import MyCalculation from "../utils/MyCalculation";

const imageStyle = {
  width: "90%",
  objectFit: "cover",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
};

const dividerStyle = {
  height: 50,
  opacity: 0,
};

let chartList = [{}, {}, {}, {}];

// palette 1: blue, green, light blue
//const colorList = ["#1F77B4", "#31A354", "#AEC7E8"];

// opalette 2: Vermillion (deaths) / Blue (fewer deaths)  / Grey
//const colorList = ["#d55e00", "#00b2fc", "#8898ab"];

// lighter grey
const colorList = ["#d55e00", "#00b2fc", "#dddddd"];

export default class ResultPage extends Component {
  constructor(props) {
    super(props);

    const { resultImgURL = "" } = this.props;

    let linkLanguage = MyUtility.getLanguageFromURL(
      this.props.location.pathname
    );

    //localStorage.setItem("language", linkLanguage);

    this.state = {
      sex: "",
      risk: -1,
      eligibility: "no",
      insurance: "unknown",
      old: -1,
      packYears: -1,
      //imageLoaded: false,
      reflectionSubmitted: false,
      formPersonalBenefit: "",
      formRecommended: "",
      formRisk: "",
      formReason: "",
      formRiskConfirmation: "",
      formBenefitConfirmation: "",
      chartIndex:
        Math.floor(chartList.length * Math.random()) % chartList.length,
      // beta: show-all
      // production: show-one
      chartMode: "show-all",
      abTestIndex: 1, // always align right //Math.random() > 0.5 ? 1 : 0,
      stageWidth: 0,
      stageHeight: 0,
      scaleElementWidth: 0,
      language: linkLanguage, //localStorage.getItem("language")
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRiskConfirmationSubmit = this.handleRiskConfirmationSubmit.bind(
      this
    );
    this.handleBenefitConfirmationSubmit = this.handleBenefitConfirmationSubmit.bind(
      this
    );
    this.onChartTabClick = this.onChartTabClick.bind(this);
    //this.onImageLoad = this.onImageLoad.bind(this);

    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    const { chartIndex } = this.state;
    gAnalytics();

    // now getting the size of the stage
    window.addEventListener("resize", this.onWindowResize);

    const sWidth = this.scaleElement.clientWidth - 50; // * 0.90;
    const sHeight = 120; // 120; //this.scaleElement.clientHeight * 0.8; //120 ; //200; //this.scaleElement.clientHeight * 0.50;

    console.log("componentDidMount.sWidth: " + sWidth);
    console.log("componentDidMount.sHeight: " + sHeight);

    this.setState({
      stageHeight: sHeight,
      stageWidth: sWidth,
      scaleElementWidth: this.scaleElement.clientWidth,
    });

    // see if I can extrat necessasry variables here
    // I can!
    if (
      this.props.match.params.display == undefined ||
      this.props.match.params.display.length <= 5
    ) {
      return;
    }
    // V2
    let resultName = ""; //
    var extensionIndex = this.props.match.params.display.lastIndexOf(".png");
    if (extensionIndex >= 0) {
      resultName = this.props.match.params.display.substring(0, extensionIndex);
    } else {
      resultName = this.props.match.params.display;
    }

    // now, try to extract the [sex]_[risk]_[eligibility]_[insurance]
    // never_smoked
    var pSex = "";
    var pRisk = 0.0;
    var pEligibility = "";
    var pInsurance = "";
    let pOld = -1;
    let pPackYears = -1;

    if (resultName == "never_smoked") {
      pRisk = 0.0;
    } else {
      let pList = resultName.split("_");
      pSex = pList[0];
      pRisk = Number(pList[1]);
      pEligibility = pList[2];
      if (pList.length >= 4 && pList[3].length > 0) {
        pInsurance = pList[3];
      } else {
        pInsurance = "unknown";
      }

      if (pList.length >= 5) {
        pOld = Number(pList[4]);
      } else {
        pOld = -1;
      }

      if (pList.length >= 6) {
        pPackYears = Number(pList[5]);
      } else {
        pPackYears = -1;
      }
    }

    // avoid certain chart if risk is too big
    // chartIndex: Math.floor(chartList.length * Math.random())%chartList.length,
    let newChartindex = chartIndex;
    if (pRisk > 25.0) {
      newChartindex =
        Math.floor((chartList.length - 1) * Math.random()) %
        (chartList.length - 1);
    }

    this.setState({
      chartIndex,
      newChartindex,
      sex: pSex,
      risk: pRisk,
      eligibility: pEligibility,
      insurance: pInsurance,
      old: pOld,
      packYears: pPackYears,
    });
  }

  onWindowResize() {
    const sWidth = this.scaleElement.clientWidth - 50; // * 0.90;
    const sHeight = 120; //this.scaleElement.clientHeight * 0.50;

    this.setState({
      stageHeight: sHeight,
      stageWidth: sWidth,
      scaleElementWidth: this.scaleElement.clientWidth,
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceiveProps.location.pathname: " +
        nextProps.location.pathname
    );
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language,
    });
    const { gAnalytics } = this.props;
    if (
      this.props.location.pathname != nextProps.location.pathname ||
      this.state.language != language
    ) {
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item,
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  handleSubmit(event) {
    //alert("handleSubmit: " + event.target.value);
    event.preventDefault();

    const {
      formPersonalBenefit,
      formRecommended,
      formRisk,
      formReason,
      language,
    } = this.state;

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_pack_id = Variable.getValue("pack_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_risk_confirmation_id = Variable.getValue("risk_confirmation_id");

    ServerService.submitReflection(
      formPersonalBenefit,
      formRecommended,
      formRisk,
      formReason,
      cached_feedback_id,
      cached_pack_id,
      cached_risk_id,
      cached_risk_confirmation_id,
      language
    )
      .then((response) => {
        console.log(response);
        console.log("Submit Reflection: " + JSON.stringify(response));
        Variable.setValue("reflection_id", response.data.insert_id);
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      reflectionSubmitted: true,
    });

    //alert("Reflection submit!");
  }

  /*
  onImageLoad() {
    console.log("onImageLoad");
    //alert("onImageLoad");

    this.setState({ imageLoaded: true });
  }
  */

  /*
  getEligibilityStatement(sex, risk, eligibility, insurance) {
    var result = "Default Eligibility";

    switch (eligibility) {
      case "yes":
        result = "";

        if (insurance == "yes") {
          // M
          result =
            "Given your age and smoking history, you are <strong>eligible</strong> for screening accoding to the US Preventive Services Task Force criteria.";
        } else {
          // N
        }

        result += "";
        break;
      case "no":
        result = "";
        break;
      default:
        result = "";
        break;
    }

    return result;
  }
  */

  handleChange(event) {
    console.log("handleChange: " + event.target);

    const target = event.target;

    var identifier = "";
    var value = "";

    var type = target.type;

    var name = target.name;

    switch (target.type) {
      case "checkbox":
        identifier = target.name;
        value = target.checked;
        break;
      case "radio":
        identifier = target.name;
        value = target.value;
        break;
      default:
        identifier = target.id;
        value = target.value;
        break;
    }

    this.setState({
      [identifier]: value,
    });

    if (identifier == "formRiskConfirmation") {
      this.handleRiskConfirmationSubmit(value);
    }

    if (identifier == "formBenefitConfirmation") {
      this.handleBenefitConfirmationSubmit(value);
    }

    console.log("handleChange: name and value: " + identifier + ": " + value);
  }

  handleRiskConfirmationSubmit(confirmationValue) {
    console.log("handleRiskConfirmationSubmit: " + confirmationValue);
    //event.preventDefault();

    const {
      sex,
      risk,
      eligibility,
      insurance,
      old,
      packYears,
      //formRiskConfirmation,
      abTestIndex,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      errorMsg,
      language,
    } = this.state;

    let resultName = ""; //
    var extensionIndex = this.props.match.params.display.lastIndexOf(".png");
    if (extensionIndex >= 0) {
      resultName = this.props.match.params.display.substring(0, extensionIndex);
    } else {
      resultName = this.props.match.params.display;
    }

    // now, try to extract the [sex]_[risk]_[eligibility]_[insurance]
    // never_smoked
    /*
    var pSex = "";
    var pRisk = 0.0;
    var pEligibility = "";
    var pInsurance = "";

    if (resultName == "never_smoked") {
      pRisk = 0.0;
    } else {
      let pList = resultName.split("_");
      pSex = pList[0];
      pRisk = Number(pList[1]);
      pEligibility = pList[2];
      if (pList.length >= 4) {
        pInsurance = pList[3];
      } else {
        pInsurance = "unknown";
      }
    }
    */

    let correctRiskCategory = "";
    if (risk < 1) {
      correctRiskCategory = "Low";
    } else if (risk >= 1 && risk <= 2) {
      correctRiskCategory = "Intermediate";
    } else if (risk > 2) {
      correctRiskCategory = "High";
    }

    let confirmationEvaluation =
      confirmationValue == correctRiskCategory ? 1 : 0;

    var cached_risk_id = Variable.getValue("risk_id");
    var cached_risk_confirmation_id = Variable.getValue("risk_confirmation_id");
    var cached_pack_id = Variable.getValue("pack_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_feedback_id = Variable.getValue("feedback_id");

    ServerService.submitRiskConfirmation(
      abTestIndex == 0 ? "align-left" : "align-right",
      risk,
      confirmationValue,
      confirmationEvaluation,
      sex,
      insurance,
      eligibility,
      cached_risk_id,
      cached_risk_confirmation_id,
      cached_feedback_id,
      cached_pack_id,
      cached_reflection_id,
      language
    )
      .then((response) => {
        console.log(response);
        console.log("Submit Risk Confirmation: " + JSON.stringify(response));
        Variable.setValue("risk_confirmation_id", response.data.insert_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleBenefitConfirmationSubmit(confirmationValue) {
    console.log("handleBenefitConfirmationSubmit: " + confirmationValue);
    //event.preventDefault();

    const {
      sex,
      risk,
      eligibility,
      insurance,
      old,
      packYears,
      formBenefitConfirmation,
      abTestIndex,
      chartIndex,
      formCurrentStatus,
      formAgeLastSmoke,
      formYearsSmoking,
      formCigarettesPerDay,
      formGender,
      formInsurance,
      formHighestGradeCompleted,
      formRaceEthnicity,
      formTallFeet,
      formTallInch,
      formWeight,
      formDoctorToldCancer,
      formFamilyHistory,
      formDoctorToldCOPD,
      errorMsg,
      language,
    } = this.state;

    /*
    let resultName = ""; //
    var extensionIndex = this.props.match.params.display.lastIndexOf(".png");
    if (extensionIndex >= 0) {
      resultName = this.props.match.params.display.substring(0, extensionIndex);
    } else {
      resultName = this.props.match.params.display;
    }

    // now, try to extract the [sex]_[risk]_[eligibility]_[insurance]
    // never_smoked
    var pSex = "";
    var pRisk = 0.0;
    var pEligibility = "";
    var pInsurance = "";

    if (resultName == "never_smoked") {
      pRisk = 0.0;
    } else {
      let pList = resultName.split("_");
      pSex = pList[0];
      pRisk = Number(pList[1]);
      pEligibility = pList[2];
      if (pList.length >= 4) {
        pInsurance = pList[3];
      } else {
        pInsurance = "unknown";
      }
    }
    */

    // I need to know what type of chart it is.
    // chartIndex
    let chartObjDescription = chartList[chartIndex].description;

    let chartDescriptionString = `${chartObjDescription.type}-${chartObjDescription.base}-${chartObjDescription.mode}`;

    var cached_risk_id = Variable.getValue("risk_id");
    var cached_risk_confirmation_id = Variable.getValue("risk_confirmation_id");
    var cached_benefit_confirmation_id = Variable.getValue(
      "benefit_confirmation_id"
    );
    var cached_pack_id = Variable.getValue("pack_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_feedback_id = Variable.getValue("feedback_id");

    console.log(
      "Before submitBenefitConfirmation - formBenefitConfirmation: " +
        formBenefitConfirmation
    );
    ServerService.submitBenefitConfirmation(
      chartIndex,
      chartDescriptionString,
      chartObjDescription.type,
      chartObjDescription.base,
      chartObjDescription.mode,
      risk,
      confirmationValue,
      sex,
      insurance,
      eligibility,
      cached_risk_id,
      cached_risk_confirmation_id,
      cached_benefit_confirmation_id,
      cached_feedback_id,
      cached_pack_id,
      cached_reflection_id,
      language
    )
      .then((response) => {
        console.log(response);
        console.log("Submit Benefit Confirmation: " + JSON.stringify(response));
        console.log("insert_id: " + response.data.insert_id);
        Variable.setValue("benefit_confirmation_id", response.data.insert_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChartTabClick(index) {
    console.log("onChartTabClick: " + index);

    this.setState({
      chartIndex: index,
      formBenefitConfirmation: "", // clear selection when switching tab
    });
  }

  render() {
    const {
      Content,
      deviceType,
      savedContent,
      mainContentStyle,
      changeLanguage,
      ...others
    } = this.props;

    const {
      language,
      imageLoaded,
      stageWidth,
      stageHeight,
      scaleElementWidth,
      formRiskConfirmation,
      formBenefitConfirmation,
      abTestIndex,
      chartIndex,
      chartMode,
      sex,
      risk,
      eligibility,
      insurance,
      old,
      packYears,
    } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (
      Content.all_content[language] != undefined &&
      Content.all_content[language]["resultPage"] != undefined
    ) {
      pageContent = Content.all_content[language]["resultPage"];
    } else {
      pageContent = Content.all_content["English"]["resultPage"];
    }

    console.log(JSON.stringify(pageContent));

    const { alt = "This is an image." } = this.props;

    const {
      reflectionSubmitted,
      formPersonalBenefit,
      formRecommended,
      formRisk,
      formReason,
    } = this.state;

    console.log("match:" + JSON.stringify(this.props.match));

    if (
      this.props.match.params.display == undefined ||
      this.props.match.params.display.length <= 5
    ) {
      return <Redirect to={"/notfound"} />;
    }
    //alert("info: " + this.props.match.params.display);
    //https://lisa-lau-epa2.squarespace.com/assets/Male_18.6_no.png

    // V1
    //var extensionIndex = this.props.match.params.display.lastIndexOf(".png");
    //let imgName = this.props.match.params.display.substring(0, extensionIndex);

    // disable as all the variable are technically in state
    // V2
    /*
    let resultName = ""; //
    var extensionIndex = this.props.match.params.display.lastIndexOf(".png");
    if (extensionIndex >= 0) {
      resultName = this.props.match.params.display.substring(0, extensionIndex);
    } else {
      resultName = this.props.match.params.display;
    }

    // now, try to extract the [sex]_[risk]_[eligibility]_[insurance]
    // never_smoked
    var pSex = "";
    var pRisk = 0.0;
    var pEligibility = "";
    var pInsurance = "";
    let pOld = -1;
    let pPackYears = -1;

    if (resultName == "never_smoked") {
      pRisk = 0.0;
    } else {
      let pList = resultName.split("_");
      pSex = pList[0];
      pRisk = Number(pList[1]);
      pEligibility = pList[2];
      if (pList.length >= 4) {
        pInsurance = pList[3];
      } else {
        pInsurance = "unknown";
      }

      if (pList.length >= 5) {
        pOld = Number(pList[4]);
      } else {
        pOld = -1;
      }

      if (pList.length >= 6) {
        pPackYears = Number(pList[5]);
      } else {
        pPackYears = -1;
      }
    }
    */

    let webTitle = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    let neverSmoked = this.props.match.params.display == "never_smoked";

    // for heatmap
    /*
    const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);
    const yLabels = ["Sun", "Mon", "Tue"];
    const data = new Array(yLabels.length)
      .fill(0)
      .map(() =>
        new Array(xLabels.length)
          .fill(0)
          .map(() => Math.floor(Math.random() * 100))
      );
    */

    console.log("stageWidth: " + stageWidth);
    console.log("stageHeight: " + stageHeight);

    let spectrum_start_x = 10; //0;
    let spectrum_end_x = stageWidth - 10;

    let risk_range = risk < 30 ? 30 : 100;

    let risk_pointer =
      (risk * (spectrum_end_x - spectrum_start_x)) / risk_range; // risk * stageWidth / risk_range;

    var risk_label_x = 0;

    var statement_length = 240;

    let marker_label_font_size = 18;

    if (deviceType == "desktop" || deviceType == "tablet") {
      if (risk_pointer + statement_length >= stageWidth) {
        risk_label_x = risk_pointer - statement_length;
      } else if (risk_pointer - statement_length <= 0) {
        risk_label_x = risk_pointer + 10;
      } else {
        risk_label_x = risk_pointer + 10;
      }
    } else if (deviceType == "mobile") {
      risk_label_x = spectrum_start_x;
      marker_label_font_size = 14;
    }

    let low_risk_pointer = (stageWidth / risk_range) * 10;
    let high_risk_pointer = (stageWidth / risk_range) * 20;
    let scale_baseline_y = 30;

    // now the visualization part
    /*
    let newChartIndex = chartIndex;
    if( risk > 25.0){
      // not choosing option D, too messsy
      if()
    }
    */

    //risk

    console.log("Number of deaths: " + Math.floor((1000 / 100) * risk));

    let numberDieBeforeScreen = Math.floor((1000 / 100) * risk);
    let numberLiveBeforeScreen = 1000 - numberDieBeforeScreen;

    let numberSavedAfterScreen = MyCalculation.calculateSavedByScreeningFromRisk(
      risk
    );
    let numberDieAfterScreen =
      Math.floor((1000 / 100) * risk) - numberSavedAfterScreen; //numberLiveBeforeScreen;
    let numberLiveAfterScreen =
      1000 - numberDieAfterScreen - numberSavedAfterScreen;

    let scaleDownFactor = 0.9;
    // thousand square
    let thousandColumnCount = 25;
    //deviceType != "mobile"? Math.floor(scaleDownFactor * scaleElementWidth/2/thousandSideLength): Math.ceil(scaleElementWidth/thousandSideLength);

    switch (deviceType) {
      case "mobile":
        thousandColumnCount = 20;
        break;
      case "table":
        thousandColumnCount = 20;
        break;
      default:
        // deakstop
        thousandColumnCount = 25;
        break;
    }

    // method 2: responsive
    let thousandSideLength =
      deviceType != "mobile"
        ? Math.floor(
            (scaleDownFactor * scaleElementWidth) / 2 / thousandColumnCount
          )
        : Math.ceil(scaleElementWidth / thousandColumnCount);

    // method 1: fixed
    //let thousandSideLength = 16;

    console.log(
      `deviceType: ${deviceType}, scaleElementWidth: ${scaleElementWidth}, columnCount: ${thousandColumnCount}`
    );

    let thousandChartWidth = thousandSideLength * thousandColumnCount;
    let thousandChartHeight =
      200 + Math.ceil(1000 / thousandColumnCount) * thousandSideLength;

    // hundred square
    let hundredSideLength =
      deviceType != "mobile"
        ? Math.floor((scaleDownFactor * scaleElementWidth) / 2 / 10)
        : Math.ceil(scaleElementWidth / 10);
    let hundredWidth =
      deviceType != "mobile"
        ? (scaleDownFactor * scaleElementWidth) / 2
        : scaleElementWidth;
    let hundredHeight = 200 + hundredWidth;

    let numberOfExtraLineForLegendLabel = thousandChartWidth < 300 ? 1 : 0;

    console.log(
      `numberOfExtraLineForLegendLabel: ${numberOfExtraLineForLegendLabel}`
    );

    let chart_info_list = pageContent.comparison_chart_info_list;

    let chartFirstInfo = chart_info_list[0];
    let chartSecondInfo = chart_info_list[1];

    const chartDataLeft = {
      chartTitle: chartFirstInfo.chartTitle,
      legendTitle: chartFirstInfo.legendTitle["1000"],
      table: [
        {
          id: 1,
          category: `${numberDieBeforeScreen} ${chartFirstInfo.legendMap["die"]}`,
          color: colorList[0],
          fillOpacity: 1.0,
          amount: numberDieBeforeScreen,
        },
        //{ id: 2, category: '5 fewer lung cancer deaths due to screening', color: colorList[1], amount: 5 },
        {
          id: 0,
          category: `${numberLiveBeforeScreen} ${chartFirstInfo.legendMap["not die"]}`,
          color: colorList[2],
          fillOpacity: 1.0,
          amount: numberLiveBeforeScreen,
        },
      ],
    };

    const chartDataRight = {
      chartTitle: chartSecondInfo.chartTitle,
      legendTitle: chartSecondInfo.legendTitle["1000"],
      table: [
        {
          id: 1,
          category: `${numberDieAfterScreen} ${chartSecondInfo.legendMap["die"]}`,
          color: colorList[0],
          fillOpacity: 1.0,
          amount: numberDieAfterScreen,
        },
        {
          id: 2,
          // fewer died from lung cancer due to screening
          category:
            numberOfExtraLineForLegendLabel > 0
              ? [
                  `${numberSavedAfterScreen} ${chartSecondInfo.legendMap["fewer die"]["list"][0]}`,
                  `${chartSecondInfo.legendMap["fewer die"]["list"][1]}`,
                ]
              : `${numberSavedAfterScreen} ${chartSecondInfo.legendMap["fewer die"]["full"]}`,
          color: colorList[1],
          fillOpacity: 1.0,
          amount: numberSavedAfterScreen,
        },
        {
          id: 0,
          category: `${numberLiveAfterScreen} ${chartSecondInfo.legendMap["not die"]}`,
          color: colorList[2],
          fillOpacity: 1.0,
          amount: numberLiveAfterScreen,
        },
      ],
    };

    const chartDataLeftHundred = {
      chartTitle: chartFirstInfo.chartTitle,
      legendTitle: chartFirstInfo.legendTitle["100"],
      table: [
        {
          id: 1,
          category: `${Decimal(numberDieBeforeScreen).div(Decimal(10))} ${
            chartFirstInfo.legendMap["die"]
          }`,
          color: colorList[0],
          fillOpacity: 1.0,
          amount: numberDieBeforeScreen,
        },
        //{ id: 2, category: '5 fewer lung cancer deaths due to screening', color: colorList[1], amount: 5 },
        {
          id: 0,
          category: `${Decimal(numberLiveBeforeScreen).div(Decimal(10))} ${
            chartFirstInfo.legendMap["not die"]
          }`,
          color: colorList[2],
          fillOpacity: 1.0,
          amount: numberLiveBeforeScreen,
        },
      ],
    };

    const chartDataRightHundred = {
      chartTitle: chartSecondInfo.chartTitle,
      legendTitle: chartSecondInfo.legendTitle["100"],
      table: [
        {
          id: 1,
          category: `${Decimal(numberDieAfterScreen).div(Decimal(10))} ${
            chartSecondInfo.legendMap["die"]
          }`,
          color: colorList[0],
          fillOpacity: 1.0,
          amount: numberDieAfterScreen,
        },
        {
          id: 2,
          // fewer died from lung cancer due to screening
          category:
            numberOfExtraLineForLegendLabel > 0
              ? [
                  `${Decimal(numberSavedAfterScreen).div(Decimal(10))} ${
                    chartSecondInfo.legendMap["fewer die"]["list"][0]
                  }`,
                  `${chartSecondInfo.legendMap["fewer die"]["list"][1]}`,
                ]
              : `${Decimal(numberSavedAfterScreen).div(Decimal(10))} ${
                  chartSecondInfo.legendMap["fewer die"]["full"]
                }`,
          color: colorList[1],
          fillOpacity: 1.0,
          amount: numberSavedAfterScreen,
        },
        {
          id: 0,
          category: `${Decimal(numberLiveAfterScreen).div(Decimal(10))} ${
            chartSecondInfo.legendMap["not die"]
          }`,
          color: colorList[2],
          fillOpacity: 1.0,
          amount: numberLiveAfterScreen,
        },
      ],
    };

    chartList = [
      {
        title: "A",
        description: {
          type: "icon-array",
          base: "1000",
          mode: "",
        },
        content: (
          <MyRiskComparison
            deviceType={deviceType}
            chartType={"icon-array"}
            chartBase={"1000"}
            data1={chartDataLeft}
            data2={chartDataRight}
            columnCount={thousandColumnCount} // 25
            sideLength={thousandSideLength} // 20
            width={thousandChartWidth} //400
            height={thousandChartHeight}
            numberOfExtraLineForLegendLabel2={numberOfExtraLineForLegendLabel}
          />
        ),
      },
      {
        title: "B",
        description: {
          type: "icon-array",
          base: "100",
          mode: "",
        },
        content: (
          <MyRiskComparison
            deviceType={deviceType}
            chartType={"icon-array"}
            chartBase={"100"}
            data1={chartDataLeftHundred}
            data2={chartDataRightHundred}
            columnCount={10}
            sideLength={hundredSideLength} // 40
            width={hundredWidth}
            height={hundredHeight}
            numberOfExtraLineForLegendLabel2={numberOfExtraLineForLegendLabel}
          />
        ),
      },
      /*
      {
        title: "Viz C",
        content: <MyRiskComparison
        chartBase={"1000"}
        chartType={"random-scatter"}
        chartMode={"random"}
        data1={chartDataLeft}
        data2={chartDataRight}
        //columnCount={10}
        sideLength={15}
        width={500} 
        height={500}
        />
      },
      */
      {
        title: "C",
        description: {
          type: "random-scatter",
          base: "1000",
          mode: "two-sides",
        },
        content: (
          <MyRiskComparison
            deviceType={deviceType}
            chartBase={"1000"}
            chartType={"random-scatter"}
            chartMode={"two-sides"}
            data1={chartDataLeft}
            data2={chartDataRight}
            //columnCount={10}
            sideLength={15}
            width={hundredWidth}
            height={hundredWidth}
            numberOfExtraLineForLegendLabel2={numberOfExtraLineForLegendLabel}
          />
        ),
      },
      {
        title: "D",
        description: {
          type: "random-scatter",
          base: "1000",
          mode: "two-sides-column",
        },
        content: (
          <MyRiskComparison
            deviceType={deviceType}
            chartBase={"1000"}
            chartType={"random-scatter"}
            chartMode={"two-sides-column"}
            data1={chartDataLeft}
            data2={chartDataRight}
            //columnCount={10}
            sideLength={15}
            width={hundredWidth}
            height={hundredWidth}
            numberOfExtraLineForLegendLabel2={numberOfExtraLineForLegendLabel}
          />
        ),
      },
    ];

    //alert("Risk pointer: " + risk_pointer);

    // <Button type="button" className="is-mobile is-centered has-text-centered is-hidden" onClick={(event) => { window.print(); }}>Click to print this page</Button>

    // <Button type="button" className="is-mobile is-centered has-text-centered" onClick={(event) => { window.printJS({ printable: 'dynamicResult', type: 'html', documentTitle: webTitle, css: ['https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css'] }); }}>{pageContent.print_button_label}</Button>

    // <button className="button is-mobile is-centered has-text-centered" style={{marginTop: 20, marginBottom: 20}} onClick={(event) => { window.printJS({ printable: 'dynamicResult', type: 'html', documentTitle: webTitle, css: ['https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css'] }); }}>{pageContent.print_button_label}</button>

    /*
    <SelectGroup
                    id="formRiskConfirmation"
                    type="text"
                    label={pageContent.risk_confirmation.label}
                    selectLabel={pageContent.default_select_label}
                    options={pageContent.risk_confirmation.options}
                    onChange={this.handleChange}
                    value={formRiskConfirmation}
                    placeholder={
                      pageContent.risk_confirmation.placeholder
                    }
                  />
    */

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="content has-text-justified" id="resultPage">
          <div className="top-sticky">
            <Header
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
              deviceType={deviceType}
              savedContent={savedContent}
              changeLanguage={this.onLanguageChange}
              {...others}
              style={{ width: "100%" }}
            />
          </div>
          <div className="columns is-mobile is-centered">
            <div className="column is-three-quarters">
              <p
                className="is-mobile is-centered has-text-centered"
                style={{ display: "none" }}
              >
                {pageContent.click_for_pdf_msg}
              </p>
              <div
                className="is-mobile is-centered has-text-centered"
                style={{ display: "none", cursor: "pointer" }}
              >
                {pageContent.print_button_label}
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }} className="is-hidden">
                <PrintButton
                  documentElementID={"dynamicResult"}
                  documentTitle={webTitle}
                  buttonLabel={pageContent.print_button_label}
                ></PrintButton>
              </div>
              <br />
              <div id="dynamicResult">
                <article className="tile is-child notification result-title-color has-text-centered">
                  <div className="is-size-4 is-size-5-mobile">
                    {pageContent.title}
                  </div>
                  <div className="is-size-5 is-size-6-mobile">
                    {pageContent.sub_title}
                  </div>
                </article>
                <article className="tile is-child notification eligibility-color">
                  <div className="columns">
                    <div className="column is-2 is-12-mobile">
                      <div className="has-text-centered">
                        <figure
                          className="image is-64x64"
                          style={{ display: "inline-block" }}
                        >
                          <img src="/image/doctor.png" />
                        </figure>
                      </div>
                      <br />
                    </div>
                    <div className="column is-8 is-12-mobile">
                      <div className="has-text-centered is-size-5 is-size-6-mobile">
                        {pageContent.getEligibilityStatement(
                          sex,
                          risk,
                          eligibility,
                          insurance,
                          old,
                          packYears,
                          neverSmoked
                        )}
                      </div>
                    </div>
                    <div className="column is-2 is-12-mobile">
                      <div className="has-text-centered">
                        <figure
                          className="image is-64x64"
                          style={{ display: "inline-block" }}
                        >
                          <img src="/image/tzhq.png" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </article>
                <article
                  className="tile is-child notification chance-color"
                  style={{ display: !neverSmoked ? "inherit" : "none" }}
                >
                  <div className=" has-text-centered is-size-5 is-size-6-mobile">
                    {pageContent.getChanceStatement(
                      sex,
                      risk,
                      eligibility,
                      insurance,
                      old,
                      packYears
                    )}
                  </div>
                </article>
                <div
                  className="tile is-parent"
                  style={{ visibility: !neverSmoked ? "visible" : "hidden" }}
                >
                  <article
                    className="tile is-child notification"
                    ref={(scaleElement) => (this.scaleElement = scaleElement)}
                    style={{ padding: 20 }}
                  >
                    <div style={{ display: !neverSmoked ? "inherit" : "none" }}>
                      <div className="content">
                        {abTestIndex == 1 ? (
                          <div className="container">
                            <div className="is-pulled-left">
                              <div className="is-size-5 is-size-6-mobile">
                                {pageContent.risk_spectrum.risk_statement}{" "}
                                {risk} %
                              </div>
                              <br />
                            </div>
                            <div className="is-pulled-right">
                              <div
                                className={
                                  risk < 1 ? "has-text-weight-bold" : ""
                                }
                              >
                                {pageContent.risk_spectrum.low_risk_label}
                              </div>
                              <div
                                className={
                                  risk >= 1 && risk <= 2
                                    ? "has-text-weight-bold"
                                    : ""
                                }
                              >
                                {
                                  pageContent.risk_spectrum
                                    .intermediate_risk_label
                                }
                              </div>
                              <div
                                className={
                                  risk > 2 ? "has-text-weight-bold" : ""
                                }
                              >
                                {pageContent.risk_spectrum.high_risk_label}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {abTestIndex == 0 ? (
                          <div>
                            <div
                              className={risk < 1 ? "has-text-weight-bold" : ""}
                            >
                              {pageContent.risk_spectrum.low_risk_label}
                            </div>
                            <div
                              className={
                                risk >= 1 && risk <= 2
                                  ? "has-text-weight-bold"
                                  : ""
                              }
                            >
                              {
                                pageContent.risk_spectrum
                                  .intermediate_risk_label
                              }
                            </div>
                            <div
                              className={risk > 2 ? "has-text-weight-bold" : ""}
                            >
                              {pageContent.risk_spectrum.high_risk_label}
                            </div>
                            <div className="is-size-5 is-size-6-mobile">
                              {pageContent.risk_spectrum.risk_statement} {risk}{" "}
                              %
                            </div>
                          </div>
                        ) : null}

                        <nav className="level is-size-5 is-size-6-mobile is-hidden">
                          <div className="level-item has-text-centered">
                            {
                              <div
                                className={
                                  risk < 1 ? "has-text-weight-bold" : ""
                                }
                              >
                                {pageContent.risk_spectrum.low_risk_label}
                              </div>
                            }
                          </div>
                          <div className="level-item has-text-centered">
                            {
                              <div
                                className={
                                  risk >= 1 && risk <= 2
                                    ? "has-text-weight-bold"
                                    : ""
                                }
                              >
                                {
                                  pageContent.risk_spectrum
                                    .intermediate_risk_label
                                }
                              </div>
                            }
                          </div>
                          <div className="level-item has-text-centered">
                            {
                              <div
                                className={
                                  risk > 2 ? "has-text-weight-bold" : ""
                                }
                              >
                                {pageContent.risk_spectrum.high_risk_label}
                              </div>
                            }
                          </div>
                        </nav>
                      </div>

                      <br />
                      <div className="flex">
                        <Stage width={stageWidth} height={stageHeight}>
                          <Layer name={"scale"}>
                            <Rect
                              x={spectrum_start_x}
                              y={scale_baseline_y}
                              width={spectrum_end_x - spectrum_start_x}
                              height={50}
                              fillPriority={"linear-gradient"}
                              fillLinearGradientColorStops={[
                                0,
                                "#ffffff", // "#ffeda0",
                                1 / 30,
                                "#ffeda0", // "#feb24c"
                                1.5 / 30,
                                "#feb24c", // "f03b20"
                                3 / 30,
                                "#f03b20",
                              ]}
                              fillLinearGradientStartPoint={{
                                x: spectrum_start_x,
                                y: scale_baseline_y,
                              }}
                              fillLinearGradientEndPoint={{
                                x: spectrum_end_x,
                                y: (stageHeight - 10) / 2,
                              }}
                            />
                          </Layer>
                          <Layer name={"marker"}>
                            <Line
                              name={"My Risk outline"}
                              points={[
                                spectrum_start_x + risk_pointer,
                                scale_baseline_y - 1,
                                spectrum_start_x + risk_pointer,
                                90 + 1,
                              ]}
                              stroke="black"
                              strokeWidth={10}
                              //ref="line"
                            />
                            <Line
                              name={"My Risk"}
                              points={[
                                spectrum_start_x + risk_pointer,
                                scale_baseline_y,
                                spectrum_start_x + risk_pointer,
                                90,
                              ]}
                              stroke="white"
                              strokeWidth={8}
                              ref="line"
                            />
                            <Text
                              x={risk_label_x}
                              y={100}
                              text={
                                pageContent.risk_spectrum.risk_statement +
                                " " +
                                risk +
                                " %"
                              }
                              fontSize={marker_label_font_size} // 18
                              //fontFamily={"Calibri"}
                              fill={"black"}
                              visible={false}
                            />
                            <Line
                              name={"Start"}
                              points={[
                                spectrum_start_x,
                                scale_baseline_y - 5,
                                spectrum_start_x,
                                scale_baseline_y + 50,
                              ]}
                              stroke="black"
                              strokeWidth={2.5}
                              ref="line"
                            />
                            <Text
                              x={spectrum_start_x}
                              y={0}
                              text={"0"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"black"}
                            />
                            <Line
                              name={"End"}
                              points={[
                                spectrum_end_x,
                                scale_baseline_y - 5,
                                spectrum_end_x,
                                scale_baseline_y + 50,
                              ]}
                              stroke="black"
                              strokeWidth={2.5}
                              ref="line"
                            />
                            <Text
                              x={spectrum_end_x - 15}
                              y={0}
                              text={risk_range}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"black"}
                            />
                          </Layer>
                          <Layer name={"legend"} visible={false}>
                            <Rect
                              x={0}
                              y={150}
                              width={50}
                              height={50}
                              stroke={"pink"}
                              fill={"red"}
                              fillPriority={"linear-gradient"}
                              fillLinearGradientColorStops={[
                                0,
                                "white",
                                1,
                                "red",
                              ]}
                              fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                              fillLinearGradientEndPoint={{
                                x: spectrum_end_x,
                                y: stageHeight - 10,
                              }}
                            />
                            <Text
                              x={50 + 10}
                              y={150 + 15}
                              text={"< 1% low risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />

                            <Line
                              name={"Low"}
                              points={[
                                low_risk_pointer,
                                0,
                                low_risk_pointer,
                                60,
                              ]}
                              stroke="black"
                              strokeWidth={2}
                              ref="line"
                            />
                            <Text
                              x={low_risk_pointer / 2}
                              y={60}
                              text={"< 1% low risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />
                            <Rect
                              x={(stageWidth * 2) / 5}
                              y={150}
                              width={50}
                              height={50}
                              stroke={"pink"}
                              fill={"red"}
                              fillPriority={"linear-gradient"}
                              fillLinearGradientColorStops={[
                                0,
                                "white",
                                1,
                                "red",
                              ]}
                              fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                              fillLinearGradientEndPoint={{
                                x: spectrum_end_x,
                                y: stageHeight - 10,
                              }}
                            />
                            <Text
                              x={(stageWidth * 2) / 5 + 50 + 10}
                              y={150 + 15}
                              text={"1-2% intermediate risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />
                            <Text
                              x={(low_risk_pointer + high_risk_pointer) / 2}
                              y={60}
                              text={"1-2% intermediate risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />
                            <Line
                              name={"High"}
                              points={[
                                high_risk_pointer,
                                0,
                                high_risk_pointer,
                                60,
                              ]}
                              stroke="black"
                              strokeWidth={2}
                              ref="line"
                            />
                            <Rect
                              x={(stageWidth * 4) / 5}
                              y={150}
                              width={50}
                              height={50}
                              stroke={"pink"}
                              fill={"red"}
                              fillPriority={"linear-gradient"}
                              fillLinearGradientColorStops={[
                                0,
                                "white",
                                1,
                                "red",
                              ]}
                              fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                              fillLinearGradientEndPoint={{
                                x: spectrum_end_x,
                                y: stageHeight - 10,
                              }}
                            />
                            <Text
                              x={spectrum_end_x}
                              y={150 + 15}
                              text={"> 2% high risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />
                            <Text
                              x={high_risk_pointer + stageWidth / 6}
                              y={60}
                              text={"> 2% high risk"}
                              fontSize={15}
                              //fontFamily={"Calibri"}
                              fill={"green"}
                            />
                          </Layer>
                        </Stage>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="tile is-parent is-vcentered hidden">
                  <RadioGroup
                    id="formRiskConfirmation"
                    label={pageContent.risk_confirmation.label}
                    options={pageContent.risk_confirmation.options}
                    onChange={this.handleChange}
                    inline={true}
                    placeholder={pageContent.risk_confirmation.placeholder}
                  />
                </div>
                <div
                  className="tile is-parent"
                  style={{ display: !neverSmoked ? "inherit" : "none" }}
                >
                  <article className="tile is-child notification explanation-color">
                    <div className="has-text-centered  is-size-5 is-size-6-mobile">
                      {pageContent.getExplanationStatement(
                        sex,
                        risk,
                        eligibility,
                        insurance
                      )}
                    </div>
                  </article>
                </div>

                <div
                  //className="tile is-child"
                  style={{ display: !neverSmoked && !isIE ? "inherit" : "none" }}
                >
                  <MyTabsGeneral
                    list={chartList}
                    initialIndex={chartIndex}
                    onTabClick={this.onChartTabClick}
                    showTabs={chartMode == "show-all"}
                  ></MyTabsGeneral>
                  <RadioGroup
                    id="formBenefitConfirmation"
                    label={pageContent.benefit_confirmation.label}
                    options={pageContent.benefit_confirmation.options}
                    onChange={this.handleChange}
                    //value={formBenefitConfirmation} // see if it workss
                    inline={true}
                    placeholder={pageContent.benefit_confirmation.placeholder}
                  />
                </div>

                <div
                  //className="tile"
                  style={{ display: !!neverSmoked && !isIE ? "inherit" : "none" }}
                >
                  
                </div>
                <div
                  //className="tile is-parent is-horizontal"
                  style={{ display: !neverSmoked ? "inherit" : "none" }}
                >
                  <div className="columns">
                    <div className="column">
                    <div className="notification benefit-background-color">
                      <div className="has-text-centered is-size-4 is-size-5-mobile benefit-color">
                        {pageContent.benefit_section.title}
                      </div>
                      <div className="row">
                        <div className="col-sm is-4 is-12-mobile">
                          <div className="has-text-centered">
                            <figure
                              className="image is-64x64"
                              style={{ display: "inline-block" }}
                            >
                              <img src="/image/ms.png" />
                            </figure>
                          </div>
                        </div>
                        <div className="col-sm is-8 is-12-mobile">
                          <div className="is-size-5 is-size-6-mobile benefit-color">
                            {" "}
                            {pageContent.getBenefitTitle(
                              sex,
                              risk,
                              eligibility,
                              insurance
                            )}
                          </div>
                          <div className="content">
                            {pageContent.benefit_section.statement_end}
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    </div>
                    <div className="column">
                    <div className="notification risk-color">
                      <div className="has-text-centered  is-size-4 is-size-5-mobile">
                        {pageContent.risk_section.title}
                      </div>
                      <div className="columns">
                        <div className="column">
                          <div className="is-size-5 is-size-6-mobile"> </div>
                          <div className="content">
                            <ul className="fa-ul">
                              {pageContent.risk_section.statement_list.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      style={{ marginBottom: "1em" }}
                                    >
                                      <span className="fa-li">
                                        <i
                                          className={
                                            index <
                                            pageContent.risk_section
                                              .statement_list.length -
                                              1
                                              ? "fas fa-circle"
                                              : "fas fa-asterisk"
                                          }
                                        ></i>
                                      </span>
                                      {item}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    </div>
                  </div>
                </div>
                <div style={dividerStyle}></div>
                {reflectionSubmitted ? (
                  <div
                    flexDirection="column"
                    alignItems="center"
                    style={mainContentStyle}
                    className="is-hidden "
                  >
                    <h3>
                      {pageContent.instructions.afterSubmission.map(
                        (item, index) => {
                          return <div key={index}>{item}</div>;
                        }
                      )}
                    </h3>
                  </div>
                ) : (
                  <div />
                )}

                {reflectionSubmitted ? (
                  <div />
                ) : (
                  <div
                    id="resultPage_reflection_form"
                    style={{ display: !neverSmoked ? "inherit" : "none" }}
                  >
                    {pageContent.instructions.beforeSubmission.map(
                      (item, index) => {
                        return <p key={index}>{item}</p>;
                      }
                    )}
                    <form onSubmit={this.handleSubmit}>
                      <SelectGroup
                        id="formPersonalBenefit"
                        type="text"
                        selectLabel={pageContent.default_select_label}
                        onChange={this.handleChange}
                        value={formPersonalBenefit}
                        {...pageContent.reflection_questions
                          .formPersonalBenefit}
                      />
                      <SelectGroup
                        id="formRecommended"
                        type="text"
                        selectLabel={pageContent.default_select_label}
                        onChange={this.handleChange}
                        value={formRecommended}
                        {...pageContent.reflection_questions.formRecommended}
                      />
                      <FieldGroup
                        id="formRisk"
                        type="text"
                        onChange={this.handleChange}
                        value={formRisk}
                        {...pageContent.reflection_questions.formRisk}
                      />
                      <FieldGroup
                        id="formReason"
                        type="text"
                        onChange={this.handleChange}
                        value={formReason}
                        {...pageContent.reflection_questions.formReason}
                      />

                      <input
                        className="button"
                        type="submit"
                        value={pageContent.submit_button_label}
                      ></input>
                    </form>
                  </div>
                )}
                <MyNavigator
                  currentRoute={this.props.location.pathname}
                  language={language}
                  Content={Content}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

//export default ResultPage;

// get images from Square space
//var resultImgURL = "https://lisa-lau-epa2.squarespace.com/assets/";

// get images from UM
//var resultImgURL = "";

// for testing locally
//let assetDirectoryURL = "https://shouldiscreen.com/assets/";

// for deployment on UM
//let assetDirectoryURL = "/assets/";

// get images on UM from UM
// var resultImgURL = "/assets/";
/*
    switch (language) {
      case "English":
        resultImgURL = assetDirectoryURL + resultName + ".png";
        break;
      case "Español":
        resultImgURL = assetDirectoryURL + resultName + "_esp" + ".png";
        break;
      default:
        break;
    }
    */

//console.log("Trying to load result png:" + resultImgURL);

//var resultImgURL = "https://lisa-lau-epa2.squarespace.com/assets/" + this.props.match.params.display;

//var resultImgURL = "/assets/" + this.props.match.params.display;

//var resultPDFURL = "https://lisa-lau-epa2.squarespace.com/assets/" + this.props.match.params.display.substring(0, extensionIndex) +".pdf";

// var resultPDFURL = resultImgURL.substring(0, resultImgURL.indexOf(".png")) + ".pdf";

//var resultPDFURL = "/assets/" + this.props.match.params.display.substring(0, extensionIndex) +".pdf";

/*
<nav className="columns is-hidden">
  <div className="column is-3">
    <div className="is-size-5 is-size-6-mobile">
      {pageContent.risk_spectrum.risk_statement} {pRisk}{" "}
      %
    </div>
  </div>
  <div className="column"></div>
  <div className="column is-2">
    <div
      className={
        pRisk < 1 ? "has-text-weight-bold" : ""
      }
    >
      {pageContent.risk_spectrum.low_risk_label}
    </div>
    <div
      className={
        pRisk >= 1 && pRisk <= 2
          ? "has-text-weight-bold"
          : ""
      }
    >
      {
        pageContent.risk_spectrum
          .intermediate_risk_label
      }
    </div>
    <div
      className={
        pRisk > 2 ? "has-text-weight-bold" : ""
      }
    >
      {pageContent.risk_spectrum.high_risk_label}
    </div>
  </div>
</nav>
*/

// <Button type="submit">{pageContent.submit_button_label}</Button>

// <Button type="submit">{pageContent.submit_button_label}</Button>
/*
<a
                href={resultPDFURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "none" }}
              >
                <img
                  src={resultImgURL}
                  style={imageStyle}
                  alt={alt}
                  id="resultPage_graph"
                  onLoad={this.onImageLoad}
                />
              </a>
*/
