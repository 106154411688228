//import moment from "moment";

class MyUtility {
  constructor() {
    console.log("MyUtility Provider: constructor");
  }

  static getLanguageFromURL(url) {
    let language = url.substring(1, url.indexOf("/", 1));

    return language;
  }

  static getRouteFromFullURL(url) {
    let route = url.substring(url.indexOf("/", url.indexOf("://", 0) + 3));
    return route;
  }
  
  static getLanguageFromFullURL(url) {
    let route = MyUtility.getRouteFromFullURL(url);
    let language = route.substring(1, route.indexOf("/", 1));

    return language;
  }

  static isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n) && n >= 0 && n != "";
  }
  /*
  static getData(start, end, amount) {
    var data = [];
    for (var i = 0; i < amount; i++) {
      data.push({
        value: this.randomDate(start, end)
      });
    }
    return data;
  }

  static randomDate(start, end) {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).valueOf();
  }

  static getStartOfADay(date) {
    console.log("getStartOfADay");
    var tempDate = moment(date)
      .startOf("day")
      .toDate();
    return tempDate;
  }
  static getEndOfADay(date) {
    console.log("getEndOfADay");
    var tempDate = moment(date)
      .endOf("day")
      .toDate();
    return tempDate;
  }

  static getLocalTimeString(date) {
    console.log(
      "getLocalTimeString: " +
        moment(date.toISOString())
          .locale("us")
          .format()
    );
    return moment(date.toISOString())
      .locale("us")
      .format();
  }

  static getUTCTimeString(date) {
    console.log("getUTCTimeString: " + date.toUTCString());
    return date.toUTCString();
  }

  static getLocalTimeFromString(dateString) {
    console.log("getLocalTimeFromString: " + moment(dateString).locale("us"));
    return moment(dateString).locale("us");
  }

  static getTodayStart() {
    console.log("getTodayStart");
    var tempDate = moment()
      .startOf("day")
      .toDate();
    //tempDate.setHours(0);
    return tempDate;
  }
  static getTodayEnd() {
    console.log("getTodayEnd");
    var tempDate = moment()
      .endOf("day")
      .toDate();
    //tempDate.setHours(0);
    return tempDate;
  }

  static getStartDateOfThisWeek() {
    console.log("getStartDateOfThisWeek");
    var tempDate = moment()
      .startOf("week")
      .toDate();
    //tempDate.setHours(0);
    return tempDate;
  }
  static getEndDateOfThisWeek() {
    console.log("getEndDateOfThisWeek");
    var tempDate = moment()
      .endOf("week")
      .toDate();
    //tempDate.setHours(0);
    return tempDate;
  }

  static getSevenDaysThisWeek() {
    console.log(
      "getSevenDaysThisWeek, starting from:" +
        MyUtility.getStartDateOfThisWeek()
    );
    var result = [];

    var startDate = MyUtility.getStartDateOfThisWeek();
    result.push(startDate);

    for (var i = 1; i < 7; i++) {
      var newDate = MyUtility.addMoment(startDate, i, "day");
      result.push(newDate);
    }

    return result;
  }

  static formatMomentString(date, formatStr) {
    console.log("formatMomentString:" + moment(date).format(formatStr));
    return moment(date).format(formatStr);
  }

  static addMoment(date, amount, unit) {
    console.log(
      "addMoment:" +
        moment
          .utc(date)
          .add(amount, unit)
          .toDate()
    );
    return moment
      .utc(date)
      .add(amount, unit)
      .toDate();
  }

  static subtractMoment(date, amount, unit) {
    //var a = moment().subtract(1, 'day');
    console.log(
      "subtractMoment:" +
        moment(date)
          .subtract(amount, unit)
          .toDate()
    );
    return moment(date)
      .subtract(amount, unit)
      .toDate();
  }

  showAlert(title, subTitle, buttons) {}
  */
}

export default MyUtility;
