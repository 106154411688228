import React, { Component, Fragment } from "react";
import Pager from "react-bootstrap/lib/Pager";

import { Link } from "react-router-dom";

import MyPager from "../components/MyPager";

const headerStyle = {
  backgroundColor: "#339479",
  width: "100%"
};

const linkStyle = {
  color: "#000000"
};

const textStyle = {
  color: "#000000"
};

export default class MyNavigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousRouteInfo: { route: "", label: "" },
      nextRouteInfo: { route: "", label: "" }
    };

    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // chekc url and set state
    /*
    const { currentRoute, language } = this.props;

    console.log("MyNavigator.currentRoute: " + currentRoute);

    var currentRouteIndex = 0;
    var previousRouteInfo = this.state.previousRouteInfo;
    var nextRouteInfo = this.state.nextRouteInfo;

    var pageContent = "";

    if (Content.all_content[language]["headerComponent"] != undefined) {
      pageContent = Content.all_content[language]["headerComponent"];
    } else {
      pageContent = Content.all_content["English"]["headerComponent"];
    }

    var routeOrderList = pageContent.route_order_list;

    for (var i = 0; i < routeOrderList.length; i++) {
      var cRoute = routeOrderList[i].route;
      if (cRoute == currentRoute) {
        // found it
        currentRouteIndex = i;
        break;
      }
    }

    if (currentRouteIndex > 0) {
      previousRouteInfo = routeOrderList[currentRouteIndex - 1];
    }

    if (currentRouteIndex < routeOrderList.length - 1) {
      nextRouteInfo = routeOrderList[currentRouteIndex + 1];
    }

    console.log("prevRoute: " + JSON.stringify(previousRouteInfo));
    console.log("nextRoute: " + JSON.stringify(nextRouteInfo));

    this.setState({
      previousRouteInfo,
      nextRouteInfo
    });
    */
  }

  render() {
    const { Content, language, currentRoute } = this.props;

    // option 1: has bugs.
    //const { previousRouteInfo, nextRouteInfo } = this.state;

    // now getting the previous and next route
    console.log("MyNavigator.currentRoute: " + currentRoute);

    var currentRouteIndex = 0;
    var previousRouteInfo = this.state.previousRouteInfo;
    var nextRouteInfo = this.state.nextRouteInfo;

    var pageContent = "";

    if (
      Content.all_content[language] != undefined &&
      Content.all_content[language]["headerComponent"] != undefined
    ) {
      pageContent = Content.all_content[language]["headerComponent"];
    } else {
      pageContent = Content.all_content["English"]["headerComponent"];
    }

    var routeOrderList = pageContent.route_order_list.slice(
      0,
      pageContent.route_in_menu_limit
    );

    for (var i = 0; i < routeOrderList.length; i++) {
      var cRoute = routeOrderList[i].route;
      if (cRoute == currentRoute) {
        // found it
        currentRouteIndex = i;
        break;
      }
    }

    if (currentRouteIndex > 0) {
      previousRouteInfo = routeOrderList[currentRouteIndex - 1];
    }

    if (currentRouteIndex < routeOrderList.length - 1) {
      nextRouteInfo = routeOrderList[currentRouteIndex + 1];
    }

    console.log("prevRoute: " + JSON.stringify(previousRouteInfo));
    console.log("nextRoute: " + JSON.stringify(nextRouteInfo));

    console.log("MyNavigator.render.language: " + language);

    return (
      <Fragment>
      <br />
      <div className="has-text-justified">
        <div className="columns is-centered">
          <div className="column is-4">
            {previousRouteInfo.route.length > 0 && (
              <Fragment>
                <MyPager
                  link={previousRouteInfo.route}
                  label={previousRouteInfo.label}
                  arrowDirection={"left"}
                ></MyPager>
              </Fragment>
            )}
          </div>
          <div className="column is-3"></div>
          <div className="column is-4">
            {" "}
            {nextRouteInfo.route.length > 0 && (
              <Fragment>
                <MyPager
                  link={nextRouteInfo.route}
                  label={nextRouteInfo.label}
                  arrowDirection={"right"}
                ></MyPager>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

/*
<div className="column is-three-quarters has-text-centered">
            <Pager>
              {previousRouteInfo.route.length > 0 && (
                <Fragment>
                  <MyPager 
                    link={previousRouteInfo.route} 
                    label={previousRouteInfo.label} 
                    arrowDirection={"left"}>
                  </MyPager>
                  <Link
                  to={previousRouteInfo.route}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="Navigator"
                  data-event-action={previousRouteInfo.route}
                >
                  <Pager.Item previous href={" "}>
                    &larr; {previousRouteInfo.label}
                  </Pager.Item>
                </Link>
                </Fragment>
              )}
              {nextRouteInfo.route.length > 0 && (
                <Fragment>
                  <Link
                    to={nextRouteInfo.route}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="Navigator"
                    data-event-action={nextRouteInfo.route}
                  >
                    <Pager.Item next href={" "}>
                      {nextRouteInfo.label} &rarr;
                    </Pager.Item>
                  </Link>
                  <MyPager 
                    link={nextRouteInfo.route} 
                    label={nextRouteInfo.label} 
                    arrowDirection={"right"}>
                  </MyPager>
                </Fragment>
              )}
            </Pager>
          </div>
*/
