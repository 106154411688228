import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "./components/MyPager";
import MyCalculation from "./utils/MyCalculation";
//import Pager from 'react-bootstrap/lib/Pager';

//import "./css/bulma.min.css";

export default class Content {
  constructor() {
    //super(props);
    console.log("Content: constructor");
  }
  static all_content = {
    English: {
      site: {
        loading_message: "Loading...",
        ie_message: <div className="lead">
        Looks like you are using Internet Explorer (IE). Our site works best with Chrome, and we might not be able to support IE in the future. Please consider using Chrome, Edge, or Firefox for the best results.<br /><br />
        You can download Chrome here: <a
                    target="_blank"
                    href="https://www.google.com/chrome/"
                    rel="noopener noreferrer"
                  >
                    Chrome
                  </a>
        </div>
      },
      benefitHarmPage: {
        banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
        banner_title: "Lung Cancer Screening",
        title: "",
        benefit_harm_content: {
          benefit: {
            title: "WHAT ARE THE BENEFITS OF SCREENING?",
            content:
            "For older adults who are screened, the chances of finding cancer early is higher. Finding cancer early generally means that more successful treatments can be offered. A recent study showed that after 6.5 years, among those who were eligible for screening, those who were screened with CT were 20% less likely to die from lung cancer compared to those who were not screened with CT. Lung cancer screening only helps to find cancer if it is already there. It cannot prevent cancer. The best way to prevent cancer is to stop smoking if you have not already done so."
          //"For people who are eligible for screening and decide to get screened, the chances of finding cancer early is higher. Finding cancer early generally means that more successful treatments can be offered. A recent study showed that after 6.5 years, among those who were eligible for screening, those who were screened with CT were 20% less likely to die from lung cancer compared to those who were not screened with CT."
          },
          harm: {
            title: "WHAT ARE THE POSSIBLE HARMS OF SCREENING?",
            //content: (
            //  <div>
            //   <p>
            //      CT scanning for lung cancer screening uses up to 90% 
            //     less radiation than a conventional chest CT scan. The radiation dose                   
            //      is equal to about 6 months of radiation from your every day 
            //      environment. The additional risk of developing fatal lung cancer 
            //      from one adult exam is considered to be extremely low. For those of you
            ///      interested in the total dose of radiation, you can visit&nbsp;
            //      <a
            //        target="_blank"
            //       href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
            //        rel="noopener noreferrer"
            //      >
            //        this site
            //      </a>
            //      . Other potential harms from screening include: false alarms,
            //      overtreatment, more testing, and invasive procedures.
            //    </p>
          //    </div>
        //    )
          }
        },
        sample_list: [
          {
            title: "Radiation",
            content: (
              <div>
                <p>
                CT scanning for lung cancer screening uses up to 90% less radiation than a conventional chest CT scan. 
                The radiation dose is equal to about 6 months of radiation from your every day 
                environment. The additional risk of developing fatal lung cancer 
                from one adult exam is considered to be extremely low. For those of you
                interested in the total dose of radiation, you can visit&nbsp;
                  <a
                    target="_blank"
                   href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                    rel="noopener noreferrer"
                  >
                    this site
                  </a>
                  .
                </p>
              </div>
            )
          },

          {
            title: "False alarms",
            content: (
              <div>
                <p>
                  A false alarm (also known as false positive) is finding a lung 
                  nodule that is not cancer. 
                  Screening works by finding lung nodules, some of which are cancer,
                  but most nodules detected on the low-dose CT scan for lung cancer
                  screening are <strong>not</strong> cancer. 
                   A lung nodule is a round 
                  or oval shaped spot that may or may not require further testing. 
                  With the current nodule management guidelines, the false positive rate 
                  for lung cancer screening is low at about 9% (among those who complete 
                  follow-up testing). 
                  
                  False positive findings can cause anxiety. However, understanding that 
                  most lung nodules found from the CT screening do <strong>not </strong> 
                  represent cancer helps most avoid significant anxiety while they wait 
                  for follow-up testing.
                </p>
              </div>
            )
          },
          {
            title: "Overtreatment (or Overdiagnosis)",
            content: (
              <div>
                <p>
                  Because doctors can’t always accurately identify which nodules 
                  will grow quickly, it can be difficult to know who will benefit 
                  from treatment and who will not benefit. Due to this uncertainty, 
                  most people receive treatment. As a result, some lung cancers 
                  are treated, but the treatment does not necessarily help a 
                  person live a longer or healthier life. This problem is called 
                  overtreatment. Treatments can include surgery, chemotherapy, 
                  and/or radiation, which can result in side effects.
                </p>
              </div>
            )
          },
          {
            title: "More testing",
            content: (
              <div>
                <p>
                  In order to prove a nodule is not cancer, your doctor will make recommendations
                  for the proper follow up to monitor any lung nodules
                  detected. Most often, this is at 3-month to 6-month
                  intervals.
                </p>
              </div>
            )
          },
          {
            title: "Follow-up procedures",
            content: (
              <div>
                <p>
                  Sometimes further testing for a finding on lung cancer
                  screening leads to the need for additional testing that can
                  include invasive procedures (biopsies or surgery). These tests
                  are risky and can cause harm in people that end up not having
                  had cancer. Invasive procedures can result in minor
                  complications such as bleeding and infections. They can also
                  result in more major complications such as a collapsed lung
                  and even death.
                </p>
              </div>
            )
          }
        ],
        more_info: (
          <p>
            FOR MORE DETAILS, YOU CAN VISIT THE{" "}
            <a
              href={"https://www.cancer.gov/types/lung/research/nlst-qa"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Q & A"}
            </a>{" "}
            COMPILED BY THE NATIONAL CANCER INSTITUTE THAT EXPLAINS THE FINDINGS
            FROM THE NATIONAL LUNG SCREENING TRIAL.
          </p>
        ),
        image_info: {
          head: "Image: ",
          src: "https://www.flickr.com/photos/pulmonary_pathology/",
          text: "Yale Rosen/Flickr. CC BY-SA 2.0."
        }
      },
      homePage: {
        banner_title: "Lung Cancer Screening",
        banner_content: "Should I get screened?",
        banner_button_label: "Click to learn more",
        banner_button_link: "/English/how-is-screening-done",
        two_column_title: "This website can help you decide.",
        two_column_sub_title:
        (
          <div>
            <ul>
              <li>It's never too late to learn more about what you can do for your lung health.</li>
              <li>This website has up to date information about Lung Cancer Screening to help you make the best choice.</li>
              <li>Talk to your doctor about what is right for you.</li>
            </ul>
          </div>
        ),     
        //"Making a decision about lung cancer CT screening is important for your overall health. This booklet has up to date information on lung cancer screening to help you make the best choice for your health.",
        //"Deciding whether or not to go through lung cancer CT screening is not easy. Here, there is up to date information provided by doctors to help you make the best choice for you.",
        //"Deciding whether or not to go through lung cancer CT screening is not easy. We have put together a road map of what you should know based on where you are in this important decision.",
        two_column_content: {
          first: {
            title: "Who is eligible for lung cancer screening?",
            sub_title: (
              <div>
                <p>
                  The US Preventive Services Task Force recently updated the eligibility guidelines for lung cancer screening to the following:
                </p>
                <ul>
                  <li>50-80 year olds,</li>
                  <li>Smoked within past 15 years, and </li>
                  <li>
                    20 pack-years (see{" "}
                    <Link
                      to={"/English/pack-year-calculator"}
                      
                      // not working
                      //to={this.getRouteByPageAndLanguage("CalculatorPage", "English")}
                      style={{ width: "100%", textDecoration: "none" }}
                      data-on="click"
                      data-event-category="HomePage"
                      data-event-action={"/English/pack-year-calculator"}
                    >
                      pack calculator
                    </Link>
                    ).
                  </li>
                </ul>
            
              <p>
                However, Medicare and Medicaid coverage guidelines for lung cancer screening remain (as of 9 March 2021) as:
              </p>
                <ul>
                  <li>55-77 years olds,</li>
                  <li>Smoked within past 15 years, and </li>
                  <li>
                    30 pack-years (see{" "}
                    <Link
                      to={"/English/pack-year-calculator"}
                      style={{ width: "100%", textDecoration: "none" }}
                      data-on="click"
                      data-event-category="HomePage"
                      data-event-action={"/English/pack-year-calculator"}
                    >
                      pack calculator
                    </Link>
                    ).
                  </li>
                </ul>
              </div>
            ),
            image: "/image/questioningDude.jpg",
            button_label: "Learn More",
            link: "/English/how-is-screening-done"
          },
          second: {
            title: "Lung Cancer Risk Calculator",
            sub_title:
              "Do you want to know if you should be screened? Use our calculator to see your personalized lung cancer risk.",
            image: "/image/SIScalculator.jpg",
            button_label: "Use it",
            link: "/English/lung-cancer-risk-calculator"
          }
        },
        /*time_navigation: [
          {
            title: "1. Why",
            items: [
              {
                title: "Why should I be thinking about this?",
                content: (
                  <div>
                    <p>
                      It is better to find lung cancer early because treatment
                      has much higher chances of being effective compared to
                      cancer found at later stages.{" "}
                      <Pager>
                        <Link
                          to={"/English/how-is-screening-done"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={"/English/how-is-screening-done"}
                        >
                          <Pager.Item next href={" "}>
                            Click to read more &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              },
              {
                title: "What should I be feeling to get this done?",
                content: (
                  <p>
                    Some of the symptoms of lung cancer are pain, weight loss
                    and a cough that won't go away.{" "}
                    <strong>
                      But lung cancer screening is not for people with symptoms
                    </strong>. Many people who feel these symptoms already have
                    late-stage lung cancer.
                  </p>
                )
              },
              {
                title: "What do I have to do for this test?",
                content: (
                  <div>
                    <p>
                      Lung cancer screening involves getting a CT (or CAT) scan
                      of your chest. The procedure involves you lying still and
                      going through a machine while multiple images are taken
                      using X-rays and a computer.{" "}
                      <Pager>
                        <Link
                          to={"/English/how-is-screening-done"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={"/English/how-is-screening-done"}
                        >
                          <Pager.Item next href={" "}>
                            Click to read more &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              }
            ]
          },
          {
            title: "2. Cost and process",
            items: [
              {
                title: "Cost",
                content: (
                  <div>
                    <p>
                      Average out-of-pocket cost for the initial lung cancer
                      screening if you are uninsured can be from ~$100-$400.
                      Visit our insurance page by clicking the button below to
                      find out if your health insurance covers lung cancer
                      screening for you.
                    </p>
                    <p>
                      <Pager>
                        <Link
                          to={"/English/coverage"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={"/English/coverage"}
                        >
                          <Pager.Item next href={" "}>
                            Click to read more about insurance coverage &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              },
              {
                title:
                  "Lung cancer screening is a process and not a one-time thing.",
                content: (
                  <div>
                    <p>
                      Screening may lead to a follow-up scan that could be
                      sooner than coming back again in a year depending on if
                      nodules are found.{" "}
                    </p>
                    <p>
                      If they found nodules on your lung, depending on the size
                      and characteristics of the nodules, you may be asked to go
                      for another check up sooner.{" "}
                    </p>
                  </div>
                )
              },
              {
                title: "Where can I get screened?",

                content: (
                  <div>
                    <p>
                      It is recommended that you receive lung cancer screening
                      at an accredited facility.
                    </p>
                    <p>
                      <Pager>
                        <Link
                          to={"/English/how-is-screening-done"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={"/English/how-is-screening-done"}
                        >
                          <Pager.Item next href={" "}>
                            Find an accredited facility &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              }
            ]
          },
          {
            title: "3. Other considerations",
            items: [
              {
                title: "More about potential harms",
                content: (
                  <div>
                    <p>
                      You may want to consider the possibility of false
                      positives and overtreatment from lung cancer screening.
                    </p>
                    <p>
                      <Pager>
                        <Link
                          to={"/English/benefits-and-harms-screening"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={
                            "/English/benefits-and-harms-screening"
                          }
                        >
                          <Pager.Item next href={" "}>
                            Learn more about harms &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              },
              {
                title:
                  "Other risk factors for lung cancer other than cigarette smoking:",
                content: (
                  <div>
                    <p>
                      While cigarette smoking is by far the largest contributor
                      to lung cancer deaths, radon and occupational exposures
                      (e.g. asbestos, arsenic, silica, cadmium) are also risk
                      factors for lung cancer.
                    </p>
                    <p>
                      <Pager>
                        <Link
                          to={"/English/causes-of-lung-cancer"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={"/English/causes-of-lung-cancer"}
                        >
                          <Pager.Item next href={" "}>
                            Learn more about risk factors &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </p>
                  </div>
                )
              },
              {
                title: "Methods to reduce your risk of lung cancer",
                content: (
                  <div>
                    <div>
                      <Pager>
                        <Link
                          to={"/English/risk-reduction-lung-cancer"}
                          style={{ width: "100%", textDecoration: "none" }}
                          data-on="click"
                          data-event-category="HomePage"
                          data-event-action={
                            "/English/risk-reduction-lung-cancer"
                          }
                        >
                          <Pager.Item next href={" "}>
                            Learn about reducing lung cancer risk &rarr;
                          </Pager.Item>
                        </Link>
                      </Pager>
                    </div>
                  </div>
                )
              }
            ]
          }
        ]
      },*/
        time_navigation: [
          {
            title: "1. Why?",
            items: [
              {
                title: "Why should I be thinking about this?",
                content: (
                  <div>
                    <p>
                      Screening can help find lung cancer at an early stage,
                      when more successful treatment can be offered. Without screening,
                      lung cancer is usually found at a late stage.{" "}
                    </p>
                    <div className="is-pulled-right">
                      <MyPager
                        link={"/English/how-is-screening-done"}
                        label={"Learn more"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </div>
                )
              },
              {
                title: "What are the warning signs of lung cancer?",
                content: (
                  <p>
                    If you have any symptoms that concern you, please discuss
                    them with your physician.
                    Symptoms include:
                    <ul>
                      <li>pain,</li>
                      <li>unexpected or unplanned weight loss,</li>
                      <li>coughing up blood, or</li> 
                      <li>a changing cough that won't go away.</li>
                    </ul>
                    These symptoms are concerning for lung cancer, especially if you are a smoker.{" "}
                    <strong>
                      Note that lung cancer screening is not for people with symptoms
                    </strong>
                    .
                  </p>
                )
              },
              {
                title: "What is lung cancer CT screening?",
                content: (
                  <div>
                    <p>
                      Lung cancer screening involves getting a CT (or CAT) scan
                      of your chest. The procedure involves you lying still and
                      going through a doughnut-shaped machine while multiple
                      images are taken using X-rays and a computer.{" "}
                    </p>
                    <div className="is-pulled-right">
                      <MyPager
                        link={"/English/how-is-screening-done"}
                        label={"Learn more"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </div>
                )
              }
            ]
          },
          {
            title: "2. Cost",
            items: [
              {
                title: "Cost",
                content: (
                  <div>
                    <p>
                      If you are uninsured, the initial lung cancer screening
                      can cost you $100-$400 out-of-pocket. Visit the insurance
                      page by clicking the button below to find out if your
                      health insurance covers lung cancer screening for you.
                    </p>
                    <p>
                      <div className="is-pulled-right">
                        <MyPager
                          link={"/English/coverage"}
                          label={"Learn more"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              },
              {
                title: "Where can I get screened?",

                content: (
                  <div>
                    <p>
                      It is recommended that you get lung cancer screening at a
                      facility that is accredited by the American College of
                      Radiology. Click&nbsp;
                      <a
                        target="_blank"
                        href="http://www.acraccreditation.org/accredited-facility-search"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>
                      &nbsp;to see a list of accredited facilities.
                    </p>
                  </div>
                )
              }
            ]
          },
          {
            title: "3. Other things to consider",
            items: [
              {
                title: "Lung cancer screening is not a one-time thing.",
                content: (
                  <div>
                    <p>
                      Lung cancer screening is a process that must be done under the 
                      direction of your doctor(s). It is not a one-time thing.  
                      To get the full benefit of screening, you need to
                      be screened once a year until you are no longer eligible.
                      Also, screening may lead to finding a nodule that needs a
                      follow-up CT scan within 6 months.{" "}
                    </p>
                  </div>
                )
              },

              {
                title: "About potential harms",
                content: (
                  <div>
                    <p>
                      You may want to consider the possibility of false
                      positives and overtreatment from lung cancer screening.
                    </p>
                    <p>
                      <div className="is-pulled-right">
                        <MyPager
                          link={"/English/benefits-and-harms-screening"}
                          label={"Learn more"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              },
              {
                title:
                  "Other risk factors for lung cancer, other than cigarette smoking",
                content: (
                  <div>
                    <p>
                      While cigarette smoking is by far the largest contributor
                      to lung cancer risk, radon and occupational exposures
                      (e.g. asbestos, arsenic, silica, cadmium) are also risk
                      factors for lung cancer.
                    </p>
                    <p>
                      <div className="is-pulled-right">
                        <MyPager
                          link={"/English/causes-of-lung-cancer"}
                          label={"Learn more"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              }
            ]
          }
        ]
      },
      causePage: {
        banner_image_source: "/image/smoke.jpg",
        banner_title: "Lung Cancer Screening",
        cause_info: {
          title: "LUNG CANCER AND ITS CAUSES",
          causes: [
            <p>
              Lung cancer starts with cells in the lung airway that have suffered
              permanent damage. This damage results in an abnormal uncontrolled
              growth of cells and can spread to other parts of your body
              (metastasis). Lung cancer is the leading cause of cancer related
              death in the world.
            </p>,
            <p>
              There are many causes linked to lung cancer. The most important
              and well understood cause is{" "}
              <strong>cigarette smoking</strong>.
            </p>
          ]
        },
        cause_chart: {
          src: "/image/attributable_risks_tiff2_English.png",
          text:
            "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49."
        },
        risk_info: {
          title: "Other known risk factors for lung cancer are:",
          risks: [
            "long term exposure to radon (a colorless, odorless gas that miners are exposed to, and which can accumulate in the basement of some homes);",
            "occupational exposure to asbestos, arsenic, and some types of chromium and silica;",
            "a diagnosis of Chronic Obstructive Pulmonary Disease (COPD), or emphysema and/or chronic bronchitis, made by a doctor; and",
            "a family history of lung cancer."
          ]
        }
      },
      comparePage: {
        image_info: {
          link: "/image/fruit.png",
          attribution: {
            link: "https://creativecommons.org/licenses/by-sa/2.0/",
            label: "MicroAssist/Flickr: CC BY-SA 2.0"
          }
        },
        title:
          "How does lung cancer CT screening compare with other kinds of screening?",
        sub_title: "DID YOU KNOW?",
        content:
          "Compared to other common cancer screening tests such as mammography for breast cancer, lung cancer CT screening is at least as effective at reducing lung cancer deaths in high risk individuals as mammography is at preventing breast cancer deaths.",
        comparison_table: {
          column_title_list: [
            <div>
              <center>
                Type of cancer screening<sup>1</sup>
              </center>
            </div>,
            <div>
              <center>Years of annual screens</center>
            </div>,
            <div>
              <center>
                Number of cancer deaths prevented<sup>2</sup>
              </center>
            </div>
          ],
          row_list: [
            {
              title: "Lung",
              cell_list: [3, 3.1]
            },
            {
              title: "Colorectal",
              cell_list: [5, 2.8]
            },
            {
              title: "Breast",
              cell_list: ["", ""]
            },
            {
              title: "Age 60-69",
              cell_list: [10, 2.6]
            },
            {
              title: "Age 50-59",
              cell_list: [10, 0.8]
            }
          ]
        },
        foot_note_list: [
          <div>
            <sup>1</sup>The procedures corresponding to lung, colorectal, and
            breast cancer screening here are: low-dose CT scan, sigmoidoscopy
            with fecal occult blood testing, and mammography respectively.
          </div>,
          <div>
            <sup>2</sup>This indicates the number of deaths prevented per 1000
            people screened.
          </div>
        ]
      },
      disclaimerPage: {
        title: "Disclaimer",
        content:
          "The information provided in this decision aid is for general health information only. The risk calculator can only give estimates of your risk based on the responses you provide. Even though we try to provide the best estimates available, the numbers may not be accurate for an individual person. There may be specific risk factors that are not measured.  Therefore, the information provided on this website is not to be used as a substitute for medical advice from a health professional. If you are concerned about lung cancer or your risk of getting lung cancer, please talk to your doctor or other health care provider. The Site contains links to other sites operated by third parties, and we do not guarantee the representation about any Third Party Site or the accuracy of its content."
      },
      howPage: {
        title: "Things you should know about lung cancer screening",
        banner_image_source: "/image/um-ct-shoot.jpg",
        banner_title: "Lung Cancer Screening",
        sample_list: [
          {
            title: "Q1. WHAT IS LUNG CANCER CT SCREENING?",
            content: (
              <div>
                <p>
                  People who take part in screening can lower their chances of dying from lung cancer.
                </p>
                <p>
                  Lung cancer screening uses low-dose computed tomography (LDCT)
                  i.e. a CT scan with a low dose of radiation, to find lung
                  nodules, some of which may be cancer.
                  A nodule is a round or oval shaped spot that may or may not require further testing.
                  People who take part in screening can lower their chances of dying from lung cancer.
                </p>
                <p>
                  In general, lung cancer screening is not a single test but a
                  process under the direction of your doctor(s) that includes
                  follow-up of any findings and return for screening every year
                  while you remain eligible.
                </p>
              </div>
            )
          },
          {
            title: "Q2. WHO IS ELIGIBLE FOR SCREENING?",
            content: (
              <div>
                <p>
                  Lung cancer screening is recommended for older individuals with a history of smoking. According to the 2021 United States Preventive Services Task Force, you qualify for lung cancer screening if you:
                </p>
                <ul>
                  <li>are between 50 and 80 years old, and</li>
                  <li>have smoked within the last 15 years, and</li>
                  <li>have smoked 20 pack-years or more.</li>
                </ul>
                <p>
                  
                  Pack-years are a way to measure your smoking history, taking into
                  account how long you have smoked, and how much you have smoked. For example,
                  20 pack-years equals: 
                  <ul>
                    <li>two packs a day (40 cigarettes) for the last 10 years,</li>
                    <li>one pack a day (20 cigarettes) for the last 20 years, or</li>
                    <li>one half pack a day (10 cigarettes) for 40 years.</li>
                  </ul>

                  Click{" "}
                  <Link
                    to={"/English/pack-year-calculator"}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HowPage"
                    data-event-action={"/English/pack-year-calculator"}
                  >
                    here
                  </Link>{" "}
                  to calculate how many pack years you have. 
                  Talk to your doctor about lung cancer screening so that together, you can decide what is best for you. 
                  Click{" "}
                  <Link
                    to={"/English/coverage"}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="CoveragePage"
                    data-event-action={"/English/coverage"}
                  >
                    here
                  </Link>{" "}
                  for more information on insurance coverage if you are eligible for lung cancer screening.  
                </p>

              </div>
            )
          },
          {
            title: "Q3. WHAT HAPPENS AT THE SCREENING?",
            content: (
              <div>
                <p>
                  The following description is adapted from the{" "}
                  <a
                    href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    American College of Radiology
                  </a>
                  :
                </p>
                <p>
                  Lung cancer screening involves getting a low-dose CT (or CAT) scan of your chest. 
                  The procedure usually takes about 15 minutes. It involves lying still and going 
                  through a doughnut-shaped machine while multiple images are taken using X-rays 
                  and a computer. You will usually be asked to hold your arms over your head. 
                  Then, while you hold your breath for 5 to 10 seconds, the table will move through 
                  the machine as the actual CT scan is done.
                </p>
              </div>
            )
          },
          {
            title: "Q4. WHAT HAPPENS AFTER MY FIRST SCREENING?",
            content: (
              <div>
                <p>
                  If a lung nodule is found on your CT scan, your doctor may
                  recommend a follow-up CT scan, usually 6 months later to check
                  that the nodule has not grown. A lung nodule is a round or oval
                  shaped spot. In the unlikely case that the
                  nodule does grow or may present a worry, your doctor may
                  recommend further testing using a{" "}
                  <a
                    href="https://medlineplus.gov/ency/article/007342.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PET scan
                  </a>{" "}
                  or a biopsy (taking out a small piece of the nodule).
                </p>
                <p>
                  However, since more than 95% of lung nodules found are not
                  cancer, you may only need to go back in one year as with other
                  annual health checks to continue the process of regular screening.
                  Remember, lung cancer screening is not a single test: it is a
                  process that must be done correctly under the direction of
                  your doctor(s).
                </p>
              </div>
            )
          },
          {
            title:
              "Q5. HOW MUCH RADIATION WILL I BE EXPOSED TO FROM THE CT SCAN FOR LUNG CANCER SCREENING?",
            content: (
              <div>
                <p>
                CT scanning for lung cancer screening uses up to 90% less radiation than 
                a conventional chest CT scan. The radiation dose is equal to about 6 months 
                of radiation from your every day environment. The additional risk of developing 
                fatal lung cancer from one adult exam is considered to be extremely low.
                </p>
              </div>
            )
          },
          {
            title: "Q6. WHERE CAN I GET SCREENED?",
            content: (
              <div>
                <p>
                  You should only be screened at an American College of Radiology accredited facility. You can
                  find one close to you by going{" "}
                  <a
                    href="http://www.acraccreditation.org/accredited-facility-search"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            )
          }
        ]
      },
      riskPage: {
        banner_image_source: "/image/gsh-sa-balloons.jpg",
        banner_title: "Lowering Your Risk of Lung Cancer",
        title:
          "THE BEST WAY TO LOWER YOUR CHANCES OF GETTING LUNG CANCER IS TO QUIT SMOKING.",

        description: (
          <p>
            While lung cancer screening can reduce your chances of dying from lung
            cancer, a <strong>far more</strong> effective means of reducing your
            chances of dying from lung cancer is to stop smoking if you
            currently smoke. This is difficult, but it can be done! To stop
            smoking, it's helpful to address:

            <div>
            <ul>
              <li>The nicotine cravings, as well as</li>
              <li>The deeply ingrained routines and habits of smoking.</li>
            </ul>
          </div>        
            <div>
              <br></br>
            Many people cite "stress" as a reason they do not choose to try and quit. 
              <ul>
                <li>Stress is a part of everyday life for most people.</li>
                <li>It's never too late to start learning about ways to manage stress.</li>
                <li>Talking with your doctor about your sources of stress and how to manage them is a good start.</li>
              </ul>
              <br></br>

            </div>
            There are many
            resources out there that can help you quit, and some will provide
            you FDA approved smoking cessation aids for free, or at a greatly
            reduced prices. Here are just a few:
          </p>
        ),
        state_resource_list: [
          { state: "Select a state...", links: [] },
          ,
          {
            state: "Alabama",
            links: [
              {
                label:
                  "Alabama Department of Public Health, Tobacco Prevention and Control",
                link: "http://www.adph.org/tobacco/Default.asp?id=779"
              }
            ]
          },

          {
            state: "Alaska",
            links: [
              {
                label: "Tobacco-Free Alaska",
                link:
                  "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx"
              }
            ]
          },

          {
            state: "Arizona",
            links: [
              {
                label: "ASHLine - Arizona's Smokers' Helpline",
                link: "https://ashline.org/"
              },
              {
                label: "Tobacco Free Arizona",
                link: "http://azdhs.gov/tobaccofreeaz/"
              }

              
            ]
          },

          {
            state: "Arkansas",
            links: [
              {
                label: "Arkansas Tobacco Cessation Resources",
                link:
                  "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources"
              },
              {
                label: "Stamp Out Smoking",
                link: "http://www.stampoutsmoking.com/get-help-to-quit/"
              }
            ]
          },

          {
            state: "California",
            links: [
              {
                label: "California Tobacco Control Program",
                link:
                  "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx"
              },
              {
                label: "California Smokers' Helpline",
                link: "https://www.nobutts.org/"
              }
            ]
          },

          {
            state: "Colorado",
            links: [
              {
                label: "Colorado Department of Public Health and Environment",
                link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco"
              },
              {
                label: "Tobacco Free CO",
                link: "http://www.tobaccofreeco.org/"
              }
            ]
          },

          {
            state: "Connecticut",
            links: [
              {
                label: "Connecticut Department of Public Health",
                link:
                  "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841"
              }
            ]
          },

          {
            state: "Delaware",
            links: [
              {
                label: "Delaware Tobacco Prevention and Control Program",
                link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html"
              }
            ]
          },

          {
            state: "Florida",
            links: [
              {
                label: "Tobacco Free Florida",
                link:
                  "http://www.floridahealth.gov/programs-and-services/prevention/tobacco-free-florida/index.html"
              }
            ]
          },

          {
            state: "Georgia",
            links: [
              {
                label: "Georgia Department of Public Health - Ready to Quit",
                link: "https://dph.georgia.gov/ready-quit"
              }
            ]
          },

          {
            state: "Hawaii",
            links: [
              {
                label: "Hawaii Department of Health, Tobacco Control",
                link: "http://health.hawaii.gov/tobacco/home/cessation/"
              },
              {
                label: "Hawaii Tobacco Quitline",
                link: "http://hawaiiquitline.org"
              }
            ]
          },

          {
            state: "Idaho",
            links: [
              {
                label: "Idaho Tobacco Prevention and Control Program",
                link:
                  "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx"
              },
              { label: "Project Filter", link: "http://projectfilter.org/" }
            ]
          },

          {
            state: "Illinois",
            links: [
              {
                label: "Illinois Tobacco Quitline",
                link: "http://quityes.org/home.html"
              }
            ]
          },

          {
            state: "Indiana",
            links: [
              {
                label: "Indiana State Department of Health",
                link: "http://www.in.gov/isdh/tpc/2353.htm"
              },
              {
                label: "Quit Now Indiana",
                link: "http://www.quitnowindiana.com/"
              }
            ]
          },

          {
            state: "Iowa",
            links: [
              {
                label: "Quitline Iowa",
                link: "https://iowa.quitlogix.org/"
              }
            ]
          },

          {
            state: "Kansas",
            links: [
              {
                label: "Kansas Department of Health and Environment",
                link: "http://www.kdheks.gov/tobacco/cessation.html"
              },
              { label: "KanQuit!", link: "http://www.ksquit.org/" }
            ]
          },

          {
            state: "Kentucky",
            links: [
              {
                label: "Kentucky Tobacco Prevention and Cessation Program",
                link: "http://chfs.ky.gov/dph/mch/hp/tobacco.htm"
              }
            ]
          },

          {
            state: "Louisiana",
            links: [
              {
                label: "Quit with us, Louisiana",
                link: "http://www.quitwithusla.org/"
              }
            ]
          },

          {
            state: "Maine",
            links: [
              {
                label: "Partnership for a Tobacco-Free Maine",
                link: "http://www.tobaccofreemaine.org/"
              }
            ]
          },

          {
            state: "Maryland",
            links: [
            { 
              label: "Smoking stops here - Maryland's quit resource", 
              link: "https://smokingstopshere.com/" 
            },
            { 
              label: "MDQuit", 
              link: "http://mdquit.org/" 
            }
              ]
          },

          {
            state: "Massachusetts",
            links: [
              {
                label: "Make smoking history",
                link: "http://makesmokinghistory.org/"
              }
            ]
          },

          {
            state: "Michigan",
            links: [
              {
                label: "Michigan Department of Community Health",
                link:
                  "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html"
              },
              {
                label: "University of Michigan Tobacco Treatment Programs",
                link:
                  "https://hr.umich.edu/benefits-wellness/health-well-being/mhealthy/faculty-staff-well-being/alcohol-tobacco-programs/tobacco-consultation-service"
              }
            ]
          },

          {
            state: "Minnesota",
            links: [
              {
                label: "Minnesota Department of Health",
                link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html"
              },
              {
                label: "QUITPLAN services. No judgments. Just help.",
                link: "https://www.quitplan.com/"
              }
            ]
          },

          {
            state: "Mississippi",
            links: [
              {
                label: "Mississippi Office of Tobacco Control",
                link:
                  "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community"
              },
              {
                label: "Mississippi Tobacco Quitline",
                link: "http://www.quitlinems.com/"
              }
            ]
          },

          {
            state: "Missouri",
            links: [
              {
                label: ">Missouri Department of Health and Senior Services",
                link:
                  "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php"
              }
            ]
          },
          {
            state: "Montana",
            links: [
              {
                label: "Montana Tobacco Use Prevention Program",
                link: "http://dphhs.mt.gov/publichealth/mtupp"
              },
              {
                label: "Montana Tobacco Quitline",
                link: "https://montana.quitlogix.org/"
              }
            ]
          },
          {
            state: "Nebraska",
            links: [
              {
                label: "Smoke Free Nebraska",
                link:
                  "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx"
              }
            ]
          },

          {
            state: "Nevada",
            links: [
              {
                label: "Nevada Tobacco and Control Program",
                link:
                  "http://dpbh.nv.gov/Programs/TPC/Tobacco_Prevention_and_Control_-_Home/"
              },
              {
                label: "Nevada Tobacco Prevention Coalition",
                link:
                  "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking"
              }
            ]
          },

          {
            state: "New Hampshire",
            links: [
              {
                label: "New Hampshire Tobacco Prevention and Control Program",
                link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm"
              },
              {
                label: "New Hampshire Tobacco Helpline",
                link: "http://trytostopnh.org/"
              }
            ]
          },
          {
            state: "New Jersey",
            links: [
              {
                label: "New Jersey Tobacco Control Program",
                link: "http://www.nj.gov/health/fhs/tobacco"
              },
              {
                label: "New Jersey Quitline",
                link: "http://www.njquitline.org/treatment.html"
              }
            ]
          },

          {
            state: "New Mexico",
            links: [
              {
                label: "TUPAC: Tobacco Use Prevention and Control",
                link: "http://nmtupac.com/#home"
              }
            ]
          },

          {
            state: "New York",
            links: [
              {
                label: "New York State Smokers' Quitline",
                link: "https://nysmokefree.com/"
              }
            ]
          },

          {
            state: "North Carolina",
            links: [
              {
                label: "QuitlineNC",
                link: "http://www.quitlinenc.com/"
              }
            ]
          },

          {
            state: "North Dakota",
            links: [
              {
                label: "North Dakota Tobacco Prevention and Control Program",
                link: "http://www.ndhealth.gov/tobacco/"
              },
              {
                label: "North Dakota Cessation/Quitting Programs",
                link: "http://www.ndhealth.gov/tobacco/cessation.htm"
              }
            ]
          },

          {
            state: "Ohio",
            links: [
              {
                label: "Ohio Department of Health Tobacco Program",
                link:
                  "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation"
              }
            ]
          },

          {
            state: "Oklahoma",
            links: [
              {
                label: "Oklahoma Tobacco Use Prevention and Reduction Programs",
                link:
                  "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html"
              }
            ]
          },

          {
            state: "Oregon",
            links: [
              {
                label: "Oregon Tobacco Prevention and Education Program",
                link:
                  "http://www.oregon.gov/oha/PH/PreventionWellness/TobaccoPrevention/GetHelpQuitting/Pages/oregonquitline.aspx"
              },
              {
                label: "Smokefree Oregon",
                link: "https://www.quitnow.net/oregon/"
              }
            ]
          },

          {
            state: "Pennsylvania",
            links: [
              {
                label: "Pennsylvania Tobacco Prevention and Control",
                link:
                  "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs"
              },
              {
                label: "PA Free Quitline",
                link:
                  "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/PA-Free-Quitline.aspx#.WbrmPdOGORt"
              }
            ]
          },

          {
            state: "Rhode Island",
            links: [
              {
                label: "Rhode Island Tobacco Control Program",
                link: "http://www.health.ri.gov/programs/tobaccocontrol/"
              },
              { label: "Quit Now RI", link: "http://www.quitnowri.com/" }
            ]
          },
          {
            state: "South Carolina",
            links: [
              {
                label: "South Carolina Tobacco Cessation",
                link: "http://www.scdhec.gov/Health/TobaccoCessation/"
              }
            ]
          },
          {
            state: "South Dakota",
            links: [
              {
                label: "South Dakota Tobacco Prevention and Control Program",
                link: "http://doh.sd.gov/prevention/tobacco/"
              },
              {
                label: "South Dakota QuitLine",
                link: "https://www.sdquitline.com/"
              }
            ]
          },
          {
            state: "Tennessee",
            links: [
              {
                label: "Tennessee Tobacco Quitline",
                link: "http://health.state.tn.us/tobaccoquitline.htm"
              }
            ]
          },
          {
            state: "Texas",
            links: [
              {
                label: "Texas Department of State Health Services",
                link: "https://www.dshs.state.tx.us/tobacco/links.shtm"
              },
              { label: "Tobacco is foul", link: "http://www.ducktexas.org/" }
            ]
          },
          {
            state: "Utah",
            links: [
              {
                label: "Utah Tobacco Prevention and Control Program",
                link: "http://www.tobaccofreeutah.org/"
              },
              {
                label: "Way to Quit",
                link: "http://waytoquit.org/"
              }
            ]
          },

          {
            state: "Vermont",
            links: [
              {
                label: "Vermont Department of Health",
                link:
                  "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx"
              },
              {
                label: "802 Quits: The Vermont Quit Smoking Resource",
                link: "http://802quits.org/"
              }
            ]
          },
          {
            state: "Virginia",
            links: [
              {
                label: "Virginia Tobacco Use Control Project",
                link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/"
              }
            ]
          },

          {
            state: "Washington",
            links: [
              {
                label:
                  "Washington State Department of Health Tobacco Resources",
                link:
                  "http://www.doh.wa.gov/ForPublicHealthandHealthcareProviders/PublicHealthSystemResourcesandServices/LocalHealthResourcesandTools/Tobacco"
              }
            ]
          },

          {
            state: "West Virginia",
            links: [
              {
                label: "West Virginia Tobacco Prevention Resources",
                link:
                  "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx"
              }
            ]
          },

          {
            state: "Wisconsin",
            links: [
              {
                label: "Wisconsin Tobacco Prevention and Control Program",
                link: "https://www.dhs.wisconsin.gov/tobacco/index.htm"
              },
              {
                label: "Wisconsin Tobacco Quitline",
                link: "http://www.ctri.wisc.edu/quitline.html"
              }
            ]
          },

          {
            state: "Wyoming",
            links: [
              {
                label:
                  "Wyoming Department of Health Tobacco Prevention Program",
                link:
                  "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/"
              }
            ]
          }
        ],
        resources_quit: [
          {
            link: "",
            label: "Call 1-800-QUIT-NOW (800-784-8669)"
          },
          {
            link: "http://smokefree.gov/",
            label: "Smokefree.gov"
          },
          {
            link: "http://betobaccofree.hhs.gov/quit-now/index.html",
            label: "BeTobaccoFree.gov"
          },
          {
            link:
              "http://www.cancer.org/healthy/stayawayfromtobacco/guidetoquittingsmoking/index",
            label: "American Cancer Society"
          }
        ],
        resources_instructions:
          "Choose a state from the drop down menu for resources that are specifically available there.",
        radon_instructions:
          "Radon is another preventable risk factor for lung cancer. Learn how you can reduce your risk at:",
        resources_radon: [
          {
            link:
              "https://www.epa.gov/sites/production/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf",
            label:
              "A Citizen's Guide to Radon compiled by the Environmental Protection Agency"
          },
          {
            link:
              "https://www.cdc.gov/radon/radon-health.html",
            label: "Centers for Disease Control and Prevention"
          }
        ]
      },
      packPage: {
        title: "Pack year calculator",
        instruction: {
          normal:
            "Pack-years are a way to measure your smoking history, taking into account how long you have smoked, and how much you have smoked. Currently, having 20 pack-years or more is one of the criteria that needs to be met to be recommended for lung cancer screening."
        },

        sub_title:
          "PLEASE ANSWER THESE TWO QUESTIONS TO WORK OUT YOUR PACK-YEARS.",
        submit_button_label: "Calculate",
        calculation_result_template:
          "Your smoking exposure is x_number pack-years.", // x_number will be replaced with calculation result, don't change it.
        pack_questions: {
          formYears: {
            label: "1. For how many years have you smoked?",
            placeholder: "how many years?"
          },
          formAmount: {
            label:
              "2. How many cigarettes per day have you usually smoked? There are 20 cigarettes in a pack.",
            placeholder: "cigarettes per day?"
          }
        }
      },
      calculatePage: {
        banner_image_source: "/image/areachart2.png",
        banner_title: "Your Lung Cancer Risk",
        title:
          "If you smoke, the best way to lower your risk of lung cancer is to quit.",

        instruction: {
          normal: (
            <div>
              <p>
                Fill in the information below to find out whether you are in the
                group where screening is recommended by the&nbsp;
                <a
                  target="_blank"
                  href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                  rel="noopener noreferrer"
                >
                  US Preventive Services Task Force
                </a>
                .&nbsp; The calculator will also indicate how much you stand to
                benefit from getting screened. This will help you better
                determine whether your potential benefit from screening
                outweighs the harms.
              </p>
            </div>
          ),
          strong_text_list: ["* INDICATES REQUIRED FIELDS"]
        },
        submit_button_label: "Submit",
        default_select_label: " -- select -- ",
        error_input_guidance:
          "Hi, one or more of the things you filled in may have a typo. Please scroll down and check.",
        tall_question: (
          <div>
            9. How tall are you? &nbsp;
            <a
              target="_blank"
              href="https://www.google.com/search?q=convert+centimeters+to+feet+and+inches&ie=utf-8&oe=utf-8"
              rel="noopener noreferrer"
            >
              Help me convert centimeters to feet and inches.
            </a>
          </div>
        ),
        risk_questions: {
          formOld: {
            label: "1. How old are you?* ",
            placeholder: "",
            help_text:
              "Question 1 is a required field, and the valid age range for this calculator is from 18 to 100 years old."
          },
          formCurrentStatus: {
            label: "2. What is your current smoking status?*",
            placeholder: "",
            options: [
              { label: "Smoker", value: 1 },
              { label: "Former Smoker", value: 0 },
              { label: "Never Smoker", value: 2 }
            ],
            help_text: "Question 2 is a required field."
          },
          formAgeLastSmoke: {
            label: "2.1. At what age did you quit smoking for the last time?*",
            placeholder: "",
            options: [],
            help_text:
              "You have indicated that you are former smoker. Please ensure that you have filled in a number that is less than your current age in Question 1."
          },
          formYearsSmoking: {
            label: "3. For how many years total have you smoked cigarettes?*",
            placeholder: "",
            options: [],
            help_text:
              "Question 3 is a required field. Please ensure that this value is less than your current age, and that it is a number."
          },
          formCigarettesPerDay: {
            label:
              "4. On average, how many cigarettes do/did you smoke per day?* There are 20 cigarettes in a pack.",
            placeholder: "",
            options: [],
            help_text:
              "Question 4 is a required field. Please ensure that you have filled in a number between 0 and 200."
          },
          formGender: {
            label: "5. What is your gender?",
            placeholder: "",
            options: [
              { label: "Male", value: "male" },
              { label: "Female", value: "female" }
            ]
          },
          formInsurance: {
            label: <div>6. Are you covered by Medicare? 🏥</div>,
            placeholder: "",
            options: [
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
              { label: "Don't know/Not applicable", value: "unknown" }
            ]
          },
          formHighestGradeCompleted: {
            label:
              "7. What is the highest grade or year of school you completed? ",
            placeholder: "",
            options: [
              { label: "Less than high school", value: 0 },
              { label: "High school graduate", value: 1 },
              { label: "Some training after high school", value: 2 },
              { label: "Some college", value: 3 },
              { label: "College graduate", value: 4 },
              { label: "Postgraduate or professional degree", value: 5 }
            ]
          },
          formRaceEthnicity: {
            label: "8. How would you describe your race/ ethnicity?",
            placeholder: "",
            options: [
              { label: "White", value: 0 },
              { label: "Black", value: 1 },
              { label: "Hispanic", value: 2 },
              { label: "Asian", value: 3 },
              { label: "American Indian or Alaskan Native", value: 4 },
              {
                label: "Native Hawaiian or Pacific Islanders",
                value: 5
              }
            ]
          },
          formTallFeet: {
            label: "",
            placeholder: "",
            right_side_label: "ft.",
            options: [
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 }
            ]
          },
          formTallInch: {
            label: "",
            placeholder: "",
            right_side_label: "in.",
            options: [
              { label: "0", value: 0 },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 },
              { label: "10", value: 10 },
              { label: "11", value: 11 }
            ]
          },
          formWeight: {
            label: (
              <div>
                10. How much do you weigh? (lbs.) &nbsp;
                <a
                  target="_blank"
                  href="https://www.google.com/search?q=convert+kilograms+to+pounds&ie=utf-8&oe=utf-8"
                  rel="noopener noreferrer"
                >
                  Help me convert kilograms to pounds.
                </a>
              </div>
            ),
            placeholder: "",
            options: [],
            help_text:
              "Please ensure that you have entered a number between 45 and 1000 in Question 9. This is not a required field. Leaving it empty will assign a default value to our calculation."
          },
          formDoctorToldCancer: {
            label:
              "11. Have you ever been told by a doctor that you have cancer?",
            placeholder: "",
            options: [
              { label: "Yes", value: 1 },
              { label: "No", value: 0 }
            ]
          },
          formFamilyHistory: {
            label: "12. Does your family have a history of lung cancer?",
            placeholder: "",
            options: [
              { label: "Yes", value: 1 },
              { label: "No", value: 0 }
            ]
          },
          formDoctorToldCOPD: {
            label:
              "13. Have you ever been told by your doctor that you have chronic pulmonary disease also known as COPD (chronic bronchitis or emphysema)? ",
            options: [
              { label: "Yes", value: 1 },
              { label: "No", value: 0 }
            ]
          }
        }
      },
      feedbackPage: {
        title: "We appreciate your feedback!",
        submitted_message: "Thank you!",
        default_select_label: " -- select -- ",
        submit_button_label: "Submit",
        feedback_questions: {
          formGender: {
            label: "I am",
            placeholder: "",
            options: [
              { label: "Female", value: 0 },
              { label: "Male", value: 1 }
            ]
          },
          formSmokeStatus: {
            label: "I...",
            placeholder: "",
            options: [
              { label: "am a smoker.", value: 1 },
              { label: "used to smoke.", value: 0 },
              { label: "have never smoked.", value: 2 }
            ]
          },
          formAge: {
            label: "How old are you?",
            placeholder: "50",
            options: []
          },
          formCalculatorResult: {
            label: "According to what you entered in the calculator, you were:",
            placeholder: "",
            options: [
              {
                label: "Eligible to be screened",
                value: "Eligible to be screened"
              },
              {
                label: "Not eligible to be screened",
                value: "Not eligible to be screened"
              },
              {
                label: "Risk was too low to be calculated accurately",
                value: "Risk was too low to be calculated accurately"
              }
            ]
          },
          formInformationReceiver: {
            label: "I am looking for information for",
            placeholder: "",
            options: [
              { label: "Myself", value: "Myself" },
              { label: "My partner", value: "My partner" },
              { label: "My family member(s)", value: "My family member(s)" },
              { label: "My friend(s)", value: "My friend(s)" },
              { label: "My patient(s)", value: "My patient(s)" }
            ]
          },
          formFindAllInformation: {
            label:
              "I found all the information I was looking for about lung cancer screening.",
            placeholder: "",
            options: [
              { label: "Strongly Disagree", value: -2 },
              { label: "Disagree", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "Agree", value: 1 },
              { label: "Strongly Agree", value: 2 }
            ]
          },
          formHelpConversationDoctor: {
            label:
              "The information I found helped me talk to my doctor about lung cancer screening.",
            placeholder: "",
            options: [
              { label: "Strongly Disagree", value: -2 },
              { label: "Disagree", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "Agree", value: 1 },
              { label: "Strongly Agree", value: 2 }
            ]
          },
          formHelpDecision: {
            label:
              "The website helped me in my decision about lung cancer screening.",
            placeholder: "",
            options: [
              { label: "Strongly Disagree", value: -2 },
              { label: "Disagree", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "Agree", value: 1 },
              { label: "Strongly Agree", value: 2 }
            ]
          },
          formInterestReason: {
            label: "I am interested in lung cancer screening because...",
            placeholder: "E.g., I have a family history of cancer."
          },
          formComment: {
            label:
              "Please feel free to submit any other comments that you think will help us improve the website:",
            placeholder: ""
          }
        }
      },
      resultPage: {
        print_button_label: "Print",
        title: "Lung Cancer Screening:",
        sub_title: "Should I do it?",
        risk_spectrum: {
          risk_statement: "Your lung cancer risk:",
          low_risk_label: <span>&#60; 1%: low risk</span>,
          intermediate_risk_label: <span>1-2%: intermediate risk</span>,
          high_risk_label: <span>&#62; 2%: high risk</span>
        },
        risk_confirmation:{
          label: "According to the chart, my risk of developing lung cancer is:",
          placeholder: "",
          options: [
            { label: "High", value: "High" },
            { label: "Intermediate", value: "Intermediate" },
            { label: "Low", value: "Low" },
            { label: "Don't know", value: "Don't know" }
          ]
        },
        comparison_chart_info_list:[
          {
            chartTitle:"Not screened",
            legendTitle: {
              "1000": "Out of 1000 people like you",
              "100": "Out of 100 people like you"
            },
            legendMap:{
              "die": "die from lung cancer",
              "not die": "did not die from lung cancer"
            }
          },
          {
            chartTitle:"Screened",
            legendTitle: {
              "1000": "Out of 1000 people like you",
              "100": "Out of 100 people like you"
            },
            legendMap:{
              "die": "die from lung cancer",
              "fewer die": {
                "full": "fewer died from lung cancer due to screening",
                "list": ["fewer died from lung cancer due ",  "to screening"]
              },
              "not die": "did not die from lung cancer"
            }
          },
        ],
        benefit_confirmation:{
          //label: "After seeing the chart, I consider my benefits of getting screened being:",
          label: "Quick check-in: based on the information above, I consider the benefits of screening, relative to my risk of developing lung cancer in the next 6 years, to be ",
          placeholder: "",
          options: [
            { label: "High enough", value: "High enough" },
            //{ label: "Intermediate", value: "Intermediate" },
            { label: "Too low", value: "Too low" },
            { label: "Don't know", value: "Don't know" }
          ]
        },
        risk_section: {
          title: "Risks",
          statement_list: [
            "90 in 1000 people who were screened found a lung nodule that was not cancer.",
            "13 in 1000 had an invasive procedure, such as biopsy or surgery, due to a lung nodule that was not cancer.",
            "Fewer than 1 in 1000 had a major complication from invasive procedures.",
            "Of the lung cancers found by screening, fewer than 1 in 10 would have never harmed you. This may lead to unnecessary treatment and complications."
          ]
        },
        benefit_section: {
          title: "Benefits",
          statement_end:
            "fewer people like you will die from lung cancer among those who were screened compared to those who were not screened."
        },
        instructions: {
          beforeSubmission: [
            "By now, you should have a sense of what screening is and what it can and can't do. It will be helpful for your doctor to understand how you feel about the pros and cons of screening. Please check which sentence below best describes your answer to the following question. There is no right or wrong answer!",
            "IN YOUR VIEW, DOES YOUR PERSONAL BENEFIT FROM SCREENING JUSTIFY GETTING SCREENED? CHOOSE ONE OPTION BELOW."
          ],
          afterSubmission: [
            "Remember to talk to your doctor about your view on lung cancer screening!"
          ]
        },
        default_select_label: " -- select -- ",
        submit_button_label: "Submit",
        click_for_pdf_msg:
          "Click on the image for a printer-friendly version of the results.",
        getEligibilityStatement: function(sex, risk, eligibility, insurance, old, packYears, neverSmoked=false) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown
          // Old = -1;
          // yearsSmoking = -1;
          // cigarettesPerDay = -1;

          var result = "Default Eligibility";

          if(neverSmoked){
            result = <div>Lung cancer CT screening is not recommended for people who have never smoked.</div>;
            return result;
          }

          switch (eligibility) {
            case "yes":
              result = "";
      
                  if (insurance == "yes") {
                    // M
                    result = (
                      <div>
                        Given your age and smoking history, you are{" "}
                        <strong>eligible</strong> for screening according to the US
                        Preventive Services Task Force criteria.
                      </div>
                    );
                  } else {
                    if( old > 77){
                      result = (
                        <div>
                          Given your age and smoking history, you are{" "}
                          <strong>eligible</strong> for screening according to the US
                          Preventive Services Task Force criteria. However, Medicare
                          only covers lung cancer screening up to age 77. Please see
                          our &nbsp;
                          <Link
                            to={"/English/coverage"}
                            style={{ width: "100%" }}
                            data-on="click"
                            data-event-category="CoveragePage"
                            data-event-action={"/English/coverage"}
                          >
                            insurance coverage page&nbsp;
                          </Link>{" "}
                          or contact your insurance provider to see if there are other
                          options that might cover the cost of lung cancer screening.
                        </div>
                      );
                    }
                    else{
                      result = (
                        <div>
                          Given your age and smoking history, you are{" "}
                          <strong>eligible</strong> for screening according to the US
                          Preventive Services Task Force criteria.
                        </div>
                      );
                    }
                    
                  }
                  break;
                case "no":
                  result = (<p>
                    Given your age and smoking history, you are{" "}
                    <strong>not eligible*</strong> for screening according to the
                    US Preventive Services Task Force criteria. 
                  </p>
                  );
                  break;
                default:
                  result = "";
                  break;
              }
      
              return result;
            },
        getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // medicare: yes, no, unknown
          var result = "Default Chance";
  
          console.log(`getChanceStatement(), old: ${old}, packYears: ${packYears}`);
  
          let  tailorMessage = "";
  
          let oldInRange = old >= 50 && old <=54;
          let packYearsInRange = packYears >= 20 && packYears <=29;
  
          if(oldInRange && packYearsInRange){
            tailorMessage = `As you are 50-54 years old and have a smoking history of 20-29 pack-years, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
          }
          else if(!oldInRange && packYearsInRange){
            tailorMessage = `As you have a smoking history of 20-29 pack-years, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
          }
          else if(oldInRange && !packYearsInRange){
            tailorMessage = `As you are 50-54 years old, you are newly eligible for lung cancer screening under the most recent US Preventive Services Task Force recommendations. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.`;
          }
  
          if (risk == 0 ) {
            // smoker or not
            result = (
              <div>
                The chance of you developing lung cancer in the next 6 years is
                too low to be measured accurately. Talk to your doctor about
                screening if you are concerned about your risk for lung cancer
                as they will understand your situation best.
              </div>
            );
          } 
          else if (eligibility == "no") {
            if(risk >= 1.5){
              result = (<Fragment>
                <p>
                  *However, the chance of you developing lung cancer in the next
                  6 years is {risk}%, which is where we believe the benefits of screening are large enough to consider
                  CT screening as an option. You should consider talking to your
                  doctor about whether lung cancer screening might still be a
                  good choice for you.
                  
                </p>
                </Fragment>
              );
            }
            else{
              result = (
                <div>
                  The chance of you developing lung cancer in the next 6 years
                  is {risk}%. Talk to your doctor about the option to screen or
                  not to screen as they will understand your situation best.
                </div>
              );
            }
            
          } else if (eligibility == "yes")  {
            if(risk < 1.5){
              result = (<Fragment>
                <p>
                  {tailorMessage}
                </p>
                <p>
                  *However, the chance of you developing lung cancer in the next
                  6 years is {risk}%, which is where we believe the benefits of screening are not large enough to consider
                  CT screening as an option. You should consider talking to your
                  doctor about whether lung cancer screening might still be a
                  good choice for you.
                  
                </p>
  
                </Fragment>
              );
            }
            else{
              result = (
                <div>
                  <p>
                  The chance of you developing lung cancer in the next 6 years
                  is {risk}%. Talk to your doctor about the option to screen or
                  not to screen as they will understand your situation best.
                  </p>          
                  <p>
                    {tailorMessage}
                </p>
                </div>
              );
            }
          }
          return result;
        },
        getExplanationStatement(sex, risk, eligibility, insurance) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown
          var result = "Default Explanation";

          // Math.round()

          // deal with it.

          // method 2: encapsulate into a function in  MyCalcuataion
          result = (
            <div>
              Compared to other people like you, there will be{" "}
              {MyCalculation.calculateSavedByScreeningFromRisk(risk) +
                " "}
              fewer deaths out of 1000 in the next 6 years if you get screened.
            </div>
          );

          // method 1: calculate directly
          /*
          result = (
            <div>
              Compared to other people like you, there will be{" "}
              {Math.round(Math.round(risk * 10) - Math.round(risk * 10 * 0.8)) +
                " "}
              fewer deaths out of 1000 in the next 6 years if you get screened.
            </div>
          );
          */

          return result;
        },
        getBenefitTitle(sex, risk, eligibility, insurance) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown

          // we only need risk

          var result = "Default Benefit";

          result =
            MyCalculation.calculateSavedByScreeningFromRisk(risk)  +
            " in 1000";

          return result;
        },
        reflection_questions: {
          formPersonalBenefit: {
            label: "",
            placeholder: "",
            options: [
              {
                label:
                  "Yes. My benefit from screening is enough to justify getting screened.",
                value:
                  "Yes. My benefit from screening is enough to justify getting screened."
              },
              {
                label:
                  "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure.",
                value:
                  "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure."
              },
              {
                label:
                  "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened.",
                value:
                  "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened."
              },
              {
                label:
                  "No. My benefit from screening is too small to justify getting screened.",
                value:
                  "No. My benefit from screening is too small to justify getting screened."
              }
            ]
          },
          formRecommended: {
            label: "I was:",
            placeholder: "",
            options: [
              {
                label: "Recommended to be screened",
                value: "Recommended to be screened"
              },
              {
                label: "Not recommended to be screened",
                value: "Not recommended to be screened"
              }
            ]
          },
          formRisk: {
            label: "My risk was",
            placeholder: "1%"
          },
          formReason: {
            label: "My reasons are:",
            placeholder: ""
          }
        }
      },
      coveragePage: {
        banner_image_source: "/image/bannerImage_coveragePage.png",
        banner_title: "Insurance coverage of lung cancer screening",
        title:
          "Click on the option that applies to you to see if lung cancer screening is covered by your health insurance.",
        //content: "The following information is from the American Lung Association.",
        sub_title: "Note: If you are 50-54 years old and/or have a smoking history of 20-29 pack-years, you are newly eligible for lung cancer screening. You may not be covered by insurance yet, so please talk to your insurance provider for more information about when you will be covered.",

        condition_list: [
          {
            title: "Employer-sponsored plan",
            introtext:
              "This is insurance that is provided for you through your employer, union or other professional association. You are eligible to be covered for lung cancer screening if you:",
            criteria_list: [
              "are between 55 and 80 years old, and",
              <div>
                have a smoking history of 30 pack-years (Click&nbsp;
                <Link
                  to={"/English/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/English/pack-year-calculator"}
                >
                  here&nbsp;
                </Link>
                to work out your pack-years)
              </div>,
              "have smoked within the last 15 years"
            ],

            note:
              "If your plan is 'grandfathered' under the Affordable Care Act, it is not required to cover lung cancer screening. Check the documents of your plan and verify with your insurer about this.",
            question_answer_list: [
              {
                question: "Should there be a copay?",
                answer:
                  "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
              },
              {
                question: "Will there be other costs?",
                answer:
                  "There might be. Be sure to check with your insurance company what they are covering for the procedure."
              }
            ]
          },
          {
            title: "State insurance marketplace plan",
            introtext:
              "This is a plan that you enrolled in via healthcare.gov or a marketplace/exchange website set up by your state. You are eligible to be covered for lung cancer screening if you:",
            criteria_list: [
              "are between 55 and 80 years old, and",
              <div>
                have a smoking history of 30 pack-years (Click&nbsp;
                <Link
                  to={"/English/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/English/pack-year-calculator"}
                >
                  here
                </Link>{" "}
                to work out your pack-years)
              </div>,
              "have smoked within the last 15 years"
            ],
            question_answer_list: [
              {
                question: "Should there be a copay?",
                answer:
                  "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
              },
              {
                question: "Will there be other costs?",
                answer:
                  "There might be. Be sure to check with your insurance company what they are covering for the procedure."
              }
            ]
          },
          {
            title: "Medicare",
            introtext:
              "This is insurance provided by the national government for individuals over age 65 or with disabilities. You are eligible to be covered for lung cancer screening if you:",
            criteria_list: [
              "are between 55 and 77 years old, and",
              <div>
                have a smoking history of 30 pack-years (Click&nbsp;
                <Link
                  to={"/English/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/English/pack-year-calculator"}
                >
                  here
                </Link>{" "}
                to work out your pack-years)
              </div>,
              "have smoked within the last 15 years",
              "have no signs or symptoms (e.g., pain, weight loss, persistent coughing) of lung cancer"
            ],
            question_answer_list: [
              {
                question: "Should there be a copay?",
                answer:
                  "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
              },
              {
                question: "Will there be other costs?",
                answer:
                  "There might be. Be sure to check with your insurance company what they are covering for the procedure."
              }
            ]
          },
          {
            title: "Medicaid",
            introtext:
              "This is insurance provided by the national and state government for individuals with limited income. Coverage of lung cancer screening in Medicaid varies by state. We suggest that you contact your Medicaid provider and check if your plan covers lung cancer screening.",
            criteria_list: [],
            note: "",
            question_answer_list: []
          },
          {
            title: "Individual plan",
            introtext:
              "This is insurance that you buy directly from an insurer. You are eligible to be covered for lung cancer screening if you:",
            criteria_list: [
              "are between 55 and 80 years old and",
              <div>
                have a smoking history of 30 pack-years (Click&nbsp;
                <Link
                  to={"/English/pack-year-calculator"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/English/pack-year-calculator"}
                >
                  here
                </Link>{" "}
                to work out your pack-years)
              </div>,
              "have smoked within the last 15 years"
            ],

            note:
              "If your plan is 'grandfathered' under the Affordable Care Act, it is not required to cover lung cancer screening. Check the documents of your plan and verify with your insurer about this.",
            question_answer_list: [
              {
                question: "Should there be a copay?",
                answer:
                  "No. You should not be charged a copay, a co-insurance or deductible for screening if you go to a provider who is in your network. "
              },
              {
                question: "Will there be other costs?",
                answer:
                  "There might be. Be sure to check with your insurance company what they are covering for the procedure."
              }
            ]
          }
        ],
        citation_text: (
          <div>
            <p>
              Click&nbsp;
              <a
                target="_blank"
                href="https://www.lung.org/lung-health-diseases/lung-disease-lookup/lung-cancer/saved-by-the-scan/resources/is-lung-cancer-screening-right"
                rel="noopener noreferrer"
              >
                here&nbsp; 
              </a>
              for the most up-to-date insurance coverage information.
            </p>
          </div>
        )
      },
      savedPage: {
        title: "Here are the materials you saved",
        item_unit: "snippet(s)",
        print_button_label: "Print"
      },
      publicationPage: {
        title: "Research Publications",
        publication_list: [
          {
            category: "conference",
            text: (
              <span>
                Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza
                (2019). Designing a Web-based Decision Aid for Individuals to
                Consider Lung Cancer Screening.{" "}
                <i>
                  13th EAI International Conference on Pervasive Computing
                  Technologies for Healthcare (PervasiveHealth)
                </i>
                , Trento, Italy, May 20-23. pp 51-60
              </span>
            ),
            year: 2019,
            description:
              "We conducted participatory design workshops and focus groups to provide design suggestions for decision aids and to guide the development of shouldiscreen.com.",
            links: [
              {
                label: "Link",
                link: "https://doi.org/10.1145/3329189.3329210"
              }
            ]
          },
          {
            category: "journal",
            text: (
              <span>
                Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
                Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
                Evaluation of a Personalized, Web-Based Decision Aid for Lung
                Cancer Screening.{" "}
                <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
                49, Issue 6, Pages e125–e129
              </span>
            ),
            year: 2015,
            description:
              "We tested whether shouldiscreen.com helped improve people's knowledge of lung cancer risks and lung cancer screening, and decreased their decisional conflict.",
            links: [
              {
                label: "Link",
                link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/"
              }
            ]
          },
          {
            category: "journal",
            text: (
              <span>
                Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
                Mindy West, Douglas Arenberg, and Rafael Meza (2014).
                Development and Validation of a Personalized, Web-Based Decision
                Aid for Lung Cancer Screening Using Mixed Methods: A Study
                Protocol. <i>JMIR Research Protocol</i>
              </span>
            ),
            year: 2014,
            description:
              "This is a protocol that describes the various phases of research plans and development for shouldiscreen.com.",
            links: [
              {
                label: "Link",
                link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/"
              }
            ]
          }
        ]
      },
      teamPage: {
        title: "The developers of shouldiscreen.com",

        investigator_title: "PRINCIPAL INVESTIGATORS",

        investigator_list: [
          {
            name: "Rafael Meza, PhD",
            affiliation: [
              "Department of Epidemiology, School of Public Health, University of Michigan"
            ],
            link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html"
          },
          {
            name: "Douglas Arenberg, MD",
            affiliation: [
              "Department of Internal Medicine, University of Michigan"
            ],
            link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md"
          },
          {
            name: "Lisa Lau, PhD, MPH",
            affiliation: [
              "Department of Epidemiology, School of Public Health, University of Michigan"
            ],
            link: ""
          },
          {
            name: "Tanner Caverly, MD, MPH ",
            affiliation: [
              "Center for Clinical Management Research, Veterans Affairs, Ann Arbor Healthcare Systems; Department of Internal Medicine, University of Michigan"
            ],
            link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph"
          }
        ],

        research_development_title: "RESEARCH AND DEVELOPMENT TEAM",

        research_development_list: [
          {
            name: "Pianpian Cao, MPH",
            affiliation: [
              "Department of Epidemiology, School of Public Health, University of Michigan"
            ],
            link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html"
          },
          {
            name: "Sarah Cherng, PhD, MPH",
            affiliation: [
              "Department of Epidemiology, School of Public Health, University of Michigan"
            ],
            link: "http://www.sarahcherng.com/"
          },
          {
            name: "Pei-Yao Hung, MSI",
            affiliation: ["School of Information, University of Michigan"],
            link: "https://peiyaoh.github.io/"
          },
          {
            name: "Mindy West, MPH",
            affiliation: [
              "Department of Epidemiology, School of Public Health, University of Michigan"
            ],
            link: ""
          }
        ],

        press_title: "PRESS RELEASES",

        press_resources_list: [
          {
            link:
              "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
            label:
              "Tool helps patients decide if lung cancer screening is warranted",
            source_label: "Health Canal"
          },
          {
            link:
              "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
            label:
              "Web module helps patients decide about lung cancer screening",
            source_label: "Reuters"
          },
          {
            link:
              "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
            label:
              "On Medicare and assessing the value of lung cancer screening",
            source_label: "New York Times"
          },
          {
            link:
              "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
            label:
              "Tool helps patients, providers decide if lung cancer screening is warranted",
            source_label: "University of Michigan News"
           }
        ],
        founding_title: "FUNDING SUPPORT",
        founding_content:
          "The development of this tool was supported by the Elizabeth A Crary Fund of the University of Michigan Comprehensive Cancer Center (2014-2015), and the University of Michigan Rogel Cancer Center, Cancer Control and Population Sciences Research Program: Outreach and Health Disparities Grant."
      },
      historyPage: {
        title: "The development history of shouldiscreen.com",
        description: <Fragment>
          <p>Shouldiscreen.com is a lung cancer screening decision aid that is designed, evaluated, and re-designed continually based on existing literature, multidisciplinary research effort, health experts' suggestions, and feedback from users.</p>
          <p>Our goal is to continually improve it by keeping it up to date with the current understanding of lung cancer screening and refine it with human-centered methodology so that people can easily obtain a better understanding of relevant scientific knowledge and best practices.</p>
          <p>Here we outline a list of milestones and changes in the development of shouldicreen.com.</p>
        </Fragment>,
        eventList:[
          // marker_color:  color from bulma.css: is-primary (green), is-link, is-info, is-success, is-warning, is-danger
          // markder_type: "", is-image, is-icon
          {
            label: "June 2014",
            description: "First version of shouldiscreen created for feedback from potential users and health risk communication experts.",
            timestamp: "2014-06-01T00:00:00",
            marker_color: "is-warning",
            marker_type: "is-icon",
            // fontawesome
            marker_icon_type: "fas fa-play"
          },
          {
            label: "January 2015",
            description: "Version 1 of shouldiscreen.com officially deployed",
            timestamp: "2015-01-01T00:00:00",
            marker_color: "is-success",
            marker_type: "is-icon",
            // fontawesome
            marker_icon_type: "fa fa-flag"
          },
          {
            label: "September 2017",
            description: "Enable bilingual support with Spanish content.",
            timestamp: "2017-09-01T00:00:00",
            marker_color: "is-danger",
            marker_type: "is-icon",
            // fontawesome
            marker_icon_type: "fa fa-book"
          },
          {
            label: "July 2018",
            description: <Fragment>Added insurance coverage based on observations and feedback from participatory design and focus group studies (see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2018-07-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "September 2018",
            description: <Fragment>Added a save-for-later feature for people to collect relevant content based on a conversation with clinicians who conduct shared decision-making with patients.</Fragment>,
            timestamp: "2018-09-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "September 2018",
            description: <Fragment>Added a pop-up menu for people to provide feedback (helpful/not helpful) on content.</Fragment>,
            timestamp: "2018-09-15T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "December 2019",
            description: <Fragment>Added a question-based navigation menu based on observations from participatory design and focus group studies (see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2019-12-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "May 2019",
            description: <Fragment>Presented our participatory design and focus group research on the design of shouldiscreen.com at the <i>13th EAI International Conference on Pervasive Computing Technologies for Healthcare</i> (PervasiveHealth 2019)(see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2019-05-21T00:00:00",
            marker_color: "is-warning"
          },
          {
            label: "December 2019",
            description: <Fragment>Updated screening eligibility criteria with Medicare.</Fragment>,
            timestamp: "2019-12-12T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "January 2020",
            description: <Fragment>Enabled tailoring of messages based on calculated lung cancer risk and biographical information.</Fragment>,
            timestamp: "2020-01-01T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "August 2020",
            description: <Fragment>Added multiple visualization options to help people understand screening benefits and risks.</Fragment>,
            timestamp: "2020-08-22T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "November 2020",
            description: <Fragment>Revised content to reflect findings in USPSTF evidence review (2020) with regards to harms from false positives when using LungRADS (see <a
              target="_blank"
              href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2020-11-21T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "March 2021",
            description: <Fragment>Revised content to reflect USPSTF recommendations (2021) to lower age and pack-year thresholds for lung cancer screening (see <a
              target="_blank"
              href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/lung-cancer-screening"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2021-03-09T00:00:00",
            marker_color: "is-danger",
            marker_type: "is-icon",
            // fontawesome
            marker_icon_type: "fas fa-info-circle"
          },
          /*
          {
            label: "February 2016",
            description: "Timeline content - Can include any HTML element",
            timestamp: "2016-02-01T00:00:00",
            marker_color: "is-warning",
            marker_type: "is-image",
            marker_image_size: "is-32x32",
            marker_image_source: "https://bulma.io/images/placeholders/32x32.png"
          },
          */
          
        ]
      },      
      noPage: {
        title: "Should I Screen: not found",
        not_found_message:
          "You have reached this place by accident, or there is an issue on our site. Please click the following link to go back to the homepage.",
        take_me_home_label: "Take me to the home page.",
        take_me_home_link: "/English/home"
      },
      footerComponent: {
        development_team_content: (
          <p>
            Created by &nbsp;
            <Link
              to={"/English/development-team"}
              className="my-link"
              data-on="click"
              data-event-category="Navigation"
              data-event-action={"team"}
            >
              University of Michigan
            </Link>
            &nbsp; using the risk prediction model developed by Tammemägi et al.
            (2013).&nbsp;
            <a
              target="_blank"
              href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
              rel="noopener noreferrer"
              className="my-link"
            >
              Selection criteria for lung-cancer screening
            </a>
            .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
            2013.
          </p>
        ),
        basis_content: (
          <p>
            See{" "}
            <Link
              to={"/English/publications"}
              data-on="click"
              data-event-category="Navigation"
              data-event-action={"publications"}
              className="my-link"
            >
              publications
            </Link>{" "}
            for peer-reviewed articles regarding the design, development, and
            validation of this decision aid.
          </p>
        ),

        feedback_content: (
          <p>
            Your feedback is valuable to us. Please&nbsp;
            <Link to={"/English/feedback"} className="my-link">fill out this survey</Link>
            &nbsp;to help us improve the website.&nbsp;
          </p>
        ),

        disclaimer_content: (
          <p>
            <Link to={"/English/disclaimer"} className="my-link">Disclaimer</Link>. &nbsp;
            <em>
              Written content of this website are shared under &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
                className="my-link"
              >
                CC BY-NC-SA 4.0
              </a>
              .&nbsp;
            </em>
            All images on this website are copyrighted material and all rights
            are reserved unless otherwise referenced on the page.
          </p>
        )
      },
      lastUpdateComponent: {
        message_head: "This page was last updated on ",
        pointer_to_history: <Fragment>
          See <Link
                  to={"/English/development-history"}
                  data-on="click"
                  data-event-category="UpdateComponent"
                  data-event-action={"/English/development-history"}
                  className="my-link"
                >
                  the history of changes
                </Link>.
        </Fragment>
      },
      headerComponent: {
        title: "About Lung Cancer & Screening",
        menu_title: " Menu",
        route_in_menu_limit: 8, //9
        route_order_list: [
          {
            route: "/English/home",
            label: "Home",
            title: "Should I Screen: Lung cancer screening decision aid",
            page: "HomePage",
            description: "Lung cancer screening uses low-dose computed tomography (LDCT) to find lung cancer at an early stage. Here is information provided by doctors to help you decide."
          },
          {
            route: "/English/how-is-screening-done",
            label: "What is lung cancer screening",
            title: "What is lung cancer screening",
            page: "HowPage"
          },
          {
            route: "/English/coverage",
            label: "Insurance Coverage",
            title: "Insurance coverage of lung cancer screening",
            page: "CoveragePage"
          },
          {
            route: "/English/benefits-and-harms-screening",
            label: "Benefits and harms of screening",
            title: "Benefits and harms of lung cancer screening",
            page: "BenefitHarmPage"
          },
          /*
          {
            route: "/English/compare-with-other-screening-tests",
            label: "Compared to other tests",
            title:
              "Comparison of lung cancer screening to other types of common cancer screening",
            page: "ComparePage"
          },
          */
          {
            route: "/English/causes-of-lung-cancer",
            label: "Causes of lung cancer",
            title: "Causes of lung cancer",
            page: "CausePage"
          },
          {
            route: "/English/pack-year-calculator",
            label: "Pack year calculator",
            title: "Calculate pack years",
            page: "PackPage"
          },
          {
            route: "/English/risk-reduction-lung-cancer",
            label: "Lowering risk of lung cancer",
            title: "Lowering risk of lung cancer",
            page: "RiskPage"
          },
          {
            route: "/English/lung-cancer-risk-calculator",
            label: "Calculate my lung cancer risk",
            title: "Calculate my lung cancer risk",
            page: "CalculatePage"
          },
          {
            route: "/English/result/",
            label: "Result",
            title: "What is my lung cancer risk",
            page: "ResultPage"
          },
          {
            route: "/English/disclaimer",
            label: "Disclaimer",
            title: "Disclaimer",
            page: "DisclaimerPage"
          },
          {
            route: "/English/development-team",
            label: "Development Team",
            title: "Development Team",
            page: "TeamPage"
          },
          {
            route: "/English/development-history",
            label: "Development History",
            title: "Development History",
            page: "HistoryPage"
          },
          {
            route: "/English/feedback",
            label: "Feedback",
            title: "Feedback",
            page: "FeedbackPage"
          },
          {
            route: "/English/saved",
            label: "Saved",
            title: "Saved Content",
            page: "SavedPage"
          },
          {
            route: "/English/publications",
            label: "Publications",
            title: "Publications",
            page: "PublicationPage"
          }
        ]
      },
      savedComponent: {
        saved_label: "Saved",
        saved_url: "/English/saved"
      }
    },
    Español: {
      site: {
        loading_message: "Loading...",
        ie_message: <div className="lead">
        Parece que está usando Internet Explorer (IE). Nuestro sitio funciona mejor en Chrome, y no podemos garantizar que en el futuro funcione en IE. Considere cambiar a Chrome, Edge u otro navegador para mejor uso del sitio.<br /><br />
        Usted puede bajar Chrome aquí: <a
                    target="_blank"
                    href="https://www.google.com/chrome/"
                    rel="noopener noreferrer"
                  >
                    Chrome
                  </a>
        </div>
      },
      homePage: {
        banner_title:
          "Detección temprana de cáncer de pulmón con tomografía computarizada",
        banner_content: "¿Debo hacérmela?",
        banner_button_label: "Conocer más",
        banner_button_link: "/Español/que-es-la-deteccion-del-cancer-de-pulmon",
        two_column_title: "Nosotros podemos ayudarle.",
        two_column_sub_title:
          "¿Debo hacerme la prueba de detección temprana de cáncer de pulmón? Para ayudarle a tomar una decisión, hemos creado una guía de cosas importantes a considerar.",
        two_column_content: {
          first: {
            title: "¿Quiénes son elegibles para ésta prueba?",
            sub_title: (
              <div>
                <ul>
                  <li>Tiene entre 55-77 or 55-80 años de edad,</li>
                  <li>
                    Ha fumado en algún momento durante los últimos 15 años, y
                  </li>
                  <li>
                    Ha fumado en su vida al menos el equivalente a una cajetilla
                    (o paquete) de cigarrillos al día por 30 años (es decir, al
                    menos ha consumido 30 paquete-años). Ver{" "}
                    <Link
                      to={"/Español/calculadora-de-paquete-anos"}
                      style={{ width: "100%", textDecoration: "none" }}
                      data-on="click"
                      data-event-category="HomePage"
                      data-event-action={"/Español/calculadora-de-paquete-anos"}
                    >
                      la calculadora de paquete-años.
                    </Link>
                    )
                  </li>
                </ul>
              </div>
            ),
            image: "/image/questioningDude.jpg",
            button_label: "Conocer más",
            link: "/Español/que-es-la-deteccion-del-cancer-de-pulmon"
          },
          second: {
            title: "Calculadora del riesgo de cáncer de pulmón",
            sub_title:
              "¿Quiere saber si usted debería realizarse una prueba de detección? Utilice nuestra calculadora para conocer su riesgo personal de cáncer de pulmón.",
            image: "/image/SIScalculator.jpg",
            button_label: "Úselo",
            link: "/Español/calculadora-del-riesgo-de-cancer-de-pulmon"
          }
        },
        time_navigation: [
          {
            title: "1. ¿Porqué?",
            items: [
              {
                title: "¿Porqué debería considerarlo?",
                content: (
                  <div>
                    <p>
                      La prueba puede detectar el cáncer de pulmón en una etapa
                      temprana, cuando el tratamiento tiene mayor posibilidad de
                      éxito. Sin una prueba de detección temprana, el cáncer de
                      pulmón usualmente se presenta cuando ya está en etapas
                      avanzadas.{" "}
                      <div className="is-pulled-right">
                        <MyPager
                          link={
                            "/Español/que-es-la-deteccion-del-cancer-de-pulmon"
                          }
                          label={"Conocer más"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              },
              {
                title: "¿Cuáles son los síntomas de cáncer de pulmón?",
                content: (
                  <p>
                    Dolor en el pecho, baja de peso y tener tos recurrente todos
                    los días pueden ser señales de cáncer de pulmón, en especial
                    si usted fuma o fumó.{" "}
                    <strong>
                      Sin embargo, la prueba de detección temprana no es para
                      personas con síntomas.
                    </strong>{" "}
                    Si usted tiene síntomas que le preocupen, por favor consulte
                    a su médico.
                  </p>
                )
              },
              {
                title: "¿En qué consiste la prueba?",
                content: (
                  <div>
                    <p>
                      Tomarse una tomografía computacional (TC) de su pecho (CT
                      scan en inglés). Durante la prueba se le toman
                      “fotografías” de rayos X a su pecho con una máquina de
                      tomografía computacional (TC) mientras usted permanece
                      acostado en una camilla.{" "}
                      <div className="is-pulled-right">
                        <MyPager
                          link={
                            "/Espanõl/que-es-la-deteccion-del-cancer-de-pulmon"
                          }
                          label={"Conocer más"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              }
            ]
          },
          {
            title: "2. Costo",
            items: [
              {
                title: "Costo",
                content: (
                  <div>
                    <p>
                      El costo (a su bolsillo) de la prueba inicial si no tiene
                      seguro médico varía de $100-$400 dólares. Visite nuestra
                      página sobre cobertura de seguro para saber si su seguro
                      médico le cubre la prueba.
                    </p>
                    <p>
                      <div className="is-pulled-right">
                        <MyPager
                          link={"/Español/cobertura"}
                          label={"Conocer más"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              },
              {
                title: "¿Dónde me puedo hacer la prueba?",

                content: (
                  <div>
                    <p>
                      Se recomienda que se la haga en un sitio acreditado por el
                      Colegio Americano de Radiología (ACR por sus siglas en
                      inglés). Vaya a&nbsp;
                      <a
                        target="_blank"
                        href="http://www.acraccreditation.org/accredited-facility-search"
                        rel="noopener noreferrer"
                      >
                        este sitio
                      </a>
                      &nbsp; para ver una lista de los sitios acreditados.
                    </p>
                  </div>
                )
              }
            ]
          },
          {
            title: "3. Otras consideraciones",
            items: [
              {
                title: "No se limita a una sola prueba.",
                content: (
                  <div>
                    <p>
                      La detección temprana de cáncer de pulmón no se limita a
                      una sola prueba, sino que involucra todo un proceso bajo
                      la supervisión de su médico. Para que tenga el mayor
                      beneficio, debe hacerse la prueba una vez al año. Y
                      dependiendo del resultado, tal vez tenga que hacerse
                      pruebas de seguimiento, como otra tomografía
                      computacional, en 3 o 6 meses.{" "}
                    </p>
                  </div>
                )
              },

              {
                title: "Más sobre efectos adversos",
                content: (
                  <div>
                    <p>
                      Es importante considerar la posibilidad de que ocurran
                      falsas alarmas (resultados positivos falsos) y
                      sobretratamiento a consecuencia de la prueba de detección
                      temprana.
                    </p>
                    <p>
                      <div className="is-pulled-right">
                        <MyPager
                          link={
                            "/Español/beneficios-y-efectos-adversos-de-la-prueba"
                          }
                          label={"Conocer más"}
                          arrowDirection={"right"}
                        ></MyPager>
                      </div>
                    </p>
                  </div>
                )
              },
              {
                title:
                  "Otros factores de riesgo de cáncer de pulmón (además de fumar):",
                content: (
                  <div>
                    <p>
                      Aunque fumar es la causa principal de cáncer de pulmón,
                      hay otros factores que pueden causar cáncer de pulmón,
                      como exposición a gas radón o exposición ocupacional a
                      asbestos, arsénico, y a algunos tipos de cromo y sílice.
                    </p>
                    <div className="is-pulled-right">
                      <MyPager
                        link={"/Español/causas-del-cancer-de-pulmon"}
                        label={"Más sobre otros factores de riesgo"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </div>
                )
              }
            ]
          }
        ]
      },
      benefitHarmPage: {
        banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
        banner_title: "LA DETECCIÓN TEMPRANA DE CÁNCER DE PULMÓN",
        title:
          "Información acerca de la detección temprana de cáncer de pulmón con tomograpfía computarizada (TC)",
        benefit_harm_content: {
          benefit: {
            title: "¿CUÁLES SON LOS BENEFICIONS DE LA PRUEBA?",
            content:
              "La probabilidad de detectar cáncer en etapa temprana es alta para las personas que son elegibles y que deciden hacerse la prueba. Encontrar cáncer en etapas tempranas significa tener más opciones disponibles de tratamiento. Un estudio reciente mostró que después de 6.5 años, las personas elegibles que se realizaron la prueba con TC fueron 20% menos susceptibles de morir de cáncer de pulmón comparados con aquellas que no se realizaron la prueba."
          },
          harm: {
            title: "¿CUÁLES SON LOS EFECTOS ADVERSOS DE LA PRUEBA?",
            content: (
              <div>
                <p>
                  La prueba de detección temprana de cáncer de pulmón solo ayuda
                  a encontrar cáncer si este realmente existe. La prueba
                  desafortunadamente no previene la ocurrencia del cáncer. La
                  única manera efectiva de prevenir el cáncer de pulmón es
                  dejando de fumar si usted no lo ha realizado. La prueba
                  utiliza un escáner de TC el cual usa una dosis más baja de
                  radiación de rayos X que un escáner de TC convencional. El
                  riesgo adicional de desarrollar un cáncer de pulmón mortal por
                  una prueba de detección en adultos es tan bajo que es difícil
                  de medir, con una probabilidad estimada de 1 en 100,000 y 1 en
                  10,000. Para quienes están interesados en saber sobre la dosis
                  total de radiación. Puede visitar&nbsp;
                  <a
                    target="_blank"
                    href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                    rel="noopener noreferrer"
                  >
                    este sitio
                  </a>
                  . Otros efectos adversos de la prueba son: falsas alarmas,
                  sobretratamiento, pruebas adicionales, y procedimientos
                  invasivos.
                </p>
              </div>
            )
          }
        },
        sample_list: [
          {
            title: "FALSAS ALARMAS",
            content: (
              <div>
                <p>
                  La prueba de detección temprana consiste en encontrar nódulos
                  pulmonares, de los cuales algunos podrían ser cancerosos. Sin
                  embargo la mayoría (cerca del 96%) de los nódulos detectados
                  durante ésta prueba <strong>no</strong> son cancerosos. Estos
                  nódulos no cancerosos o falsos positivos superan en número a
                  los verdaderos casos de cáncer (relación de 25 a 1). Un
                  hallazgo falso positivo tiene el potencial de causar ansiedad.
                  Sin embargo, el saber que la mayoría de nódulos pulmonares
                  encontrados en una TC <strong>no</strong>
                  representa cáncer ayuda a muchos pacientes a reducir
                  significativamente la ansiedad mientras se espera a los
                  resultados de pruebas adicionales.
                </p>
              </div>
            )
          },
          {
            title: "SOBRETRATAMIENTO (O SOBREDIAGNÓSTICO)",
            content: (
              <div>
                <p>
                  Hay algunas ocasiones en que la prueba encontrará un nódulo
                  que aunque es canceroso, nunca iba a causar problemas
                  (sobrediagnóstico). Estos puede llevar a tratamientos como la
                  cirugía, quimioterapia, y radiación, los cuáles son
                  innecesarios y considerados como sobretratamiento. El
                  sobretratamiento a su vez puede causar efectos secundarios y
                  malestar sin conllevar un beneficio real al paciente. Nota:
                  una vez detectado un cáncer, es imposible saber si este es un
                  sobrediagnóstico o no.
                </p>
              </div>
            )
          },
          {
            title: "PRUEBAS ADICIONALES",
            content: (
              <div>
                <p>
                  Con el objetivo de comprobar si el nódulo no es canceroso, la
                  recomendación generalmente apropiada es realizarse pruebas de
                  seguimiento con TC para monitorear cambios en el tamaño de los
                  nódulos encontrados. Su médico le hará recomendaciones para un
                  apropiado intervalo de seguimiento para monitorear cualquier
                  nódulo pulmonar detectado. Regularmente, el intervalo de
                  seguimiento es de 6 a 12 meses.
                </p>
              </div>
            )
          },
          {
            title: "PROCEDIMIENTOS INVASIVOS",
            content: (
              <div>
                <p>
                  Algunas veces se necesitan pruebas adicionales tras un
                  hallazgo en la prueba de detección de cáncer de pulmón. Estas
                  pruebas adicionales pueden ser procedimientos invasivos
                  (biopsias o cirugías). Este tipo de pruebas tienen su propio
                  riesgo y pueden causar efectos adversos en personas que no
                  tienen cáncer. Procedimientos invasivos pueden resultar en
                  complicaciones menores como sangrado e infecciones. Estos
                  pueden también resultar en complicaciones mayores tales como
                  colapso pulmonar e incluso la muerte.
                </p>
              </div>
            )
          }
        ],
        more_info: (
          <p>
            PARA MAYOR DETALLE, USTED PUEDE VISITAR LAS{" "}
            <a
              href={
                "https://www.cancer.gov/espanol/tipos/pulmon/investigacion/nlst-respuestas"
              }
              target=" blank"
              rel="noopener noreferrer"
            >
              {"PREGUNTAS/RESPUESTAS"}
            </a>{" "}
            RECOPILADAS POR EL INSTITUTO NACIONAL DE CÁNCER QUE EXPLICA SOBRE
            LOS HALLAZGOS DEL ENSAYO CLÍNICO NACIONAL DE LA DETECCIÓN DE CÁNCER
            DE PULMÓN.
          </p>
        ),
        image_info: {
          head: "Image: ",
          src: "https://www.flickr.com/photos/pulmonary_pathology/",
          text: "Yale Rosen/Flickr. CC BY-SA 2.0."
        }
      },
      causePage: {
        banner_image_source: "/image/smoke.jpg",
        banner_title: "LAS CAUSAS DE CÁNCER DE PULMÓN",
        cause_info: {
          title: "CÁNCER DE PULMÓN Y SUS CAUSAS",
          causes: [
            <p>
              El cáncer de pulmón inicia en células de las vías respiratorias
              que han sufrido un daño permanente. Este daño resulta en un
              crecimiento anormal no controlado y que puede diseminarse a otras
              partes de su cuerpo (metástasis). El cáncer de pulmón es la causa
              número uno de muerte por cáncer en el mundo.
            </p>,
            <p>
              Existen muchos factores ligados al cáncer de pulmón. La causa más
              importante y el factor de riesgo más estudiado es el{" "}
              <strong>consumo de tabaco</strong>.
            </p>
          ]
        },
        cause_chart: {
          src: "/image/attributable_risks_tiff2_Español.png",
          text:
            "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49."
        },
        risk_info: {
          title: "Otros factores de riesgo asociados al cáncer de pulmón son:",
          risks: [
            "exposición de largo plazo a radón (un gas sin color u olor), al cual pueden estar expuestas personas que trabajan en minería, y el cual puede acumularse en el sótano de algunas casas);",
            "exposición ocupacional a asbestos, arsénico, y algunos tipos de cromo y sílice;",
            "un diagnóstico médico de Enfermedad Pulmonar Obstructiva Crónica (EPOC), o enfisema y/o bronquitis crónica; e",
            "historia familiar de cáncer de pulmón."
          ]
        }
      },
      comparePage: {
        image_info: {
          link: "/image/fruit.png",
          attribution: {
            link: "https://creativecommons.org/licenses/by-sa/2.0/",
            label: "MicroAssist/Flickr: CC BY-SA 2.0"
          }
        },
        title:
          "¿Cómo se compara la prueba de detección de cáncer de pulmón por TC con otras pruebas de detección temprana?",
        sub_title: "¿SABÍA QUE?",
        content:
          "Comparado con otras pruebas de detección temprana de cáncer, como la mamografía para cáncer de mama, la prueba de detección para cáncer de pulmón por TC es tan efectiva para reducir las muertes por cáncer de pulmón en personas de alto riesgo, como lo es la mamografía para reducir las muertes por cáncer de mama.",
        comparison_table: {
          column_title_list: [
            <div>
              <center>
                Tipo de tamizaje<sup>1</sup>
              </center>
            </div>,
            <div>
              <center>Años de pruebas anuales</center>
            </div>,
            <div>
              <center>
                Número de muertes por cáncer prevenidas<sup>2</sup>
              </center>
            </div>
          ],
          row_list: [
            {
              title: "Pulmón",
              cell_list: [3, 3.1]
            },
            {
              title: "Colorrectal",
              cell_list: [5, 2.8]
            },
            {
              title: "Mama",
              cell_list: ["", ""]
            },
            {
              title: "Edad 60-69",
              cell_list: [10, 2.6]
            },
            {
              title: "Edad 50-59",
              cell_list: [10, 0.8]
            }
          ]
        },
        foot_note_list: [
          <div>
            <sup>1</sup>Los exámenes correspondientes a tamizaje de cáncer de
            pulmón, colon y mama son: tomografía computacional de baja dosis
            (pulmón), sigmoidoscopia con prueba sangre oculta en heces (colon) y
            mastografía (mama).
          </div>,
          <div>
            <sup>2</sup>Número de muertes prevenidas por 1000 personas
            tamizadas.
          </div>
        ]
      },
      disclaimerPage: {
        title: "Exención de responsabilidades",
        content:
          "La información proporcionada en este sitio es de información de salud en general.  La calculadora de riesgo puede darle solo estimados de su riesgo basado en las respuestas que usted ha proporcionado.  A pesar de que hemos tratado de proveer las mejores estimaciones disponibles, los números podrían no ser los correctos para una persona en particular.  Por ejemplo, podrían haber algunos factores específicos de riesgo que no han sido medidos. Por lo mismo, la información proporcionada en este sitio no debe ser usada como sustituto de recomendaciones médicas proporcionadas por sus servicios de salud.  Si usted se encuentra preocupado de su riesgo de desarrollar cáncer de pulmón, por favor hable con su médico o proveedor de salud.  Este sitio contiene enlaces a otros sitos o páginas de internet operados por terceros, y no garantizamos la representación de cualquier sitio de terceros o la exactitud de su contenido."
      },
      howPage: {
        title: "Cosas que debe saber sobre la detección del cáncer de pulmón",
        banner_image_source: "/image/um-ct-shoot.jpg",
        banner_title: "LA DETECCIÓN TEMPRANA DE CÁNCER DE PULMÓN",
        sample_list: [
          {
            title:
              "Q1. ¿QUÉ ES LA PRUEBA DE DETECCIÓN TEMPRANA DE CÁNCER DE PULMÓN CON TC?",
            content: (
              <div>
                <p>
                  La detección temprana (“screening” en inglés) de cáncer de
                  pulmón utiliza una tomografía computarizada de baja dosis
                  (LDCT, por sus siglas en inglés low-dose computed tomography),
                  es decir una tomografía con dosis mínimas de radiación, con la
                  cual se buscan pequeñas formaciones (o nódulos) en los
                  pulmones, los cuales podrían ser cancerosos. Las personas
                  elegibles pueden disminuir su probabilidad de morir a causa de
                  cáncer de pulmón al ser detectado en etapas tempranas con la
                  TC.
                </p>
                <p>
                  En general, la detección temprana de cáncer de pulmón no se
                  limita a una sola prueba, sino que involucra todo un proceso
                  que debe realizarse bajo la supervisión de su médico.
                </p>
              </div>
            )
          },
          {
            title: "Q2. ¿QUIÉNES SON ELEGIBLES PARA ÉSTA PRUEBA?",
            content: (
              <div>
                <p>
                  La prueba de detección temprana de cáncer de pulmón no es
                  recomendada para todos. En particular, la prueba no es
                  recomendada para aquellos que nunca han fumado. Usted es
                  elegible para ésta prueba, de acuerdo a las recomendaciones
                  del Grupo de Trabajo de Servicios Preventivos de los Estados
                  Unidos (USPSTF, por sus siglas en inglés, US Preventive
                  Services Task Force), si:
                </p>
                <ul>
                  <li>Tiene entre 55 y 80 años de edad,</li>
                  <li>
                    Ha fumado en algún momento durante los últimos 15 años, y
                  </li>
                  <li>
                    Ha fumado en su vida al menos el equivalente a una cajetilla
                    (o paquete) de cigarros o cigarrillos al día por 30 años (es
                    decir, al menos ha consumido 30 paquete-años)*
                  </li>
                </ul>
                <p>
                  *El paquete-años es usado para describir el número de cigarros
                  o cigarrillos que usted ha fumado durante su vida, asumiendo
                  que 1 paquete o cajetilla contiene usualmente 20 cigarros o
                  cigarrillos. Dependiendo de cuántos años usted ha fumado o
                  fumó, y del número de cigarros o cigarillos al día en
                  promedio, será el número de paquete-años que ha fumado
                  (calculado como: # años fumando el número de cigarrillos al
                  día en promedio 20). Por ejemplo, si usted ha fumado una
                  cajetilla al día (20 cigarrillos) durante los últimos 30 años,
                  usted ha consumido 30 paquete-años (30x(20/20)). De la misma
                  manera, si usted ha fumado 2 cajetillas diarias (40
                  cigarrillos al día) por los últimos 15 años, usted ha
                  consumido también 30 paquete-años (15x(40/20)). Pulse{" "}
                  <Link
                    to={"/Español/calculadora-de-paquete-anos"}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HowPage"
                    data-event-action={"/Español/calculadora-de-paquete-anos"}
                  >
                    aquí
                  </Link>{" "}
                  para calcular cuántos paquete-años ha consumido.
                </p>
                <p>
                  Nota: A partir de Febrero de 2015, Medicare cubrirá las
                  pruebas de detección temprana de cáncer de pulmón para sus
                  beneficiarios elegibles hasta los 77 años de edad.
                </p>
              </div>
            )
          },
          {
            title: "Q3. ¿QUÉ SUCEDE DURANTE LA PRUEBA DE DETECCIÓN CON TC?",
            content: (
              <div>
                <p>
                  La siguiente descripción es una adaptación del{" "}
                  <a
                    href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Colegio Americano de Radiología
                  </a>
                  :
                </p>
                <p>
                  La TC de baja dosis se toma mediante un escáner (maquina de
                  rayos X) mientras usted permanece acostado en una camilla.
                  Almohadas y cinturones podrán ser utilizados para su comodidad
                  y para ayudarlo a mantener una posición correcta y a
                  permanecer inmóvil durante el examen. Es probable que se le
                  solicite sostener sus brazos sobre su cabeza. Una vez que se
                  encuentre en la posición correcta, la camilla se introducirá
                  en el escáner hasta llegar a la posición inicial para la toma
                  de imágenes. Luego, mientras usted sostiene su respiración por
                  5 o 10 segundos, la camilla se moverá a través de la máquina
                  hasta que la toma de imágenes haya finalizado.
                </p>
              </div>
            )
          },
          {
            title: "Q4. ¿QUÉ SUCEDE DESPUÉS DE LA TC DE DETECCIÓN TEMPRANA?",
            content: (
              <div>
                <p>
                  Si se detecta un nódulo pulmonar durante la TC, su médico
                  podría recomendarle otra TC de seguimiento, usualmente 6 meses
                  después de la primera para determinar si el nódulo ha crecido
                  (generalmente no cambiará de tamaño). En el caso extremo de
                  que el nódulo haya crecido o sea de preocupación, su médico
                  podría recomendarle realizar pruebas adicionales usando un{" "}
                  <a
                    href="https://medlineplus.gov/ency/article/007342.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    escáner de TEP
                  </a>{" "}
                  o una biopsia (tomada de una pequeña porción del nódulo).
                </p>
                <p>
                  Sin embargo, debido a que más del 95% de los nódulos
                  encontrados no son cancerosos, lo más probable es que
                  simplemente tenga que volver un año después como con cualquier
                  otro chequeo médico para continuar con el proceso de detección
                  temprana. Recuerde, la detección de cáncer de pulmón no es una
                  simple prueba: es un proceso que debe ser correctamente
                  realizado bajo la supervisión de su médico.
                </p>
              </div>
            )
          },
          {
            title:
              "Q5. ¿A CUÁNTA RADIACIÓN ESTARÉ EXPUESTO CON LA TC PARA LA DETECCIÓN DE CÁNCER DE PULMÓN?",
            content: (
              <div>
                <p>
                  La TC para la detección de cáncer de pulmón usa hasta 90%
                  menos de radiación que una TC de tórax convencional. La dosis
                  aproximada de radiación efectiva es de 1.5 milisiviert (mSv),
                  el cual es equivalente a 6 meses de exposición ambiental de
                  radiación (lo “normal”) y tiene un muy bajo riesgo. El riesgo
                  adicional de desarrollar un cáncer de pulmón mortal por una
                  prueba de detección temprana en adultos es tan bajo que es
                  difícil de calcular, con una probabilidad estimada de 1 en
                  100,000 y 1 en 10,000. Para más información sobre exposición a
                  radiación, usted podría visitar{" "}
                  <a
                    href="https://www.radiologyinfo.org/en/info.cfm?pg=safety-xray"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>{" "}
                  o{" "}
                  <a
                    href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>
                  .
                </p>
              </div>
            )
          },
          {
            title: "Q6. ¿DÓNDE PUEDO REALIZARME LA PRUEBA?",
            content: (
              <div>
                <p>
                  Si lo decide, usted debe de realizarse la prueba únicamente en
                  lugares acreditados. Para encontrar un lugar cerca de usted,
                  pulse{" "}
                  <a
                    href="http://www.acraccreditation.org/accredited-facility-search"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>
                  .
                </p>
              </div>
            )
          }
        ]
      },
      riskPage: {
        banner_image_source: "/image/gsh-sa-balloons.jpg",
        banner_title: "REDUCIR SU RIESGO DE DESARROLLAR CÁNCER DE PULMÓN",
        title:
          "LA MEJOR MANERA DE REDUCIR SU RIESGO DE DESARROLLAR CÁNCER DE PULMÓN ES DEJAR DE FUMAR.",

        description: (
          <p>
            Mientras que la prueba de TC (Tomografía computarizada de baja
            dosis) puede reducir su probabilidad de morir por cáncer de pulmón,
            la forma
            <strong> más efectiva</strong> de reducir su riesgo de morir por
            cáncer de pulmón es dejar de fumar si es que usted aún fuma. Esto es
            difícil pero se puede lograr. Para dejar de fumar, usted necesita
            superar el deseo (antojo) de la nicotina, así como un hábito
            profundamente arraigado. Muchas personas refieren al “estrés” como
            la razón por la que no quieren de abandonar el cigarro. El estrés es
            parte de la vida diaria de muchas personas, y esperar a que éste
            desaparezca antes de hacer algo beneficioso para su salud
            difícilmente traerá resultados. Debemos de aprender diferentes
            maneras de manejar el estrés; hablar con su médico acerca de sus
            fuentes de estrés es un buen comienzo. Existen muchos recursos que
            pueden ayudarle a abandonar el hábito de fumar, y algunos le
            proveerán gratis (o a un precio reducido) de dispositivos para dejar
            de fumar aprobados por la Administración de medicamentos y alimentos
            de los Estados Unidos (FDA). Aquí hay algunos de estos recursos:
          </p>
        ),
        state_resource_list: [
          { state: "Seleccione un estado...", links: [] },
          ,
          {
            state: "Alabama",
            links: [
              {
                label:
                  "Alabama Department of Public Health, Tobacco Prevention and Control",
                link: "http://www.adph.org/tobacco/Default.asp?id=779"
              }
            ]
          },

          {
            state: "Alaska",
            links: [
              {
                label: "Tobacco-Free Alaska",
                link:
                  "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx"
              }
            ]
          },

          {
            state: "Arizona",
            links: [
              {
                label: "ASHLine - Línea de Ayuda para Fumadores en Arizona",
                link: "https://ashline.org/es/"
              },

              {
                label: "Tobacco Free Arizona",
                link: "http://azdhs.gov/tobaccofreeaz/"
              }
            ]
          },

          {
            state: "Arkansas",
            links: [
              {
                label: "Arkansas Tobacco Cessation Resources",
                link:
                  "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources"
              },
              {
                label: "Stamp Out Smoking",
                link: "http://www.stampoutsmoking.com/get-help-to-quit/"
              }
            ]
          },

          {
            state: "California",
            links: [
              {
                label: "California Cessation Services and Resources",
                link:
                  "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx"
              },
              {
                label: "Linea de Ayuda para Fumadores de California",
                link: "https://www.nobutts.org/spanish"
              }
            ]
          },

          {
            state: "Colorado",
            links: [
              {
                label: "Colorado Department of Public Health and Environment",
                link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco"
              },
              {
                label: "Colorado Sin Tabaco",
                link: "http://www.coloradosintabaco.org/"
              }
            ]
          },

          {
            state: "Connecticut",
            links: [
              {
                label: "Connecticut Department of Public Health",
                link:
                  "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841"
              }
            ]
          },

          {
            state: "Delaware",
            links: [
              {
                label: "Delaware Tobacco Prevention and Control Program",
                link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html"
              }
            ]
          },

          {
            state: "Florida",
            links: [
              {
                label: "Tobacco Free Florida",
                link: "http://tobaccofreeflorida.com/es/"
              }
            ]
          },

          {
            state: "Georgia",
            links: [
              {
                label: "Georgia",
                link: "https://dph.georgia.gov/ready-quit"
              }
            ]
          },

          {
            state: "Hawaii",
            links: [
              {
                label: "Hawaii Tobacco Quitline",
                link: "http://hawaiiquitline.org"
              }
            ]
          },

          {
            state: "Idaho",
            links: [
              {
                label: "Idaho Tobacco Prevention and Control Program",
                link:
                  "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx"
              },
              {
                label: "Project Filter",
                link: "https://idaho.quitlogix.org/es-ES/Enroll-Now"
              }
            ]
          },

          {
            state: "Illinois",
            links: [
              {
                label: "Illinois Tobacco Quitline",
                link: "http://quityes.org/home.html"
              }
            ]
          },

          {
            state: "Indiana",
            links: [
              {
                label: "Indiana State Department of Health",
                link: "http://www.in.gov/isdh/tpc/2353.htm"
              }
            ]
          },

          {
            state: "Iowa",
            links: [
              {
                label: "Quitline Iowa",
                link: "https://iowa.quitlogix.org/"
              }
            ]
          },

          {
            state: "Kansas",
            links: [
              {
                label: "Kansas Department of Health and Environment",
                link: "http://www.kdheks.gov/tobacco/cessation.html"
              },
              { label: "KanQuit!", link: "http://www.ksquit.org/" }
            ]
          },

          {
            state: "Kentucky",
            links: [
              {
                label: "La línea telefónica de Kentucky para dejar el tabaco",
                link:
                  "http://chfs.ky.gov/NR/rdonlyres/98C3837F-6777-4E2C-BDE1-1E3BA753B924/0/PatientQuitlineFactSheetESTspa.pdf"
              }
            ]
          },

          {
            state: "Louisiana",
            links: [
              {
                label: "Quit with us, Louisiana",
                link: "http://www.quitwithusla.org/"
              }
            ]
          },

          {
            state: "Maine",
            links: [
              {
                label: "Partnership for a Tobacco-Free Maine",
                link: "http://www.tobaccofreemaine.org/"
              }
            ]
          },

          {
            state: "Maryland",
            links: [
            { 
              label: "Smoking Stops Here - Linea de Ayuda para Fumadores de Maryland", 
              link: "https://smokingstopshere.com/" 
            },
            { 
              label: "MDQuit", 
              link: "http://mdquit.org/" 
            }
            ]
          },

          {
            state: "Massachusetts",
            links: [
              {
                label: "Make smoking history",
                link: "http://makesmokinghistory.org/"
              }
            ]
          },

          {
            state: "Michigan",
            links: [
              {
                label: "Michigan Department of Community Health",
                link:
                  "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html"
              },
              {
                label: "University of Michigan Tobacco Treatment Programs",
                link:
                  "http://hr.umich.edu/mhealthy/programs/tobacco/programs.html"
              }
            ]
          },

          {
            state: "Minnesota",
            links: [
              {
                label: "Minnesota Department of Healthh",
                link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html"
              },
              {
                label: "QUITPLAN services. No judgments. Just help.",
                link: "https://www.quitplan.com/"
              }
            ]
          },

          {
            state: "Mississippi",
            links: [
              {
                label: "Mississippi Office of Tobacco Control",
                link:
                  "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community"
              }
            ]
          },

          {
            state: "Missouri",
            links: [
              {
                label: "Missouri Department of Health and Senior Services",
                link:
                  "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php"
              }
            ]
          },
          {
            state: "Montana",
            links: [
              {
                label: "Montana Tobacco Use Prevention Program",
                link: "http://dphhs.mt.gov/publichealth/mtupp"
              },
              {
                label: "Montana Tobacco Quitline",
                link: "https://montana.quitlogix.org/es-ES"
              }
            ]
          },
          {
            state: "Nebraska",
            links: [
              {
                label: "Smoke Free Nebraska",
                link:
                  "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx"
              }
            ]
          },

          {
            state: "Nevada",
            links: [
              {
                label: "Nevada Tobacco Quitline",
                link: "https://nevada.quitlogix.org/es-ES/"
              },
              {
                label: "Nevada Tobacco Prevention Coalition",
                link:
                  "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking"
              }
            ]
          },

          {
            state: "New Hampshire",
            links: [
              {
                label: "New Hampshire Tobacco Prevention and Control Program",
                link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm"
              },
              {
                label: "New Hampshire Tobacco Helpline",
                link: "http://trytostopnh.org/"
              }
            ]
          },
          {
            state: "New Jersey",
            links: [
              {
                label: "New Jersey Comprehensive Tobacco Control Program",
                link: "http://www.nj.gov/health/ctcp/index.shtml"
              },
              {
                label: "New Jersey Quitline",
                link: "http://www.njquitline.org/treatment.html"
              }
            ]
          },

          {
            state: "New Mexico",
            links: [
              {
                label: "Déjelo ya",
                link: "http://dejeloyanm.com"
              }
            ]
          },

          {
            state: "New York",
            links: [
              {
                label: "La línea de Nueva York para Dejar de Fumar",
                link: "https://nysmokefree.com/default.aspx?lang=SP"
              }
            ]
          },

          {
            state: "North Carolina",
            links: [
              {
                label: "North Carolina Tobacco Prevention and Control",
                link:
                  "http://www.tobaccopreventionandcontrol.ncdhhs.gov/cessation/index.htm"
              }
            ]
          },

          {
            state: "North Dakota",
            links: [
              {
                label: "North Dakota Tobacco Prevention and Control Program",
                link: "http://www.ndhealth.gov/tobacco/"
              },
              {
                label: "North Dakota Cessation/Quitting Programs",
                link: "http://www.ndhealth.gov/tobacco/cessation.htm"
              }
            ]
          },

          {
            state: "Ohio",
            links: [
              {
                label: "Ohio Tobacco Program Cessation",
                link:
                  "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation"
              },
              {
                label: "Ohio Quitline",
                link: "https://ohio.quitlogix.org/es-ES/"
              }
            ]
          },

          {
            state: "Oklahoma",
            links: [
              {
                label: "Oklahoma Tobacco Use Prevention and Reduction Programs",
                link:
                  "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html"
              }
            ]
          },

          {
            state: "Oregon",
            links: [
              {
                label: "Oregon Tobacco Prevention and Education Program",
                link:
                  "http://public.health.oregon.gov/PHD/Directory/Pages/program.aspx?pid=56"
              },
              {
                label: "Smokefree Oregon",
                link: "https://www.quitnow.net/oregonsp/"
              }
            ]
          },

          {
            state: "Pennsylvania",
            links: [
              {
                label: "Pennsylvania Tobacco Prevention and Control",
                link:
                  "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs"
              },
              {
                label: "PA Free Quitline",
                link: "https://pa.quitlogix.org/es-ES/"
              }
            ]
          },

          {
            state: "Rhode Island",
            links: [
              {
                label: "Rhode Island Tobacco Control Program",
                link: "http://www.health.ri.gov/programs/tobaccocontrol/"
              },
              { label: "Quit Now RI", link: "http://www.quitnowri.com/" }
            ]
          },
          {
            state: "South Carolina",
            links: [
              {
                label: "South Carolina Tobacco Cessation",
                link: "http://www.scdhec.gov/Health/TobaccoCessation/"
              }
            ]
          },
          {
            state: "South Dakota",
            links: [
              {
                label: "South Dakota Tobacco Prevention and Control Program",
                link: "http://doh.sd.gov/prevention/tobacco/"
              }
            ]
          },
          {
            state: "Tennessee",
            links: [
              {
                label: "Tennessee Tobacco Quitline",
                link: "http://www.tnquitline.org/services.php"
              }
            ]
          },
          {
            state: "Texas",
            links: [
              {
                label: "Texas Control y Prevención de Tabaco",
                link:
                  "http://www.dshs.texas.gov/region8/Tabaco.aspx?terms=tabaco"
              },
              {
                label: "Tobacco is foul",
                link: "http://ducktexas.org/espanol.php"
              }
            ]
          },
          {
            state: "Utah",
            links: [
              {
                label: "Utah Tobacco Prevention and Control Program",
                link: "http://www.tobaccofreeutah.org/"
              },
              {
                label: "Déjelo ya",
                link: "http://dejeloya.org/como-dejar-de-fumar/"
              }
            ]
          },

          {
            state: "Vermont",
            links: [
              {
                label: "Vermont Department of Health",
                link:
                  "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx"
              },
              {
                label: "802 Quits: The Vermont Quit Smoking Resource",
                link: "http://802quits.org/"
              }
            ]
          },
          {
            state: "Virginia",
            links: [
              {
                label: "Virginia Tobacco Use Control Project",
                link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/"
              }
            ]
          },

          {
            state: "Washington",
            links: [
              {
                label: "Cómo obtener ayuda para dejar de fumar",
                link:
                  "http://www.doh.wa.gov/YouandYourFamily/Tabaco/Comoobtenerayudaparadejardefumar"
              }
            ]
          },

          {
            state: "West Virginia",
            links: [
              {
                label: "West Virginia Tobacco Prevention Resources",
                link:
                  "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx"
              }
            ]
          },

          {
            state: "Wisconsin",
            links: [
              {
                label: "Wisconsin Tobacco Prevention and Control Program",
                link: "https://www.dhs.wisconsin.gov/tobacco/index.htm"
              },
              {
                label: "Wisconsin Tobacco Quitline",
                link: "http://www.ctri.wisc.edu/quitline.html"
              }
            ]
          },

          {
            state: "Wyoming",
            links: [
              {
                label:
                  "Wyoming Department of Health Tobacco Prevention Program",
                link:
                  "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/"
              },
              {
                label: "Wyoming Quit Tobacco",
                link: "https://wyo.quitlogix.org/es-ES/"
              }
            ]
          }
        ],
        resources_quit: [
          {
            link: "https://espanol.smokefree.gov/",
            label: "Smokefree.gov"
          },
          {
            link: "http://betobaccofree.hhs.gov/quit-now/index.html",
            label: "BeTobaccoFree.gov"
          },
          {
            link:
              "https://www.cancer.org/es/saludable/mantengase-alejado-del-tabaco/guia-para-dejar-de-fumar.html",
            label:
              "Sociedad Estadounidense contra el Cáncer (American Cancer Society)"
          }
        ],
        resources_instructions:
          "Elija un estado del menú desplegable para encontrar recursos disponibles específicamente en su estado.",
        radon_instructions:
          "Radón es otra factor de riesgo prevenible de cáncer de pulmón. Aprenda como puede reducir su riesgo en:",
        resources_radon: [
          {
            link: "http://www.epa.gov/radon/pubs/citguide.html",
            label:
              "Un guía para los ciudadanos acerca del radón recopilado por la Agencia de Protección Ambiental (EPA)"
          },
          {
            link:
              "http://www.cdc.gov/nceh/radiation/brochure/profile_radon.htm",
            label:
              "El Centro para el Control y la Prevención de Enfermedades (CDC)"
          }
        ]
      },
      packPage: {
        title: "Calculadora de paquete-años",
        instruction: {
          normal:
            "El paquete-años es usado para describir el número de cigarros o cigarrillos que usted ha fumado durante su vida, asumiendo que 1 paquete o cajetilla contiene usualmente 20 cigarros o cigarrillos. Dependiendo de cuántos años usted ha fumado o fumó, y del número de cigarros o cigarillos al día en promedio, será el número de paquete-años que ha fumado (calculado como: # años fumando el número de cigarrillos al día en promedio 20). Por ejemplo, si usted ha fumado una cajetilla al día (20 cigarrillos) durante los últimos 30 años, usted ha consumido 30 paquete-años (30x(20/20)). De la misma manera, si usted ha fumado 2 cajetillas diarias (40 cigarrillos al día) por los últimos 15 años, usted ha consumido también 30 paquete-años (15x(40/20)). Actualmente, tener 30 paquete-años o más es uno de los criterios para la detección temprana de cáncer de pulmón."
        },
        calculation_result_template:
          "Su exposición a cigarro es de x_number paquete-años.", // x_number will be replaced with calculation result, don't change it.
        submit_button_label: "Calcular",
        sub_title:
          "POR FAVOR RESPONDA LAS SIGUIENTES DOS PREGUNTAS PARA CALCULAR SUS PAQUETE-AÑOS (MEDIDA DE CONSUMO DE TABACO).",
        pack_questions: {
          formYears: {
            label: "1. ¿Cuántos años ha fumado usted? ",
            placeholder: "cuántos años?"
          },
          formAmount: {
            label:
              "2. En promedio, ¿cuántos cigarros o cigarrillos fumó usted por día (durante su tiempo como fumador? Recuerde que en general hay 20 cigarros en un paquete.",
            placeholder: "cigarillos por día?"
          }
        }
      },
      calculatePage: {
        banner_image_source: "/image/areachart2.jpg",
        banner_title: "CALCULADORA DEL RIESGO DE CÁNCER DE PULMÓN",
        title:
          "Si usted fuma, la mejor manera de disminuir su riesgo de cáncer de pulmón es dejar de fumar.",

        instruction: {
          normal: (
            <div>
              <p>
                Complete la información a continuación para saber si usted está
                entre el grupo de personas a las que se les recomienda la prueba
                de detección temprana de cáncer de pulmón de acuerdo a los
                lineamientos del&nbsp;
                <a
                  target="_blank"
                  href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                  rel="noopener noreferrer"
                >
                  Grupo de Servicios Preventivos de los Estados Unidos (USPSTF)
                </a>
                . La calculadora también le indicará el posible beneficio de
                hacerse la prueba de detección temprana. Esto le ayudará a
                determinar mejor si su beneficio potencial de ser examinado
                supera los efectos adversos."
              </p>
            </div>
          ),
          strong_text_list: ["* CAMPOS OBLIGATORIOS"]
        },
        submit_button_label: "Calcular",
        default_select_label: " -- seleccionar -- ",
        tall_question: (
          <div>
            8. ¿Cuál es su estatura?&nbsp;
            <a
              target="_blank"
              href="https://www.google.com/search?q=convertir+centimetros+a+pies&ie=utf-8&oe=utf-8&hl=es"
              rel="noopener noreferrer"
            >
              Ayuda para convertir de centimetros a pies/pulgadas.
            </a>
          </div>
        ),

        risk_questions: {
          formOld: {
            label: "1. ¿Cuántos años tiene?* ",
            placeholder: "",
            help_text:
              "La pregunta 1 es requerida. El rango de edades valido es de 18 a 100 años."
          },
          formCurrentStatus: {
            label: "2. ¿Cuál es su estatus como fumador?*",
            placeholder: "",
            options: [
              { label: "Fumador actual", value: 1 },
              { label: "Ex fumador", value: 0 },
              { label: "Nunca ha sido fumador", value: 2 }
            ],
            help_text: "La pregunta 2 es requerida."
          },
          formAgeLastSmoke: {
            label: "2.1. ¿A qué edad dejó de fumar la última vez?*",
            placeholder: "",
            options: [],
            help_text:
              "Usted ha indicado que es un ex fumador. Por favor conteste la pregunta 2.1 y asegurese de que la edad indicada a la que dejo de fumar sea menor que su edad actual (pregunta 1)."
          },
          formYearsSmoking: {
            label:
              "3. ¿Por cuántos años en total usted ha fumado cigarros o cigarrillos?* ",
            placeholder: "",
            options: [],
            help_text:
              "La pregunta 3 es requerida. Por favor asegurese que el número de años indicado sea menor que su edad actual (pregunta 1)."
          },
          formCigarettesPerDay: {
            label:
              "4. En promedio, ¿cuántos cigarros o cigarrillos al día?* Un paquete tiene 20 cigarros. ",
            placeholder: "",
            options: [],
            help_text:
              "La pregunta 4 es requerida. Por favor asegurese que el número de cigarrillos por día sea un número entre 0 y 200."
          },
          formGender: {
            label: "5. ¿Cuál es su género? ",
            placeholder: "",
            options: [
              { label: "Masculino", value: "male" },
              { label: "Feminino", value: "female" }
            ]
          },
          formInsurance: {
            label: <div>6. ¿Tiene cobertura de Medicare? 🏥</div>,
            placeholder: "",
            options: [
              { label: "Sí", value: "yes" },
              { label: "No", value: "no" },
              { label: "No sé / No estoy segur@", value: "unknown" }
            ]
          },
          formHighestGradeCompleted: {
            label:
              "6. ¿Cuál es el mayor grado de educación o año escolar que usted ha completado? ",
            placeholder: "",
            options: [
              { label: "Menos de escuela preparatoria", value: 0 },
              { label: "Graduado de escuela preparatoria", value: 1 },
              {
                label:
                  "Algún curso o diplomado después de la escuela preparatoria",
                value: 2
              },
              { label: "Algo de Universidad", value: 3 },
              { label: "Graduado de Universidad", value: 4 },
              { label: "Graduado de posgrado o grado profesional", value: 5 }
            ]
          },
          formRaceEthnicity: {
            label: "7. ¿Cómo describiría su raza/etnica?",
            placeholder: "",
            options: [
              { label: "Blanco", value: 0 },
              { label: "Afromericano", value: 1 },
              { label: "Hispano", value: 2 },
              { label: "Asiático", value: 3 },
              { label: "Nativo americano o nativo de Alaska", value: 4 },
              {
                label: "Nativo hawaiano o de las islas del Pacífico",
                value: 5
              }
            ]
          },
          formTallFeet: {
            label: "",
            placeholder: "",
            right_side_label: "ft.",
            options: [
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 }
            ]
          },
          formTallInch: {
            label: "",
            placeholder: "",
            right_side_label: "in.",
            options: [
              { label: "0", value: 0 },
              { label: "1", value: 1 },
              { label: "2", value: 2 },
              { label: "3", value: 3 },
              { label: "4", value: 4 },
              { label: "5", value: 5 },
              { label: "6", value: 6 },
              { label: "7", value: 7 },
              { label: "8", value: 8 },
              { label: "9", value: 9 },
              { label: "10", value: 10 },
              { label: "11", value: 11 }
            ]
          },
          formWeight: {
            label: (
              <div>
                9. ¿Cuánto pesa (libras)? &nbsp;
                <a
                  target="_blank"
                  href="https://www.google.com/search?q=convertir+kilogramos+a+libras&ie=utf-8&oe=utf-8&hl=es"
                  rel="noopener noreferrer"
                >
                  Ayuda para convertir de kilogramos a libras.
                </a>
              </div>
            ),
            placeholder: "",
            options: []
          },
          formDoctorToldCancer: {
            label:
              "10. ¿Alguna vez le ha dicho su médico que usted tiene cáncer?",
            placeholder: "",
            options: [
              { label: "Sí", value: 1 },
              { label: "No", value: 0 }
            ]
          },
          formFamilyHistory: {
            label: "11. ¿Tiene usted un historial familar de cáncer de pulmón?",
            placeholder: "",
            options: [
              { label: "Sí", value: 1 },
              { label: "No", value: 0 }
            ]
          },
          formDoctorToldCOPD: {
            label:
              "12. ¿Alguna vez su médico le ha indicado que tiene enfermedad pulmonar crónica también conocido como EPOC (bronquitis crónica o enfisema)? ",
            options: [
              { label: "Sí", value: 1 },
              { label: "No", value: 0 }
            ]
          }
        }
      },
      feedbackPage: {
        title: "Agradecemos sus comentarios!",
        submitted_message: "Gracias!",
        default_select_label: " -- seleccionar -- ",
        submit_button_label: "Enviar",
        feedback_questions: {
          formGender: {
            label: "Yo soy",
            placeholder: "",
            options: [
              { label: "Mujer", value: 0 },
              { label: "Hombre", value: 1 }
            ]
          },
          formSmokeStatus: {
            label: "Yo...",
            placeholder: "",
            options: [
              { label: "soy un fumador.", value: 1 },
              { label: "soy un ex fumador.", value: 0 },
              { label: "nunca he fumado.", value: 2 }
            ]
          },
          formAge: {
            label: "¿Cuántos años tiene?",
            placeholder: "50",
            options: []
          },
          formCalculatorResult: {
            label: "De acuerdo a la calculadora, usted es...",
            placeholder: "",
            options: [
              {
                label: "Elegible para la prueba de detección",
                value: "Eligible to be screened"
              },
              {
                label: "No elegible para la prueba de detección",
                value: "Not eligible to be screened"
              },
              {
                label:
                  "Su riesgo fue muy bajo para ser calculado con precisión",
                value: "Risk was too low to be calculated accurately"
              }
            ]
          },
          formInformationReceiver: {
            label: "Estoy buscando información para",
            placeholder: "",
            options: [
              { label: "Mi", value: "Myself" },
              { label: "Mi pareja", value: "My partner" },
              {
                label: "Miembro(s) de mi familia",
                value: "My family member(s)"
              },
              { label: "Mi(s) amigo(s)", value: "My friend(s)" },
              { label: "Mi(s) paciente(s)", value: "My patient(s)" }
            ]
          },
          formFindAllInformation: {
            label:
              "Yo encontré toda la información que estaba buscando acerca de la prueba de detección temprana para cáncer de pulmón.",
            placeholder: "",
            options: [
              { label: "Muy en desacuerdo", value: -2 },
              { label: "En desacuerdo", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "De acuerdo", value: 1 },
              { label: "Muy de acuerdo", value: 2 }
            ]
          },
          formHelpConversationDoctor: {
            label:
              "La información que encontré me ayudó para hablar con mi médico acerca de la prueba de detección temprana de cáncer de pulmón.",
            placeholder: "",
            options: [
              { label: "Muy en desacuerdo", value: -2 },
              { label: "En desacuerdo", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "De acuerdo", value: 1 },
              { label: "Muy de acuerdo", value: 2 }
            ]
          },
          formHelpDecision: {
            label:
              "El sitio web me ayudó en tomar la decisión para realizarme una prueba de detección de cáncer de pulmón.",
            placeholder: "",
            options: [
              { label: "Muy en desacuerdo", value: -2 },
              { label: "En desacuerdo", value: -1 },
              { label: "Neutral", value: 0 },
              { label: "De acuerdo", value: 1 },
              { label: "Muy de acuerdo", value: 2 }
            ]
          },
          formInterestReason: {
            label:
              "Yo estoy interesado en la detección temprana de cáncer de pulmón porque...",
            placeholder: "E.g., tengo una historia familiar de cáncer."
          },
          formComment: {
            label:
              "Por favor envíe cualquier otro comentario que usted piense que nos puede ayudar para mejorar este sitio web:",
            placeholder: ""
          }
        }
      },
      resultPage: {
        print_button_label: "Imprimir",
        title: "La prueba de detección temprana de cáncer de pulmón:",
        sub_title: "¿Debo hacermela?",
        risk_spectrum: {
          risk_statement: "Su riesgo de cáncer de pulmón:",
          low_risk_label: <span>&#60; 1%: riesgo bajo</span>,
          intermediate_risk_label: <span>1-2%: riesgo intermedio</span>,
          high_risk_label: <span>&#62; 2%: riesgo alto</span>
        },
        risk_confirmation:{
          label: "Según la tabla, mi riesgo de desarrollar cáncer de pulmón es:",
          placeholder: "",
          options: [
            { label: "Alto", value: "High" },
            { label: "Intermedio", value: "Intermediate" },
            { label: "Bajo", value: "Low" },
            { label: "No sé", value: "Don't know" }
          ]
        },
        comparison_chart_info_list:[
          {
            chartTitle:"Sin prueba detección temprana",
            legendTitle: {
              "1000": "Por cada 1000 personas como usted",
              "100": "Por cada 100 personas como usted"
            },
            legendMap:{
              "die": "murió de cáncer de pulmón",
              "not die": "did not die from lung cancer"
            }
          },
          {
            chartTitle:"Con prueba detección temprana",
            legendTitle: {
              "1000": "Por cada 1000 personas como usted", 
              "100": "Por cada 100 personas como usted"
            },
            legendMap:{
              "die": "murió de cáncer de pulmón",
              "fewer die": {
                "full": "personas menos murieron de cáncer de pulmón debido a la detección temprana",
                "list": ["personas menos murieron de cáncer de ",  "pulmón debido a la detección temprana"]
              },
              "not die": "no murió de cáncer de pulmón"
            }
          },
        ],
        benefit_confirmation:{
          label: "Chequeo de comprensión: según la información anterior, considero que los posibles beneficios de la prueba de detección, en relación con mi riesgo de desarrollar cáncer de pulmón en los próximos 6 años, son ",
          placeholder: "",
          options: [
            { label: "Considerables", value: "High enough" },
            //{ label: "Intermedio", value: "Intermediate" },
            { label: "Bajos", value: "Too low" },
            { label: "No sé", value: "Don't know" }
          ]
        },
        risk_section: {
          title: "Riesgos",
          statement_list: [
            "120 de cada 1000 personas que se realicen la prueba de detección encontrarían un nódulo pulmonar que no es cáncer.",
            "13 de cada 1000 personas tendrían un procedimiento invasivo, como una biopsia o cirugía, debido a un nódulo pulmonar que no es cáncer.",
            "Menos de 1 de cada 1000 personas tendrían una complicación severa debido a estos procedimientos invasivos.",
            "De los cánceres pulmonares encontrados por las pruebas de detección, menos de 1 de cada 10 nunca hubiera causado síntomas ni molestias (sobre-diagnóstico), lo cual llevará a tratamientos y complicaciones innecesarias."
          ]
        },
        benefit_section: {
          title: "Beneficios",
          statement_end:
            "menos muertes por cáncer de pulmón por cada 1000 que se realicen la prueba de detección temprana, comparado con 1000 personas que no se la realicen."
        },
        instructions: {
          beforeSubmission: [
            "Ahora tendrá una mejor idea de lo que la detección temprana por TC es y que puede o no puede hacer. Será útil que su médico entienda como se siente acerca de lo bueno y lo mala de la detección temprana por TC. Por favor seleccione cual frase de las siguientes mejor describe so respuesta a la siguiente pregunta. No hay respuesta correcta o incorrecta.",
            "DESDE SU PUNTO DE VISTA, ¿SU BENEFICIO PERSONAL DE LA DETECCIÓN TEMPRANA JUSTIFICA HACER LA PRUEBA DE DETECCIÓN TEMPRANA POR TC? ELIJA UNA OPCIÓN DE LAS SIGUIENTES."
          ],
          afterSubmission: [
            "Discuta con su médico/a sobre la prueba de detección temprana por TC!"
          ]
        },
        default_select_label: " -- seleccionar -- ",
        submit_button_label: "Enviar",
        click_for_pdf_msg:
          "Da clic en la imagen para ver una versión reducida para impresora.",
        getEligibilityStatement: function(sex, risk, eligibility, insurance, old, packYears, neverSmoked) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown
          // Old = -1;
          // yearsSmoking = -1;
          // cigarettesPerDay = -1;


          var result = "Default Eligibility";

          if(neverSmoked){
            result = <div>La detección temprana de cáncer de pulmón con tomografía computarizada NO se recomienda para personas que nunca han fumado.</div>;
            return result;
          }

          switch (eligibility) {
            case "yes":
              result = "";

              if (insurance == "yes") {
                // M
                result = (
                  <div>
                    De acuerdo a su edad e historial de consumo de tabaco, el
                    grupo de trabajo de Servicios Preventivos de los Estados
                    Unidos (USPSTF, por sus siglas en inglés -- US Preventive
                    Services Task Force) <strong>recomienda*</strong> la prueba
                    de detección temprana para usted.
                  </div>
                );
              } else {
                result = (
                  <div>
                    De acuerdo a su edad e historial de consumo de tabaco, el
                    grupo de trabajo de Servicios Preventivos de los Estados
                    Unidos (USPSTF, por sus siglas en inglés -- US Preventive
                    Services Task Force) <strong>recomienda</strong> la prueba
                    de detección temprana para usted. Sin embargo Medicare
                    solamente cubre el costo hasta los 77 años de edad. Por
                    favor, vaya a nuestra &nbsp;
                    <Link
                      to={"/Español/cobertura"}
                      style={{ width: "100%" }}
                      data-on="click"
                      data-event-category="CoveragePage"
                      data-event-action={"/Español/cobertura"}
                    >
                      página de cobertura de seguro&nbsp;
                    </Link>{" "}
                    para mayor información, o consulte con su proveedor de
                    seguro médico para ver si tiene otras opciones para cubrir
                    el costo.
                  </div>
                );
              }
              break;
            case "no":
              result = (
                <div>
                  De acuerdo a su edad e historial de consumo de tabaco, el
                  grupo de trabajo de Servicios Preventivos de los Estados
                  Unidos (USPSTF, por sus siglas en inglés -- US Preventive
                  Services Task Force) <strong>no recomienda</strong> la prueba
                  de detección temprana para usted.
                </div>
              );

              result = (<p>
                De acuerdo a su edad e historial de consumo de tabaco, el
                grupo de trabajo de Servicios Preventivos de los Estados
                Unidos (USPSTF, por sus siglas en inglés -- US Preventive
                Services Task Force) <strong>no recomienda</strong> la prueba
                de detección temprana para usted.
            </p>
              );
              break;
            default:
              result = "";
              break;
          }

          return result;
        },
        getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // medicare: yes, no, unknown
          var result = "Default Chance";

          console.log(`getChanceStatement(), old: ${old}, packYears: ${packYears}`);

          let  tailorMessage = "";

          let oldInRange = old >= 50 && old <=54;
          let packYearsInRange = packYears >= 20 && packYears <=29;

          if(oldInRange && packYearsInRange){
            tailorMessage = `50-54 años y un historial de tabaquismo de 20 a 29 paquetes-año`;
          }
          else if(!oldInRange && packYearsInRange){
            tailorMessage = `un historial de tabaquismo de 20 a 29 paquetes-año`;
          }
          else if(oldInRange && !packYearsInRange){
            tailorMessage = `50-54 años`;
          }

          if (risk == 0) {
            // smoker or not
            result = (
              <div>
                Su probabilidad de desarrollar cáncer de pulmón en los
                siguientes 6 años es muy baja (menor a 0.1%). Si tiene preguntas
                sobre su riesgo de cáncer de pulmón, hable con su doctor/a ya
                que el/ella le dará la mejor recomendación de acuerdo a su
                situación.
              </div>
            );
          }
          else if (eligibility == "no") {
            result = (<Fragment>
              <p>
                *Sin embargo, su probabilidad de que desarrolle cáncer de
                  pulmón en los siguientes 6 años es de {risk}%, lo cual está
                  por {risk >= 1.5? "encima": "abajo"} del límite a partir del cual creemos que la prueba
                  tiene suficientes beneficios. Usted debería considerar hablar
                  con su doctor/a para saber si en este caso la prueba de
                  detección temprana es una buena opción para usted.
              </p>
              <p>
              El Grupo de Trabajo de Servicios Preventivos de EE. UU. está actualizando sus recomendaciones y tal vez aumente el rango de edades y el nivel mínimo de consumo de cigarro para ser elegible para la prueba de detección. Como tiene {tailorMessage}, debe consultar con su proveedor de atención médica sobre esto, o visite el <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/uspstf/draft-update-summary/lung-cancer-screening-2020"
                rel="noopener noreferrer"
              >
                sitio web del Grupo de Trabajo de Servicios Preventivos de EE. UU.
              </a> para obtener las últimas actualizaciones.
              </p>
              </Fragment>
            );
          } else {
            result = (
              <div>
                Su probabilidad de desarrolar cáncer de pulmón en los
                  siguientes 6 años es {risk}%. Hable con su doctor/a para
                  discutir si usted debe realizarse la prueba de detección
                  temprana de cáncer de pulmón, ya que el/ella le dará la mejor
                  recomendación de acuerdo a su situación.
              </div>
            );
          }

          return result;
        },
        getExplanationStatement(sex, risk, eligibility, insurance) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown
          var result = "Default Explanation";

          // Math.round()

          // deal with it.
          result = (
            <div>
              Comparado con otras personas similares a usted, habría{" "}
              {MyCalculation.calculateSavedByScreeningFromRisk(risk) +
                " "}
              menos muertes por cáncer de pulmón en los siguientes 6 años por
              cada 1000 personas que se realicen la prueba de detección.
            </div>
          );

          return result;
        },
        getBenefitTitle(sex, risk, eligibility, insurance) {
          // sex: male, female
          // risk: 0.0 ~
          // eligibility: yes, no
          // insurance: yes, no, unknown

          // we only need risk

          var result = "Default Benefit";

          result =
            MyCalculation.calculateSavedByScreeningFromRisk(risk)  +
            " in 1000";

          return result;
        },
        reflection_questions: {
          formPersonalBenefit: {
            label: "",
            placeholder: "",
            options: [
              {
                label:
                  "Sí. Mi beneficio de la detección temprana es suficiente para justificar hacer la prueba por TC.",
                value:
                  "Yes. My benefit from screening is enough to justify getting screened."
              },
              {
                label:
                  "Tal vez. Mi  beneficio de la detección temprana es probablemente suficiente para justificar hacer la prueba por TC pero no estoy seguro/a.",
                value:
                  "Maybe. My benefit from screening is probably enough to justify getting screened but I am not sure."
              },
              {
                label:
                  "Tal vez no. My beneficio de la detección temprana es pequeño y no estoy seguro/a que justifique hacer la prueba por TC.",
                value:
                  "Maybe not. My benefit from screening is small and I'm not sure it justifies getting screened."
              },
              {
                label:
                  "No. Mi beneficio de la detección temprana por TC es muy pequeño para justificar hacer la prueba por TC.",
                value:
                  "No. My benefit from screening is too small to justify getting screened."
              }
            ]
          },
          formRecommended: {
            label: "Yo fui:",
            placeholder: "",
            options: [
              {
                label:
                  "Recomendado/a para hacer la prueba de detección temprana por TC",
                value: "Recommended to be screened"
              },
              {
                label:
                  "No recomendado/a para hacer la prueba de detección temprana por TC",
                value: "Not recommended to be screened"
              }
            ]
          },
          formRisk: {
            label: "Mi riesgo era",
            placeholder: "1%"
          },
          formReason: {
            label: "Mis razones son:",
            placeholder: ""
          }
        }
      },
      coveragePage: {
        banner_image_source: "/image/bannerImage_coveragePage.png",
        banner_title: "Cobertura de la prueba por seguro de gastos médicos",
        title:
          "Seleccione la opción que le corresponda para ver si su seguro médico cubre la prueba de detección temprana de cáncer de pulmón.",
        // content: "The following information is from the American Lung Association.",
        condition_list: [
          {
            title: "Cobertura patrocinada por su empleador",
            introtext:
              "Este es el seguro médico patrocinado por su empleador, sindicato u otra organización profesional. Su seguro cubre la prueba de detección temprana de cáncer de pulmón si:",
            criteria_list: [
              "tiene entre 55 y 80 años de edad",
              "ha fumado en algún momento durante los últimos 15 años",
              <div>
                ha fumado en su vida al menos el equivalente a una cajetilla (o
                paquete) de cigarros o cigarrillos al día por 30 años (es decir,
                al menos ha consumido 30 paquete-años). Pulse&nbsp;
                <Link
                  to={"/Español/calculadora-de-paquete-anos"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/Español/calculadora-de-paquete-anos"}
                >
                  aquí&nbsp;
                </Link>
                para calcular cuántos paquete-años ha consumido.
              </div>
            ],

            note:
              "Si tiene un plan de “derechos adquiridos” bajo Obamacare, la ley no requiere que su plan cubra la prueba de detección temprana de cáncer de pulmón. Consulte la documentación de su seguro médico y verifique con la compañía de seguros si tiene o no cobertura.",
            question_answer_list: [
              {
                question: "¿Tengo que hacer copago?",
                answer:
                  "No. Usted no debería de pagar copago, co-seguro o deducible por la prueba de detección temprana si va a un proveedor dentro de su red de proveedores. "
              },
              {
                question: "¿Hay otros costos?",
                answer:
                  "Tal vez. Por favor confirme con su compañía de seguros cuál es exactamente la cobertura y posibles costos asociados."
              }
            ]
          },
          {
            title: "Mercado (intercambio) de seguros médicos estatal",
            introtext:
              "Este son los planes a los que uno se inscribe en healthcare.gov o en un Mercado/intercambio estatal. Su seguro cubre la prueba de detección temprana de cáncer de pulmón si:",
            criteria_list: [
              "tiene entre 55 y 80 años de edad",
              "ha fumado en algún momento durante los últimos 15 años",
              <div>
                ha fumado en su vida al menos el equivalente a una cajetilla (o
                paquete) de cigarros o cigarrillos al día por 30 años (es decir,
                al menos ha consumido 30 paquete-años). Pulse&nbsp;
                <Link
                  to={"/Español/calculadora-de-paquete-anos"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/Español/calculadora-de-paquete-anos"}
                >
                  aquí
                </Link>{" "}
                para calcular cuántos paquete-años ha consumido.
              </div>
            ],
            question_answer_list: [
              {
                question: "¿Tengo que hacer copago?",
                answer:
                  "No. Usted no debería de pagar copago, co-seguro o deducible por la prueba de detección temprana si va a un proveedor dentro de su red de proveedores."
              },
              {
                question: "¿Hay otros costos?",
                answer:
                  "Tal vez. Por favor confirme con su compañía de seguros cuál es exactamente la cobertura y posibles costos asociados."
              }
            ]
          },
          {
            title: "Medicare",
            introtext:
              "Medicare cubre la prueba de detección temprana de cáncer de pulmón si:",
            criteria_list: [
              "tiene entre 55 y 77 años de edad",
              "ha fumado en algún momento durante los últimos 15 años",
              <div>
                ha fumado en su vida al menos el equivalente a una cajetilla (o
                paquete) de cigarros o cigarrillos al día por 30 años (es decir,
                al menos ha consumido 30 paquete-años). Pulse&nbsp;
                <Link
                  to={"/Español/calculadora-de-paquete-anos"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/Español/calculadora-de-paquete-anos"}
                >
                  aquí
                </Link>{" "}
                para calcular cuántos paquete-años ha consumido.
              </div>,
              "No tiene síntomas aparentes de cáncer de pulmón (por ejemplo, dolor en el pecho, baja de peso y tener tos recurrente todos los días)"
            ],
            question_answer_list: [
              {
                question: "¿Tengo que hacer copago?",
                answer:
                  "No. Usted no debería de pagar copago, co-seguro o deducible por la prueba de detección temprana si va a un proveedor dentro de su red de proveedores."
              },
              {
                question: "¿Hay otros costos?",
                answer:
                  "Tal vez. Por favor confirme con su compañía de seguros cuál es exactamente la cobertura y posibles costos asociados."
              }
            ]
          },
          {
            title: "Medicaid",
            introtext:
              "Cobertura de la prueba de detección temprana por Medicaid varía por estado. Le sugerimos contactar a su proveedor de Medicaid para ver si su plan cubre la prueba.",
            criteria_list: [],
            note: "",
            question_answer_list: []
          },
          {
            title: "Plan individual",
            introtext:
              "Estos son los planes que usted compra directamente con una compañía de seguros. Su seguro cubre la prueba de detección temprana de cáncer de pulmón si:",
            criteria_list: [
              "tiene entre 55 y 80 años de edad",
              "ha fumado en algún momento durante los últimos 15 años",
              <div>
                ha fumado en su vida al menos el equivalente a una cajetilla (o
                paquete) de cigarros o cigarrillos al día por 30 años (es decir,
                al menos ha consumido 30 paquete-años). Pulse&nbsp;
                <Link
                  to={"/Español/calculadora-de-paquete-anos"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="CoveragePage"
                  data-event-action={"/Español/calculadora-de-paquete-anos"}
                >
                  aquí
                </Link>{" "}
                para calcular cuántos paquete-años ha consumido.
              </div>
            ],

            note:
              "Si tiene un plan de “derechos adquiridos” bajo Obamacare, la ley no requiere que su plan cubra la prueba de detección temprana de cáncer de pulmón. Consulte la documentación de su seguro médico y verifique con la compañía de seguros si tiene o no cobertura.",
            question_answer_list: [
              {
                question: "¿Tengo que hacer copago?",
                answer:
                  "No. Usted no debería de pagar copago, co-seguro o deducible por la prueba de detección temprana si va a un proveedor dentro de su red de proveedores."
              },
              {
                question: "¿Hay otros costos?",
                answer:
                  "Tal vez. Por favor confirme con su compañía de seguros cuál es exactamente la cobertura y posibles costos asociados."
              }
            ]
          }
        ],
        citation_text: (
          <div>
            <p>
              La fuente de información:&nbsp;
              <a
                target="_blank"
                href="https://www.lung.org/assets/documents/lung-health/lung-cancer-insurance-chart.pdf"
                rel="noopener noreferrer"
              >
                American Lung Association
              </a>
              .
            </p>
          </div>
        )
      },
      publicationPage: {
        title: "Research Publications",
        publication_list: [
          {
            category: "conference",
            text: (
              <span>
                Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza
                (2019). Designing a Web-based Decision Aid for Individuals to
                Consider Lung Cancer Screening.{" "}
                <i>
                  13th EAI International Conference on Pervasive Computing
                  Technologies for Healthcare (PervasiveHealth)
                </i>
                , Trento, Italy, May 20-23. pp 51-60
              </span>
            ),
            year: 2019,
            description:
              "Llevamos a cabo talleres de diseño colaborativo y grupos focales para obtener sugerencias de pacientes sobre el diseño y material de contenido de shouldiscreen.com.",
            links: [
              {
                label: "Link",
                link: "https://doi.org/10.1145/3329189.3329210"
              }
            ]
          },
          {
            category: "journal",
            text: (
              <span>
                Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
                Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
                Evaluation of a Personalized, Web-Based Decision Aid for Lung
                Cancer Screening.{" "}
                <i>American Journal of Preventive Medicine (AJPM)</i>, Volume
                49, Issue 6, Pages e125–e129
              </span>
            ),
            year: 2015,
            description:
              "Evaluamos si shouldiscreen.com funciona como una herramienta de aprendizaje de las causas y los factores de riesgo de cáncer de pulmón, y de los beneficios y limitaciones de la prueba de detección temprana, y si ayuda a las personas a tomar una mejor decisión al respecto.",
            links: [
              {
                label: "Link",
                link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/"
              }
            ]
          },
          {
            category: "journal",
            text: (
              <span>
                Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
                Mindy West, Douglas Arenberg, and Rafael Meza (2014).
                Development and Validation of a Personalized, Web-Based Decision
                Aid for Lung Cancer Screening Using Mixed Methods: A Study
                Protocol. <i>JMIR Research Protocol</i>
              </span>
            ),
            year: 2014,
            description:
              "Este es el protocola de investigación que describe los pasos que seguimos para crear shouldiscreen.com.",
            links: [
              {
                label: "Link",
                link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/"
              }
            ]
          }
        ]
      },
      savedPage: {
        title: "Aquí está la información que guardo.",
        item_unit: "snippet(s)",
        print_button_label: "Imprimir"
      },
      teamPage: {
        title: "Desarrolladores de shouldiscreen.com",

        investigator_title: "INVESTIGADORES PRINCIPALES",

        investigator_list: [
          {
            name: "Rafael Meza, PhD",
            affiliation: [
              "Departamento de Epidemiología, Escuela de Salud Pública, Universidad de Michigan"
            ],
            link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html"
          },
          {
            name: "Douglas Arenberg, MD",
            affiliation: [
              "Departamento de Medicina Interna, Universidad de Michigan"
            ],
            link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md"
          },
          {
            name: "Lisa Lau, PhD, MPH",
            affiliation: [
              "Departamento de Epidemiología, Escuela de Salud Pública, Universidad de Michigan"
            ],
            link: ""
          },
          {
            name: "Tanner Caverly, MD, MPH ",
            affiliation: [
              "Centro de Investigaciones para el Manejo Clínico, Oficina de Veteranos, Sistema de Salud Ann Arbor; Departamento de Medicina Interna, Universidad de Michigan"
            ],
            link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph"
          }
        ],

        research_development_title: "EQUIPO DE INVESTIGACIÓN DESARROLLO",

        research_development_list: [
          {
            name: "Pianpian Cao, MPH",
            affiliation: [
              "Departamento de Epidemiología, Escuela de Salud Pública, Universidad de Michigan"
            ],
            link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html"
          },
          {
            name: "Sarah Cherng, PhD, MPH",
            affiliation: [
              "Departamento de Epidemiología, Escuela de Salud Pública, Universidad de Michigan"
            ],
            link: "http://www.sarahcherng.com/"
          },
          {
            name: "Pei-Yao Hung, MSI",
            affiliation: ["Escuela de Información, Universidad de Michigan"],
            link: "https://peiyaoh.github.io/"
          },
          {
            name: "Mindy West, MPH",
            affiliation: [
              "Departamento de Epidemiología, Escuela de Salud Pública, Universidad de Michigan"
            ],
            link: ""
          }
        ],

        press_title: "EN LA PRENSA",

        press_resources_list: [
          {
            link:
              "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
            label:
              "Tool helps patients decide if lung cancer screening is warranted",
            source_label: "Health Canal"
          },
          {
            link:
              "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
            label:
              "Web module helps patients decide about lung cancer screening",
            source_label: "Reuters"
          },
          {
            link:
              "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
            label:
              "On Medicare and assessing the value of lung cancer screening",
            source_label: "New York Times"
          },
          {
            link:
              "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
            label:
              "Tool helps patients, providers decide if lung cancer screening is warranted",
            source_label: "University of Michigan News"
           }
        ],
        founding_title: "APOYO FINANCIERO",
        founding_content:
          "El desarrollo de esta herramienta fue apoyado por la Fundación Elizabeth A Crary del Centro Integral de Cáncer de la Universidad de Michigan."
      },
      historyPage: {
        title: "The development history of shouldiscreen.com",
        description: <Fragment>
          <p>Shouldiscreen.com is a lung cancer screening decision aid that is designed, evaluated, and re-designed continually based on existing literature, multidisciplinary research effort, health experts' suggestions, and feedback from users.</p>
          <p>Our goal is to continually improve it by keeping it up to date with the current understanding of lung cancer screening so that people can obtain a better understanding of relevant scientific knowledge and best practices.</p>
          <p>Here we outline a list of milestones and changes in the development of shouldicreen.com.</p>
        </Fragment>,
        eventList: [
          // marker_color:  color from bulma.css: is-primary, is-link, is-info, is-success, is-warning, is-danger
          // markder_type: "", is-image, is-icon
          {
            label: "September 2017",
            description: "Enable bi-lingual support with Spanish content.",
            timestamp: "2017-09-01T00:00:00",
            marker_color: "is-danger",
            marker_type: "is-icon",
            // fontawesome
            marker_icon_type: "fa fa-flag"
          },
          {
            label: "July 2018",
            description: <Fragment>Added insurance coverage based on observations and feedback from participatory design and focus group studies (see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2018-07-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "September 2018",
            description: <Fragment>Added a save-for-later feature for people to collect relevant content based on a conversation with clinicians who conduct shared decision-making with patients.</Fragment>,
            timestamp: "2018-09-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "September 2018",
            description: <Fragment>Added a pop-up menu for people to provide feedback (helpful/not helpful) on content.</Fragment>,
            timestamp: "2018-09-15T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "December 2019",
            description: <Fragment>Added a question-based navigation menu based on observations from participatory design and focus group studies (see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2019-12-01T00:00:00",
            marker_color: "is-link"
          },
          {
            label: "May 2019",
            description: <Fragment>Presented our participatory design and focus group research on the design of shouldiscreen.com at the <i>13th EAI International Conference on Pervasive Computing Technologies for Healthcare</i> (PervasiveHealth 2019)(see <a
              target="_blank"
              href="https://dl.acm.org/doi/10.1145/3329189.3329210"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2019-05-21T00:00:00",
            marker_color: "is-warning"
          },
          {
            label: "December 2019",
            description: <Fragment>Updated screening eligibility criteria with Medicare.</Fragment>,
            timestamp: "2019-12-12T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "January 2020",
            description: <Fragment>Enabled tailoring of messages based on calculated lung cancer risk and biographical information.</Fragment>,
            timestamp: "2020-01-01T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "August 2020",
            description: <Fragment>Added multiple visualization options to help people understand screening benefits and risks.</Fragment>,
            timestamp: "2020-08-22T00:00:00",
            marker_color: "is-primary"
          },
          {
            label: "November 2020",
            description: <Fragment>Revised content to reflect findings in USPSTF evidence review (2020) with regards to harms from false positives when using LungRADS (see <a
              target="_blank"
              href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
              rel="noopener noreferrer"
            >
              link
            </a>).</Fragment>,
            timestamp: "2020-11-21T00:00:00",
            marker_color: "is-primary"
          },
        ]
      },
      footerComponent: {
        development_team_content: (
          <p>
            Elaborado por la &nbsp;
            <Link
              to={"/Español/equipo-investigación-desarrollo"}
              data-on="click"
              data-event-category="Footer"
              data-event-action={"team"}
            >
              Universidad de Michigan
            </Link>
            &nbsp; utilizando el modela de predicción de riesgo de cáncer de
            pulmón de Tammemägi et al. (2013).&nbsp;
            <a
              target="_blank"
              href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
              rel="noopener noreferrer"
            >
              Selection criteria for lung-cancer screening
            </a>
            .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
            2013.
          </p>
        ),

        basis_content: (
          <p>
            Se encuentran los artículos en revistas científicas sobre el diseño,
            el desarrollo y la validación de esta herramienta de ayuda para la
            toma de decisión{" "}
            <Link
              to={"/Español/publicaciones"}
              data-on="click"
              data-event-category="Navigation"
              data-event-action={"publications"}
            >
              aquí
            </Link>
            .
          </p>
        ),

        feedback_content: (
          <p>
            Sus comentarios son muy importantes para nosotros. Por favor, &nbsp;
            <Link to={"/Español/comentarios"}>complete esta encuesta</Link>
            &nbsp;para ayudarnos a mejorar este sitio web.&nbsp;
          </p>
        ),

        disclaimer_content: (
          <p>
            <Link to={"/Español/exención-de-responsabilidades"}>
              Exención de responsabilidades
            </Link>
            . &nbsp;
            <em>
              El contenido escrito de este sitio web es compartido con &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              >
                CC BY-NC-SA 4.0
              </a>
              .&nbsp;
            </em>
            Todas las imágenes de este sitio web son material con derechos de
            autor y todos los derechos son reservados a menos que se indique lo
            contrario.
          </p>
        )
      },
      noPage: {
        title: "Should I Screen: página no encontrada",
        not_found_message:
          "Está página no existe. Ha llegado aquí por error o hay algún problema en el sitio. Por favor haga clic en la liga siguiente.",
        take_me_home_label: "Llévame de regreso a la página inicial.",
        take_me_home_link: "/Español/inicio"
      },
      lastUpdateComponent: {
        message_head: "Se actualizó esta página por última vez el ",
        pointer_to_history: <Fragment>
            Ver <Link
                  to={"/Español/historia-de-desarrollo"}
                  data-on="click"
                  data-event-category="UpdateComponent"
                  data-event-action={"/Español/historia-de-desarrollo"}
                  className="my-link"
                >
                  la historia de los cambios
                </Link>.
        </Fragment>
      },
      headerComponent: {
        title: "Cáncer de pulmón y la detección temprana",
        menu_title: " Menú",
        route_in_menu_limit: 8, //9
        route_order_list: [
          {
            route: "/Español/inicio",
            label: " Inicio",
            title:
              "Detección temprana de cáncer de pulmón con tomografía computarizada ¿Debo hacérmela?",
            page: "HomePage"
          },
          {
            route: "/Español/que-es-la-deteccion-del-cancer-de-pulmon",
            label: " Cáncer de pulmón y pruebas de detección",
            title: "Cáncer de pulmón y pruebas de detección",
            page: "HowPage"
          },
          {
            route: "/Español/cobertura",
            label: " Cobertura",
            title:
              "La cobertura de los exámenes de detección del cáncer de pulmón",
            page: "CoveragePage"
          },
          {
            route: "/Español/beneficios-y-efectos-adversos-de-la-prueba",
            label: " Beneficios y efectos adversos de la prueba",
            title: "Beneficios y efectos adversos del tamizaje",
            page: "BenefitHarmPage"
          },
          /*
          {
            route: "/Español/comparado-con-otras-pruebas-de-deteccion-temprana",
            label: "Comparado con otras pruebas de detección temprana",
            title: "Comparado con otros tamizajes de cáncer",
            page: "ComparePage"
          },
          */
          {
            route: "/Español/causas-del-cancer-de-pulmon",
            label: " Causas del cáncer de pulmón",
            title: "Causas del cáncer de pulmón",
            page: "CausePage"
          },
          {
            route: "/Español/calculadora-de-paquete-anos",
            label: " Calculadora de paquete-años",
            title: "Calcular paquete-años",
            page: "PackPage"
          },
          {
            route: "/Español/reducir-riesgo-de-cancer-de-pulmon",
            label: " Reducir riesgo de cáncer de pulmón",
            title: "Reducir riesgo de cáncer de pulmón",
            page: "RiskPage"
          },
          {
            route: "/Español/calculadora-del-riesgo-de-cancer-de-pulmon",
            label: " Calcular mi riesgo de cáncer de pulmón",
            title: "Calcular mi riesgo de cáncer de pulmón",
            page: "CalculatePage"
          },
          {
            route: "/Español/resultado",
            label: " Resultado",
            title: "Mi riesgo de cáncer de pulmón",
            page: "ResultPage"
          },

          {
            route: "/Español/exención-de-responsabilidades",
            label: " Exención De Responsabilidades",
            title: "Exención De Responsabilidades",
            page: "DisclaimerPage"
          },
          {
            route: "/Español/equipo-investigación-desarrollo",
            label: " Equipo Investigación Desarrollo",
            title: "Equipo Investigación Desarrollo",
            page: "TeamPage"
          },
          {
            route: "/Español/historia-de-desarrollo",
            label: "Historia De Desarrollo",
            title: "Historia De Desarrollo",
            page: "HistoryPage"
          },
          {
            route: "/Español/comentarios",
            label: " Comentarios",
            title: "Comentarios",
            page: "FeedbackPage"
          },
          {
            route: "/Español/guardado",
            label: " Guardado",
            title: "Información guardado",
            page: "SavedPage" //"PáginaGuardado"
          },
          {
            route: "/Español/publicaciones",
            label: " Publicaciones",
            title: "Publicaciones",
            page: "PublicationPage"
          }
        ]
      },
      savedComponent: {
        saved_label: "Guardado",
        saved_url: "/Español/guardado"
      }
    }
  };
}

