import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class MyDropdown extends Component {
  constructor(props) {
    super(props);

    this.state={
        isActive: false
    };

    this.onMenuClick = this.onMenuClick.bind(this);

  }

  onMenuClick(event){
    const {isActive} = this.state;

    this.setState({
        isActive: !isActive
    });
  }

  render() {
    const { itemList, itemIndex, itemLimit, menuTitle } = this.props;
    const { isActive } = this.state;
    //<Flexbox flexDirection="column" minHeight="80vh" width="100%">
    //console.log("Accodion content: " + JSON.stringify(list, null, 2));

    return (
      <div className={"dropdown " + (isActive? "is-active":"") }>
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={(event)=>{this.onMenuClick(event)}}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "33vw",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {itemIndex == 0
                ? menuTitle
                : itemList[itemIndex].label}
            </div>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {itemList
              .slice(0, itemLimit)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    href="#"
                    className="dropdown-item"
                    style={{
                      maxWidth: "72vw"
                    }}
                  >
                    <Link
                      to={item.route}
                      style={{ width: "100%", textDecoration: "none" }}
                      data-on="click"
                      data-event-category="Header"
                      data-event-action={item.route}
                    >
                      <div className="is-clipped">{item.label}</div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}


// each item
/*
<div className="hero is-desktop is-mobile" style={{margin: 10, padding: 5}}>
<div className="level">
  <div className="level-left">
    <div className="level-item is-size-5 is-size-6-mobile">
      <div className="has-text-left" style={{textOverflow: "ellipsis"}}>{item.title}</div>
    </div>
  </div>
  <div className="level-right">
    <div className="level-item">
      {status_list[index]?
      (<Glyphicon glyph="glyphicon glyphicon-chevron-up" />) :
      (<Glyphicon glyph="glyphicon glyphicon-chevron-down" />)
      }
    </div>
  </div>
</div>
</div>
*/

// version 1
/*
return (
  <AccordionItem key={index} expanded={index == 0 ? true : false}>
    <AccordionItemTitle>
      <div className="u-position-relative">
        {item.title}
        <div className="accordion__arrow" role="presentation" />
      </div>
    </AccordionItemTitle>
    <AccordionItemBody ref="accordionBody" >{item.content}</AccordionItemBody>
  </AccordionItem>
);
*/
