import React from "react";
import { Vega } from "react-vega";
import {Decimal} from 'decimal.js';
import MyVega from "./MyVega";
//const sideLength = 40;

const symbolShape = "square";

export default function MyIconArray(props) {
  let deviceType = props.deviceType != undefined? props.deviceType: "desktop";
  let chartData = props.data;
  let chartTitle = chartData.chartTitle;
  let legendTitle = chartData.legendTitle;
  let legendOffset = props.legendOffset;

  let chartWidth = props.width != undefined? props.width: 400;
  let chartHeight = props.height != undefined? props.height: 400;

  let columnCount = props.columnCount != undefined? props.columnCount: 10;

  let sideLength = props.sideLength != undefined? props.sideLength: 40;

  let chartDataFlatten = {
    table: [],
  };

  let proportionIndex = 0;

  let amountDrew = new Decimal(0);

  let unitFilled = new Decimal(0);

  for (let j = 0; j < chartData.table.length; j++) {
    proportionIndex = j;
    let amountToDraw = new Decimal(chartData.table[proportionIndex].amount);
    //console.log("Amount for this proprotion: "+ amountToDraw);

    //console.log("amountToDraw.greaterThan(Decimal(0)): "+ amountToDraw.greaterThan(Decimal(0)));

    // amountDraw
    while(amountToDraw.greaterThan(Decimal(0))){

      let unitAmountToDraw = new Decimal(0);

      if(amountDrew.floor().equals(amountDrew)){
        unitAmountToDraw = amountToDraw.greaterThanOrEqualTo(Decimal(1))? Decimal(1): amountToDraw;
      }
      else{
        unitAmountToDraw = amountToDraw.greaterThanOrEqualTo(amountDrew.ceil().minus(amountDrew))? amountDrew.ceil().minus(amountDrew): amountToDraw;
      }

      //(1 - (amountDrew * 100 - Math.floor(amountDrew) * 100)/100);
      //console.log("unitAmountToDraw: "+ unitAmountToDraw);

      let xPos = amountDrew.mod(Decimal(columnCount)).floor();
      let yPos = amountDrew.dividedToIntegerBy(Decimal(columnCount)).add(amountDrew.minus(amountDrew.floor()));
      //console.log("xPos: "+ xPos);
      //console.log("yPos: "+ yPos);

      chartDataFlatten.table.push({
        id: chartDataFlatten.table.length,
        category: chartData.table[proportionIndex].category,
        color: chartData.table[proportionIndex].color,
        amount: 1,
        height: unitAmountToDraw * sideLength,// unitAmountToDraw.times(Decimal(sideLength)),
        x: sideLength/2 + xPos * sideLength, //xPos.times(Decimal(sideLength)),
        y: sideLength/2 + yPos * sideLength, //yPos.times(Decimal(sideLength)),
        fillOpacity: chartData.table[proportionIndex].fillOpacity
      });

      amountDrew = amountDrew.add(unitAmountToDraw);
      amountToDraw = amountToDraw.minus(unitAmountToDraw);
      //console.log("amountDrew: "+ amountDrew);
      //console.log("Amount to draw: "+ amountToDraw);
    }
  }

  let specWaffle = {
    width: chartWidth, //400,
    height: chartHeight, //400,
    //"autosize": {"type": "pad", "resize": true},
    //"padding": 20,

    data: [{ name: "table" }],
    usermeta: {
      embedOptions: {
        actions: false
      }
    },
    // disable and let the parent component MyVega to render
    
    title: {
      text: { value: chartTitle },
      fontSize: 26,
      anchor: "start",
      baseline: "top",
      offset: 25,
      //frame: "group",
    },


    autosize: { type: "fit", contains: "padding" },
    /*
    signals: [
      {
        name: "width",
        init: "containerSize()[0]",
        on: [{ events: "window:resize", update: "containerSize()[0]" }]
      },
      {
        name: "height",
        init: "containerSize()[1]",
        on: [{ events: "window:resize", update: "containerSize()[1]" }]
      }
    ],
    */
    scales: [
      {
        name: "color",
        type: "ordinal",
        //"range": {"scheme": "category10"},
        domain: { data: "table", field: "category" },
        range: { data: "table", field: "color" },
      },
      /*
      {
        name: "fillOpacity",
        type: "linear",
        //"range": {"scheme": "category10"},
        domain: { data: "table", field: "category" },
        range: { data: "table", field: "fillOpacity" },
      },
      */
    ],

    legends: [
      {
        title: legendTitle,
        titleFontSize: 18,
        titleLimit: chartWidth,
        titleAnchor: "start",
        titleBaseline: "top",

        //clipHeight: 150,
        
        
        //type: {value: "symbol"},
        
        symbolType: {value: symbolShape},
        
        symbolSize: 196,
        //symbolOffset: -10,
        

        labelFontSize: 14,
        labelLimit:  chartWidth, //deviceType == "tablet"? 250: 300,
        //"symbolLimit": 3,
        stroke: "color",
        fill: "color",
        //opacity: "fillOpaciy",
        orient: "top",
        //orient: "none",
        offset: {value: legendOffset},
        //padding: 15,

        /*
        encode: {
          "symbols": {
            "update": {
              "fill": [{ field: "color" }],
              "strokeWidth": {"value": 1},
              "opacity": "fillOpacity",
            }
          },
          "labels": {
            "update": {
              "opacity": [
                 {"value": 1.0}
              ]
            }
          }
        }
        */
      },
    ],



    marks: [
      {
        type: "symbol",
        from: { data: "table" },
        encode: {
          enter: {
            shape: {value: symbolShape},
            size: { value: sideLength * sideLength },
            width: {value: sideLength},
            height:  [{ field: "height" }], //{value: sideLength},
            fill: [{ field: "color" }],
            fillOpacity: [{ field: "fillOpacity" }],
            stroke: { value: "white" },
            strokeWidth: { value: 1.0 },
          },
          update: {
            x: { field: "x" },
            y: { field: "y" },
          },
        },
      },
    ],
  };

  //console.log(chartDataFlatten);

  // method 2:use MyVega to provide layout
  return <MyVega spec={specWaffle} data={chartDataFlatten} chartTitle={props.data.chartTitle} legendTitle={props.data.legendTitle}/>;



  // method 1: use Vega directly
  //return <Vega spec={specWaffle} data={chartDataFlatten} />;
}


/*
  "data": [
    {
      "name": "temperature",
      "url": "https://vega.github.io/vega-editor/app/data/seattle-temps.csv",
      "format": {"type": "csv", "parse": {"temp": "number", "date": "date"}},
      "transform": [
        {"type": "formula", "field": "hour", "expr": "hours(datum.date)"},
        { "type": "formula", "field": "date",
          "expr": "datetime(year(datum.date), month(datum.date), date(datum.date))"},
          { "type": "filter", "test": "month(datum.date) < 1 && date(datum.date) <= 5 && datum.hour <= 5" }
      ]
    }
  ],
  */
/*
  "scales": [
    {
      "name": "x",
      "type": "ordinal",
      "domain": {"data": "table", "field": "xPos"},
      "range": "width"
    }, {
      "name": "y",
      "type": "ordinal",
      "domain": {"data": "table", "field": "yPos"},
      "range": "height",
      "round": false
    }, {
      "name": "c",
      "type": "linear",
      "domain": [37.5, 41.3, 45.1, 48.9, 52.7, 56.5, 60.3, 64.1, 67.9, 71.7, 75.5],
      "range": ["#313695", "#4575b4", "#74add1", "#abd9e9", "#e0f3f8", "#ffffbf", "#fee090", "#fdae61", "#f46d43", "#d73027", "#a50026"],
      "zero": false
    }
  ],
  */

/*
  {test: "datum.category == 'P'", value: proportionColor},
  { value: nonProportionColor }
  */