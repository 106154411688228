import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";

import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";

import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";


const buttonStyle = {
  width: 150
};

class RiskPage extends Component {
  constructor(props) {
    super(props);

    let linkLanguage = MyUtility.getLanguageFromURL(this.props.location.pathname)


    this.state = {
      value: 0,
      language: linkLanguage //localStorage.getItem("language")
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  handleChange = (event, index, value) => this.setState({ value });

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["riskPage"] != undefined) {
      pageContent = Content.all_content[language]["riskPage"];
    } else {
      pageContent = Content.all_content["English"]["riskPage"];
    }

    const { value } = this.state;

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content  has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <Banner
          src={pageContent.banner_image_source}
          title={pageContent.banner_title}
          heightOption = {language == "English"? "small" : "normal"}
          deviceType={deviceType}
        />

        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <p>{pageContent.description}</p>
            <ul>
              {pageContent.resources_quit.map((item, index) => {
                return (
                  <li key={index}>
                    {
                    item.link.length > 0? <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.label}
                  </a>:
                  <div>{item.label}</div>
                  }

                  </li>
                );
              })}
            </ul>
            <p>{pageContent.resources_instructions}</p>

            
            <DropdownButton
              title={
                pageContent.state_resource_list[value].state
              }
              id="bg-nested-dropdown"
              //bsStyle={{width: "100%"}}
            >
              {pageContent.state_resource_list.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    eventKey={index}
                    onClick={event => {
                      this.handleChange(event, index, index)
                    }}
                    style={{
                      listStyleType: "none",
                      paddingLeft: 0,
                      //paddingRight: 10,
                     // width: menuItemLength,
                      lineHeight: 2,
                    }}
                  >
                    {item.state}
                  </MenuItem>
                );
              })}
            </DropdownButton>
            <ul>
              {pageContent.state_resource_list[
                value
              ].links.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
            <p>{pageContent.radon_instructions}</p>
            <ul>
              {pageContent.resources_radon.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  </li>
                );
              })}
            </ul>
            <MyNavigator
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
            />
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default RiskPage;