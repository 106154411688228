import React, { Fragment } from "react";
import { Vega } from "react-vega";
import { Decimal } from "decimal.js";


export default function MyVega(props) {
  let chartData = props.data;
  let chartMode = props.mode == "" ? "random" : props.mode;
  let chartTitle = chartData.chartTitle;
  let legendTitle = chartData.legendTitle;

  let chartWidth = 400;
  let chartHeight = 400;

  // <div className="is-size-3 mb-2">{props.chartTitle}</div>

console.log("chartTitle: " + props.data.chartTitle);
  return <Vega spec={props.spec} data={props.data} />;
}
