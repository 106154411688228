import React, { Component } from "react";

import Radio from "react-bootstrap/lib/Radio";

import ControlLabel from "react-bootstrap/lib/ControlLabel";

import FormGroup from "react-bootstrap/lib/FormGroup";


const spaceStyle = {
  marginRight: 15
};

class RadioGroup extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    //console.log("RadioGroup.onChange: " + event.target.name);
    const { onChange } = this.props;

    onChange(event);
  }
  render() {
    //const { id, label, options, inline=false, help, ...props} = this.props;

    // not passing onChange directly to the FormGroup
    const {
      id,
      label,
      options,
      inline = false,
      displayHelp=false,
      help,
      onChange,
      //value,
      ...props
    } = this.props;

    return (
      <div>
        <ControlLabel>{label}</ControlLabel>
        <FormGroup controlId={id} {...props}>
          {options.map((item, index) => {
            return (
              <Radio
                key={index}
                name={id}
                onClick={this.onChange}
                inline={inline}
                value={item.value}
                style={spaceStyle}
                //checked={item.value == value}
              >
                {item.label}
              </Radio>
            );
          })}
          {displayHelp && <div style={{color: "red"}}>{help}</div>}
        </FormGroup>
      </div>
    );
  }
}

export default RadioGroup;