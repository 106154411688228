import React, { Component } from "react";
import moment from "moment";

import "../css/app.css";

const iconFrontStyle = {
  fontSize: 48
};

const lowerBannerStyle = {
  height: 150,
  backgroundColor: "#333333",
  verticalAlign: "middle",
};

const noMarginRightStyle={
  marginRight: 0
};

const textStyle = {
  //fontSize: 23,
  //width: "100%",
  //fontFamily: "adobe-garamond-pro",
  color: "#929292"
  //textAlign: "center"
};

class LastUpdate extends Component {
  constructor(props) {
    super(props);

    /*
    this.state = {
      language: localStorage.getItem("language")
    };
    */
  }

  shouldComponentUpdate(nextProps, nextState){
    return true;
  }

  render() {
    const { Content, language } = this.props;
    //const { language } = this.state;

    var pageContent = "";

    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["lastUpdateComponent"] != undefined
    ) {
      pageContent = Content.all_content[language]["lastUpdateComponent"];
    } else {
      pageContent = Content.all_content["English"]["lastUpdateComponent"];
    }

    //var pageContent = Content.all_content[language]["lastUpdateComponent"];

    // 3 months ago
    /*
    var date_str = moment()
      .subtract(3, "months")
      .format("MM/D/YYYY");
    */
    // try set it manually
    
    let dateSet = new Date(2021, 3, 9);
    var date_str = `${dateSet.getMonth()}/${dateSet.getDate()}/${dateSet.getFullYear()}`;


    // try acutal date
    //var date_str = moment(document.lastModified).format("MM/D/YYYY");

    var msg = pageContent.message_head + date_str;

    // <div className="content has-text-justified">

    return (
      
      <div className="has-text-justified">
        <div className="columns is-centered is-mobile" style={{...lowerBannerStyle, ...noMarginRightStyle}}>
          <div className="column is-three-quarters has-text-centered">
            <a
              href="mailto:shouldiscreen@umich.edu"
              target="_blank"
              rel="noopener noreferrer"
              className="my-link"
            >
              <i className="far fa-envelope fa-4x"></i>
            </a>
            <div className="is-size-5 is-size-6-mobile" style={textStyle}>
              <p>{msg}{`. `}{pageContent.pointer_to_history}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LastUpdate;

/*
<i className="material-icons" style={iconFrontStyle}>
mail_outline
</i>
*/
