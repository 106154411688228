import React, { Component } from "react";
//import "../css/app.css";


const footerStyle = {
  paddingTop: 20,
  backgroundColor: "#1F1F1F",
  verticalAlign: "middle",
  paddingBottom: 40,
  marginBottom: 0
};

const noMarginRightStyle={
  marginRight: 0
};

const textStyle = {
  //fontFamily: "adobe-garamond-pro",
  color: "#797979",
};



class Footer extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState){
    return true;
  }

  render() {
    const { Content, language } = this.props;
    //const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["footerComponent"] != undefined
    ) {
      pageContent = Content.all_content[language]["footerComponent"];
    } else {
      pageContent = Content.all_content["English"]["footerComponent"];
    }

    // <div className="content has-text-justified"  style={footerStyle}>


    return (
      <div className="content has-text-justified"  style={footerStyle} id="footerComponent">
        <div className="columns is-mobile is-centered" style={noMarginRightStyle}>
          <div className="column is-three-quarters" style={textStyle}>
          {pageContent.development_team_content}
          {pageContent.basis_content}
          {pageContent.feedback_content}
          {pageContent.disclaimer_content}
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
