import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
//import "../css/print.min.css";

//import "../js/print.min.js";

export default class MyPager extends Component {
  render() {
    const {
      link = "",
      label = "",
      arrowDirection = "right",
      maxWidth = "55vw"
    } = this.props;

    return (
      <Link to={link} style={{ width: "100%", textDecoration: "none" }}>
        <button className="button is-mobile is-centered has-text-centered is-rounded">
          {arrowDirection == "left" ? (
            <Fragment>
              <div
                style={{
                  //width: "100%",
                  textDecoration: "none",
                  maxWidth: maxWidth,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {
                  <Fragment>
                    <span>&larr;</span>&nbsp;<span>{label} </span>
                  </Fragment>
                }
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                style={{
                  //width: "100%",
                  textDecoration: "none",
                  maxWidth: maxWidth,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                  {label}
              </div>&nbsp;<span>&rarr;</span>
            </Fragment>
          )}
        </button>
      </Link>
    );
  }
}
