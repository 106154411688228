import React, { Component } from "react";

import ControlLabel from "react-bootstrap/lib/ControlLabel";
import FormControl from "react-bootstrap/lib/FormControl";
import FormGroup from "react-bootstrap/lib/FormGroup";


const labelStyle = {
  margin: 10
};

class SelectGroup extends Component {
  render() {
    const {
      id,
      label,
      options,
      selectLabel= " -- select -- ",
      leftSideLabel = "",
      rightSideLabel = "",
      displayHelp = false,
      help="",
      ...props
    } = this.props;

    return (
      <FormGroup controlId={id} {...props}>
        <ControlLabel>{label}</ControlLabel>
        <div className="columns">
          {leftSideLabel.length > 0 && (
            <div className="column">
              <ControlLabel style={labelStyle}>{leftSideLabel}</ControlLabel>
            </div>
          )}

          <div className="column">
            <FormControl componentClass="select" placeholder="select">
                <option disabled selected value>
                {selectLabel}
                </option>
                {options.map((item, index) => {
                return (
                    <option key={index} value={item.value}>
                    {item.label}
                    </option>
                );
                })}
            </FormControl>
          </div>
          {rightSideLabel.length > 0 && (
            <div className="column">
              <ControlLabel style={labelStyle}>{rightSideLabel}</ControlLabel>
            </div>
          )}
        </div>
        {displayHelp && <div style={{color: "red"}}>{help}</div>}
      </FormGroup>
    );
  }
}

export default SelectGroup;
