import React, { Component, Fragment } from "react";

import Table from "react-bootstrap/lib/Table";

import EmbeddedImage from "../components/EmbeddedImage";
import MyNavigator from "../components/MyNavigator";
import Header from "../components/Header";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";

const imageStyle = {
  maxWidth: "100%"
};

const tableStyle = {
  textAlign: "center"
};

class ComparePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = ""; //Content.all_content[language]["comparePage"];
    if ( Content.all_content != undefined &&
      Content.all_content[language]["comparePage"] && 
    Content.all_content[language]["comparePage"] != undefined) {
      pageContent = Content.all_content[language]["comparePage"];
    } else {
      pageContent = Content.all_content["English"]["comparePage"];
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <EmbeddedImage src={pageContent.image_info.link} alt={"fruit"} />
            <div className="is-size-6">
              <a
                href={pageContent.image_info.attribution.lnk}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pageContent.image_info.attribution.label}
              </a>
            </div>
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">{pageContent.sub_title}</div>
            <p>{pageContent.content}</p>
            <Table striped bordered condensed hover style={tableStyle}>
              <thead>
                <tr>
                  {pageContent.comparison_table.column_title_list.map(
                    (item, index) => {
                      return <th key={index}>{item}</th>;
                    }
                  )}
                </tr>
              </thead>
              <tbody>
                {pageContent.comparison_table.row_list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <strong>{item.title}</strong>
                      </td>
                      {item.cell_list.map((item, index) => {
                        return <td key={index}>{item}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="is-size-6 is-size-7-mobile">
              {pageContent.foot_note_list.map(
                    (item, index) => {
                      return <div key={index}>{item}</div>;
                    }
                  )}
            </div>
            <MyNavigator
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
          />
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default ComparePage;
