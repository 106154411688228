import React, { Component, Fragment } from "react";

import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";



export default class PublicationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    //gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined && 
      Content.all_content[language] != undefined &&
      Content.all_content[language]["publicationPage"] != undefined) {
      pageContent = Content.all_content[language]["publicationPage"];
    } else {
      pageContent = Content.all_content["English"]["publicationPage"];
      
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];



    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div></div>
            <div>
              {
                  pageContent.publication_list.sort((pubA, pubB) => {return pubB.year - pubA.year;}).map((publication, index) => {
                    return <div key={index} style={{marginTop: 30, marginBottom: 30}}>
                        <div className="has-text-weight-semibold">
                        {publication.text}.&nbsp;
                        {
                                publication.links.map((item, indexI) => {
                                    return <span key={indexI}>
                                    {
                                        item.link.indexOf(".pdf") >= 0 ? <span><a href={item.link} target="_blank" rel="noopener noreferrer">{item.label}</a></span> :
                                        <span>[<a href={item.link} target="_blank" rel="noopener noreferrer">{item.label}</a>]</span>
                                    }
                                    </span>
                                })
                            }
                        </div>
                        <p style={{marginTop: 10}}>{publication.description}</p>
                    </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}