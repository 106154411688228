import React, { Component } from "react";
import { Link } from "react-router-dom";
import MyPager from "../components/MyPager";

//import Pager from "react-bootstrap/lib/Pager";

import "../css/app.css";

const lowerBannerStyle = {
  height: 150,
  backgroundColor: "#eee"
};

const titleTyle = {
  fontSize: 45,
  textTransform: "uppercase",
  fontFamily: "proxima-nova"
};

const subTitleTyle = {
  fontSize: 28,
  fontFamily: "proxima-nova"
};

const linkStyle = {
  color: "#000000"
};
// remove the bannaer on landing page for now.
//<MyImage src="./image/big_photo.jpg"/>

const imageStyle = {
  height: "10em", // "40vh", //350,
  width: "100%",
  objectFit: "cover" //"cover",
};

const smallImageStyle = {
  height: "7em", //"9.5em",// "30vh", //350,
  width: "100%",
  objectFit: "cover"
};

const bannerLabelStyle = {
  //position: "absolute",
  fontSize: 48
  //top: 175,
  //width: "100%",
  //textAlign: "center"
};

const bannerContentStyle = {
  //position: "absolute",
  fontSize: 24
  //top: 270,
  //width: "100%",
  //textAlign: "center"
};

const bannerButtonStyle = {
  position: "absolute",
  top: 320,
  width: "100%"
};

const bannerLabelBackgroundStyle = {
  color: "white",
  //font: "bold 24/px Helvetica, Sans-Serif;
  letterSpacing: -1,
  background: "rgb(0, 0, 0)" /* fallback color */,
  background: "rgba(0, 0, 0, 0.7)",
  padding: 10
};

export default class Banner extends Component {
  render() {
    const {
      src = "",
      title = "", //="Lung Cancer Screening",
      content = "", //="Should I get screened?",
      button_label = "", //="Click to learn more"
      button_link = "",
      alt = "This is a image.",
      heightOption = "normal",
      deviceType
    } = this.props;

    // className="has-text-weight-bold is-size-2 is-size-4-touch is-size-5-mobile"
    // is-size-2 is-size-4-touch is-size-5-mobile

    // <img src={src} style={heightOption == "normal"? imageStyle: smallImageStyle} alt={alt} className="bannerimg is-size-2 is-size-4-touch is-size-5-mobile" />
    
    let imageHieghtInUnit = 10;

    if(heightOption == "normal"){
      imageHieghtInUnit = 10;
    }
    else{
      imageHieghtInUnit = 7;
    }

    
    const backgroundImageStyle = {
      height: imageHieghtInUnit + "em",
      width: "auto",
      backgroundImage: `url(${src})`,
      backgroundPosition: "50% 50%",
      backgroundSize: "cover"
    };

    const absoluteStyle = {
      position: "absolute",
      top: 50,
      //padding: "70px 0",
      width: "100%",
      height: imageHieghtInUnit + "em",
    };


    let textTopInUnit = 3;

    console.log("Banner.deviceTop: " + deviceType);
    if(deviceType == "desktop"){
      if( heightOption == "normall" ){
        textTopInUnit = imageHieghtInUnit/2;
      }
      else{
        textTopInUnit = imageHieghtInUnit/2;
      }
      
    }
    else if(deviceType == "tablet"){
      if( heightOption == "normall" ){
        textTopInUnit = imageHieghtInUnit/2;
      }
      else{
        textTopInUnit = imageHieghtInUnit/2;
      }
    }
    else if(deviceType == "mobile"){
      if( title.length + content.length < 60){
        textTopInUnit = imageHieghtInUnit/3;
      }
      else{
        textTopInUnit = 1;
      }
      
    }

    const relativeStyle = {
      position: "relative",
      top: textTopInUnit + "em",
      //bottom: "auto",
      //padding: "auto",
      width: "100%",
      height: imageHieghtInUnit + "em",
    };

    // className="hero-body"
    // container 
    // style={relativeStyle}

    return (
      <div>
        <div className="hero">
          <div
            style={backgroundImageStyle}
            alt={alt}
            className="is-size-2 is-size-4-touch is-size-5-mobile"
          ></div>
          <div style={absoluteStyle} >
            <div className="container has-text-centered" style={relativeStyle}>
              {title.length > 0 && (
                <section style={bannerLabelBackgroundStyle}>
                  <div className="has-text-weight-bold is-size-2 is-size-4-touch is-size-5-mobile">
                    {title}
                  </div>
                </section>
              )}
              {content.length > 0 && (
                <section style={bannerLabelBackgroundStyle}>
                  <div className="has-text-weight-bold is-size-4 is-size-5-touch is-size-6-mobile">
                    {content}
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
        <br />
        {
        button_label.length > 0 && (
        <div className="has-text-centered">
          <MyPager
              link={button_link}
              label={button_label}
              arrowDirection={"right"}
            ></MyPager>
        </div>
          
        )}
        <br />
      </div>
    );
  }
}

/*
<div
            className="level is-mobile"
            style={{
              position: "relative",
              left: "auto",
              right: "auto",
              bottom: 15
            }}
          >
            <div className="level-left" />
            <div className="level-item">
            <MyPager
              link={button_link}
              label={
              <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
                {button_label}
              </div>}
              arrowDirection={"right"}
            ></MyPager>
            </div>
            <div className="level-right" />
          </div>
*/