import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";



class TeamPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    //gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined && 
      Content.all_content[language] != undefined &&
      Content.all_content[language]["teamPage"] != undefined) {
      pageContent = Content.all_content[language]["teamPage"];
    } else {
      pageContent = Content.all_content["English"]["teamPage"];
      
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */


    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              {pageContent.investigator_title}
            </div>
            <div className="box">
              {pageContent.investigator_list.map((item, index_i) => {
                return (
                  <div key={index_i}>
                    <strong>
                      {item.link.length > 0 ? (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : (
                        item.name
                      )}
                    </strong>
                    {item.affiliation.map((item, index_a) => {
                      return <p key={index_a}>{item}</p>;
                    })}
                  </div>
                );
              })}
            </div>

            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">
              {pageContent.research_development_title}
            </div>

            <div className="box">
              {pageContent.research_development_list.map((item, index_i) => {
                return (
                  <div key={index_i}>
                    <strong>
                      {item.link.length > 0 ? (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.name}
                        </a>
                      ) : (
                        item.name
                      )}
                    </strong>
                    {item.affiliation.map((item, index_a) => {
                      return <p key={index_a}>{item}</p>;
                    })}
                  </div>
                );
              })}
            </div>

            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">{pageContent.press_title}</div>
            <div className="box">
            <ul>
              {pageContent.press_resources_list.map((item, index_p) => {
                return (
                  <li key={index_p}>
                    <p>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.label}
                      </a>{" "}
                      - <i>{item.source_label}</i>
                    </p>
                  </li>
                );
              })}
            </ul>
            </div>

            <div className="has-text-weight-semibold is-size-5 is-size-6-mobile">{pageContent.founding_title}</div>
            <div className="box">
              <p>{pageContent.founding_content}</p>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default TeamPage;
