import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";
import MyUtility from "../MyUtility";
import { Link } from "react-router-dom";
import PrintButton from "../components/PrintButton";

class SavedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
    //this.onPrintButtonClick = this.onPrintButtonClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    //gAnalytics();
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }
  /*
  onPrintButtonClick(event) {
    console.log("onPrintButtonClick");

    // Method 3         
    window.printJS("save_content_list", 'html');

    // Method 2: also doesn't work
    
    var content = document.getElementById("save_content_list");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    


    // Method 1: which no longer works?
    
    var prtContent = document.getElementById("save_content_list");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    
    WinPrint.document.write('<html><head><title></title>');
    // <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css">
    //WinPrint.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css" type="text/css" />');
    WinPrint.document.write('</head><body>');


    WinPrint.document.write(prtContent.innerHTML);
    console.log("prtContent.innerHTML: " + prtContent.innerHTML);
    //WinPrint.document.write(prtContent);

    WinPrint.document.write('</body></html>');

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();

  }
  */

  render() {
    const { Content, deviceType, mainContentStyle, changeLanguage, savedContent, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["savedPage"] != undefined) {
      pageContent = Content.all_content[language]["savedPage"];
    } else {
      pageContent = Content.all_content["English"]["savedPage"];

    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    // <Button type="button" className="is-mobile is-centered has-text-centered" onClick={(event) => { window.printJS({ printable: 'save_content_list', type: 'html',documentTitle: webTitle, css: ['https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css'] }); }}>{pageContent.print_button_label}</Button>
    // <button className="button is-mobile is-centered has-text-centered" style={{ marginTop: 20, marginBottom: 20 }} onClick={(event) => { window.printJS({ printable: 'save_content_list', type: 'html', documentTitle: webTitle, css: ['https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css'] }); }}>{pageContent.print_button_label}</button>
              

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="content has-text-justified">
        <div className="top-sticky">
            <Header
              currentRoute={this.props.location.pathname}
              language={language}
              Content={Content}
              deviceType={deviceType}
              savedContent={savedContent}
              changeLanguage={this.onLanguageChange}
              {...others}
            />
          </div>
          <div className="columns is-mobile is-centered">
            <div className="column is-three-quarters">
              <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title} : {savedContent.filter((item, index) => { return item.category == "saved" }).length} {pageContent.item_unit}</div>
              
              <div style={{marginTop: 20, marginBottom: 20}}>
              <PrintButton
                documentElementID={"save_content_list"}
                documentTitle={headInfo.title}
                buttonLabel={pageContent.print_button_label}
              ></PrintButton>
              </div>


              <div id="save_content_list">
                {savedContent.filter((item, index) => { return item.category == "saved" }).map((item, index) => {
                  return <div key={index}>
                    <Link
                      to={item.pathname}
                      style={{ width: "100%", textDecoration: "none" }}
                      data-on="click"
                      data-event-category="SavedContent"
                      data-event-action={item.pathname}>
                      {item.title}
                    </Link>
                    <br />
                    <div style={{ display: "none" }}>Session ID: {item.sessionID}</div>
                    <div style={{ display: "none" }} >ID: {item.annotationID}</div>
                    <div >{item.content}</div>
                    <br />
                    <br />
                  </div>;
                })
                }
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SavedPage;
