import React, { Fragment } from "react";
import { Vega } from "react-vega";
import { Decimal } from "decimal.js";

export default function MyTwoColumns(props) {
  let deviceType = props.deviceType != undefined? props.deviceType: "desktop";
  // <div className="column"></div>
  // <div className="column"></div>
  if(deviceType == "mobile"){
    return <div>
      <div>{props.component1}</div>
      <div>{props.component2}</div>
    </div>
  }
  else{
    return <div style={{display: "flex"}}>
    <div style={{flex: "45%"}}>{props.component1}</div>
    <div style={{flex: "10%"}}></div>
    <div style={{flex: "45%"}}>{props.component2}</div>
    </div>
  }
  /*
  return <div className="columns is-centered">
    <div className="column is-narrow is-half">{props.component1}</div>
    <div className="column is-narrow is-half">{props.component2}</div>
  </div>
  */
}
// <div className="column is-hidden-tablet-only"></div>
/*
<div className="columns is-centered">
    <div className="column">
      <div className="columns is-centered">
        <div className="column"></div>
        <div className="column">{props.component1}</div>
        <div className="column"></div>
      </div>
    </div>
    <div className="column">
      <div className="columns is-centered">
        <div className="column"></div>
        <div className="column">{props.component2}</div>
        <div className="column"></div>
      </div>
    </div>
  </div>
*/
