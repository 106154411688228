import React, { Component, Fragment } from "react";
import MyTimeline from "../components/MyTimeline";
import MyUtility from "../MyUtility";
import Header from "../components/Header";
import MyPageHead from "../components/MyPageHead";


export default class HistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname),
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    //gAnalytics();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceiveProps.location.pathname: " +
        nextProps.location.pathname
    );
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language,
    });
    const { gAnalytics } = this.props;
    if (
      this.props.location.pathname != nextProps.location.pathname ||
      this.state.language != language
    ) {
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item,
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const {
      Content,
      deviceType,
      savedContent,
      mainContentStyle,
      changeLanguage,
      ...others
    } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (
      Content.all_content != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["historyPage"] != undefined
    ) {
      pageContent = Content.all_content[language]["historyPage"];
    } else {
      pageContent = Content.all_content["English"]["historyPage"];
    }

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <div className="container">
        <br />
          <h1 className="h3">{pageContent.title}</h1>
          <div>{pageContent.description}</div>
          <MyTimeline
            topHeaderLabel={"Now"}
            bottomHeaderLabel={"2014"}
            eventList={pageContent.eventList}
          ></MyTimeline>
        </div>
      </Fragment>
    );
  }
}


/*
const eventList = [
  // marker_color:  color from bulma.css: is-primary, is-link, is-info, is-success, is-warning, is-danger
  // markder_type: "", is-image, is-icon
  {
    label: "January 2016",
    description: "Timeline content - Can include any HTML element",
    timestamp: "2016-01-01T00:00:00",
    marker_color: "is-primary"
  },
  {
    label: "February 2016",
    description: "Timeline content - Can include any HTML element",
    timestamp: "2016-02-01T00:00:00",
    marker_color: "is-warning",
    marker_type: "is-image",
    marker_image_size: "is-32x32",
    marker_image_source: "https://bulma.io/images/placeholders/32x32.png"
  },
  {
    label: "March 2017",
    description: "Timeline content - Can include any HTML element",
    timestamp: "2017-03-01T00:00:00",
    marker_color: "is-danger",
    marker_type: "is-icon",
    // fontawesome
    marker_icon_type: "fa fa-flag"
  }
];

export default class HistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    //gAnalytics();
  }

  componentWillReceiveProps(nextProps){
    console.log("componentWillReceiveProps.location.pathname: " + nextProps.location.pathname);
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  render() {
    const { Content, deviceType, savedContent, mainContentStyle, changeLanguage, ...others } = this.props;

    const { language } = this.state;

    console.log("language: " + language);

    var pageContent = "";

    if (Content.all_content != undefined && 
      Content.all_content[language] != undefined &&
      Content.all_content[language]["historyPage"] != undefined) {
      pageContent = Content.all_content[language]["historyPage"];
    } else {
      pageContent = Content.all_content["English"]["historyPage"];
      
    }
*/

    /*
    let webTitle = Content.all_content[language]["headerComponent"].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

/*
    let headInfo = Content.all_content[language][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
      <div className="content has-text-justified">
      <div className="top-sticky">
          <Header
            currentRoute={this.props.location.pathname}
            language={language}
            Content={Content}
            deviceType={deviceType}
            savedContent={savedContent}
            changeLanguage={this.onLanguageChange}
            {...others}
          />
        </div>
        <div className="columns is-mobile is-centered">
          <div className="column is-three-quarters">
            <div className="has-text-weight-bold is-size-3 is-size-4-mobile">{pageContent.title}</div>
            <div>{pageContent.description}</div>
            <p></p>
            <MyTimeline
              topHeaderLabel={"Now"}
              bottomHeaderLabel={"2014"}
              eventList={pageContent.eventList}
            >
            </MyTimeline>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}
*/