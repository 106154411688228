import React, { Component, Fragment } from "react";
//import Flexbox from "flexbox-react";
import Banner from "../components/Banner";
import Header from "../components/Header";
//import Sticky from "react-stickynode";
import MyUtility from "../MyUtility";
import MyPageHead from "../components/MyPageHead";
import MyTabs from "../components/MyTabs";
import MyPager from "../components/MyPager";

import { Link } from "react-router-dom";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: MyUtility.getLanguageFromURL(this.props.location.pathname)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { gAnalytics } = this.props;
    gAnalytics();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "componentWillReceiveProps.location.pathname: " +
        nextProps.location.pathname
    );
    let language = MyUtility.getLanguageFromURL(nextProps.location.pathname);
    this.setState({
      language: language
    });
    const { gAnalytics } = this.props;
    if(this.props.location.pathname != nextProps.location.pathname || this.state.language != language){
      gAnalytics();
    }
  }

  onLanguageChange(item) {
    console.log("onLanguageChage: " + item);
    this.setState({
      language: item
    });
    const { changeLanguage } = this.props;
    changeLanguage(item);
  }

  /*
  shouldComponentUpdate(nextProps, nextState){
    console.log("shouldComponentUpdate: this.props.location: "+ JSON.stringify(this.props.location, null, 2));
    console.log("shouldComponentUpdate:  nextProps.location: "+ JSON.stringify(nextProps.location, null, 2));
    return this.props.location != nextProps.location;
  }
  */

  onTabClick(event, category, action, label, value, nonInteraction) {
    const { ReactGA } = this.props;

    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value,
      nonInteraction: nonInteraction
    });
  }

  render() {
    const {
      changeLanguage,
      deviceType,
      Content,
      savedContent,
      ...others
    } = this.props;

    const { language } = this.state;
    //alert("render.langauge: " + language);

    var pageContent = "";

    //console.log("render.language: " + language);
    //console.log("render: this.props.location: "+ JSON.stringify(this.props.location, null, 2));

    let displayLanguage = language;

    if (
      displayLanguage != undefined &&
      Content.all_content[displayLanguage] != undefined &&
      Content.all_content[displayLanguage]["homePage"] != undefined
    ) {
      pageContent = Content.all_content[displayLanguage]["homePage"];
    } else {
      pageContent = Content.all_content["English"]["homePage"];
    }

    // try to locate the route order
    // route: "/English/risk-reduction-lung-cancer",
    // label: "Reducing risk of lung cancer",
    /*
    let webTitle = Content.all_content[displayLanguage][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0].title;
    */

    let headInfo = Content.all_content[displayLanguage][
      "headerComponent"
    ].route_order_list.filter((item, index) => {
      return this.props.location.pathname.indexOf(item.route) >= 0;
    })[0];

    // pageContent.time_navigation

    //console.log(`pageContent: ${JSON.stringify(pageContent)}`);
    //console.log(`pageContent.time_navigation: ${JSON.stringify(pageContent.time_navigation)}`);

    // <div className="content has-text-justified"
    // pageContent.web_title


    /*
    <Sticky enabled={true} top={0} innerZ={100}>
    <Header
        currentRoute={this.props.location.pathname}
        language={displayLanguage}
        Content={Content}
        deviceType={deviceType}
        savedContent={savedContent}
        changeLanguage={this.onLanguageChange}
        {...others}
      />
    </Sticky>
    */


    return (
      <Fragment>
        <MyPageHead headInfo={headInfo}></MyPageHead>
        <div className="content has-text-justified">
        <div className="top-sticky">
          <Header
              currentRoute={this.props.location.pathname}
              language={displayLanguage}
              Content={Content}
              deviceType={deviceType}
              savedContent={savedContent}
              changeLanguage={this.onLanguageChange}
              {...others}
            />
        </div>
          <Banner
            src="/image/ct-scan.jpg"
            title={pageContent.banner_title}
            content={pageContent.banner_content}
            //button_label={pageContent.banner_button_label}
            //button_link={pageContent.banner_button_link}
            heightOption={displayLanguage == "English" ? "small" : "normal"}
            deviceType={deviceType}
          />
          <div className="columns is-desktop is-mobile is-centered">
            <div className="column is-three-quarters">
              <div className="has-text-weight-semibold is-size-3 is-size-4-mobile has-text-centered">
                {pageContent.two_column_title}
              </div>
              <div className="is-size-4 is-size-5-mobile">
                {pageContent.two_column_sub_title}
              </div>
              <br />
              {deviceType != "mobile" ? (
                <MyTabs
                  list={pageContent.time_navigation}
                  onTabClick={this.onTabClick}
                ></MyTabs>
              ) : null}
              {deviceType == "mobile" ? (
                <Fragment>
                  {pageContent.time_navigation.map((tabItem, tIndex) => {
                    return (
                      <div key={tIndex}>
                        <div className="has-text-weight-bold is-size-4 is-size-5-mobile has-text-centered">
                          {tabItem.title}
                        </div>
                        <br />
                        <Fragment>
                          {tabItem.items.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="box">
                                  <div className="has-text-weight-bold is-size-5 is-size-6-mobile">
                                    {item.title}
                                  </div>
                                  <div>{item.content}</div>
                                </div>
                                <br />
                              </div>
                            );
                          })}
                        </Fragment>
                      </div>
                    );
                  })}
                </Fragment>
              ) : null}

              <section className="section">
                <div className="columns">
                  <div className="column">
                    <figure className="image is-2by2">
                      <img src={pageContent.two_column_content.first.image} />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="has-text-weight-semibold is-size-3 is-size-4-mobile has-text-centered">
                      {pageContent.two_column_content.first.title}
                    </p>
                    <p className="is-size-4 is-size-5-mobile">
                      {pageContent.two_column_content.first.sub_title}
                    </p>
                    <div className="is-pulled-right">
                      <MyPager
                        link={pageContent.two_column_content.first.link}
                        label={
                          pageContent.two_column_content.first.button_label
                        }
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="columns">
                  <div className="column">
                    <figure className="image is-2by2">
                      <img src={pageContent.two_column_content.second.image} />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="has-text-weight-semibold is-size-3 is-size-4-mobile has-text-centered">
                      {pageContent.two_column_content.second.title}
                    </p>
                    <p className="is-size-4 is-size-5-mobile">
                      {pageContent.two_column_content.second.sub_title}
                    </p>
                    <div className="is-pulled-right">
                      <MyPager
                        link={pageContent.two_column_content.second.link}
                        label={
                          pageContent.two_column_content.second.button_label
                        }
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HomePage;

/*
{
  deviceType == "xyz"? 
  <Tabs
  defaultActiveKey={0}
  id="uncontrolled-tab-example"
  //style={{ display: "none" }}
>
  {pageContent.time_navigation.map((item, index) => {
    return (
      <Tab
        key={index}
        eventKey={index}
        title={
          <div
            className="has-text-weight-bold is-size-4 is-size-5-mobile"
            onClick={event => {
              this.onTabClick(
                event,
                "HomePage",
                "Tab Click",
                item.title,
                index,
                true
              );
            }}
          >
            {item.title}
            <br />
          </div>
        }
      >
        <div id={"homePage_tab" + (index + 1)}>
          {item.items.map((itemS, indexS) => {
            return (
              <div key={indexS}>
                <br />
                <div className="columns is-desktop is-mobile is-centered">
                  <div className="column box is-offset-1">
                    <div className="has-text-weight-bold is-size-5 is-size-6-mobile">
                      {itemS.title}
                    </div>
                    <div>{itemS.content}</div>
                  </div>
                  <br />
                </div>
              </div>
            );
          })}
        </div>
      </Tab>
    );
  })}
</Tabs>
:
null

}
*/

/*
<div className="container">
<TwoColumns
  title={pageContent.two_column_title}
  sub_title={pageContent.two_column_sub_title}
  content={pageContent.two_column_content}
/>
</div>
*/
